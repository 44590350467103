import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Pagination, Tooltip, Typography, useMediaQuery } from "@mui/material"



import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { deleteUser, resetUserDelete, suspendUser, reactivateUser, userReactivateReset, userSuspendReset, listAdmins } from "../../../actions/userActions";
import { useTheme } from '@mui/material/styles';
import { ToastContainer, toast } from "react-toastify";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';




const AdminList = () => {

    const dispatch = useDispatch()



    const { success, error } = useSelector((state) => state.userDelete);
    const { admins, loading, totalPages } = useSelector((state) => state.adminList);
    const { success: successSuspend, error: erorrSuspend } = useSelector(state => state.userSuspend)
    const { success: successReactivate, error: errorReactivate } = useSelector(state => state.userReactivate)



    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(5);

    const [selectedUser, setSelectedUser] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClickOpen = (user) => {
        setSelectedUser(user);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedUser(null); // Clear the selected user when dialog is closed
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };



    const handleDeleteUser = (id) => {

        dispatch(deleteUser(id))

        handleClose()
    }





    useEffect(() => {

        dispatch(listAdmins("", page, rowsPerPage))

        if (success) {

            toast("User Deleted Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
            dispatch(resetUserDelete())

        }

        if (error) {
            toast(error, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
            dispatch(resetUserDelete())
        }

        if (successSuspend) {

            toast("User Suspend Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
            dispatch(userSuspendReset())

        }

        if (erorrSuspend) {
            toast(erorrSuspend, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
            dispatch(userSuspendReset())
        }

        if (successReactivate) {
            toast("User Reactivated Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
            dispatch(userReactivateReset())
        }
        if (errorReactivate) {
            toast(errorReactivate, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
            dispatch(userReactivateReset())
        }


    }, [dispatch, success, successSuspend, successReactivate, error, erorrSuspend, errorReactivate, page, rowsPerPage])


    function ResponsiveDialog({ handleClose, open, user }) {

        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

        return (

            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Delete this User?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You are about to delete <span style={{ fontWeight: 800 }}>
                            {user?.firstname} {user?.lastname}
                        </span>
                        <p>Click Confirm if you wish to proceed with the deletion.</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus size="small" onClick={handleClose} className="button-secondary">
                        Cancel
                    </Button>
                    <Button size="small" onClick={() => handleDeleteUser(user._id)} autoFocus className="button-primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

        );
    }



    return (

        <Box>
            <Box display="flex" flexDirection="column" gap={2} mb={2}>
                {loading ? (
                    <Typography textAlign="center">
                        <CircularProgress />
                    </Typography>
                ) : (
                    admins?.map((admin) => (
                        <Grid
                            key={admin._id}
                            container
                            sx={{
                                py: 2,
                                px: 5,
                                mt: 3,
                                borderRadius: "10px",
                                border: "1px solid #eaeaea",
                                cursor: "pointer",
                            }}

                        >
                            <ToastContainer />
                            <ResponsiveDialog
                                open={open}
                                handleClose={handleClose}
                                user={selectedUser}
                            />
                            <Grid xs={2} sx={{ display: "flex", alignItems: "center" }}>
                                <Avatar src={admin.avatar} alt={admin.firstname} sx={{ width: 60, height: 60 }} />
                            </Grid>
                            <Grid xs={10} sx={{ display: "flex", justifyContent: "space-between" }}>

                                <div>
                                    <Typography sx={{ my: 1, fontSize: "16px", fontWeight: 600 }} >{admin.company}</Typography>
                                    <Typography sx={{ my: 1, fontSize: "14px", }} >{admin.firstname} {admin.lastname}</Typography>
                                    <Typography sx={{ my: 1, fontSize: "14px", }} >{admin.email} </Typography>
                                </div>

                                <div style={{ display: "flex", alignItems: "center" }}>

                                    {admin.isSuspended ? (
                                        <Tooltip title="Reactivate User">
                                            <IconButton
                                                onClick={() => dispatch(reactivateUser(admin._id))}
                                            ><CheckCircleIcon color="success" /></IconButton>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Suspend User">
                                            <IconButton
                                                onClick={() => dispatch(suspendUser(admin._id))}
                                            ><PauseCircleIcon color="warning" /></IconButton>
                                        </Tooltip>
                                    )}


                                    <Tooltip title="Delete User">
                                        <IconButton onClick={() => handleClickOpen(admin)}><DeleteIcon color="error"
                                        /></IconButton>
                                    </Tooltip>
                                </div>

                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    ))
                )}
            </Box>
            <Pagination
                count={totalPages} // Total number of pages
                page={page} // Current page
                onChange={handleChangePage} // Function to change the page
                color="primary"
                shape="rounded"

            />
        </Box>


    )
}



export default AdminList