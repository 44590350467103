import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { educationDetailsReset, getEducationDetails, getUserDetails, setEditing, setItem } from '../../../actions/userActions';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { sanitizeInput } from '../../../utils/Validate';

const EducationItem = () => {

    const { education, loading: loadingEducation } = useSelector((state) => state.educationDetails);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [name, setName] = useState(education?.name || '');
    const [field, setField] = useState(education?.field || '');
    const [qualification, setQualification] = useState(education?.qualification || '');
    const [date_start, setEduStart] = useState(education?.date_start || dayjs());
    const [date_end, setEduEnd] = useState(education?.date_end || dayjs());
    const [loading, setLoading] = useState(false)

    const { educationId } = useParams()
    const { userInfo } = useSelector(state => state.userLogin)


    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        const updatedEducation = { name, field, qualification, date_start, date_end };

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const res = await axios.put(`/api/users/education/${educationId}`, { updatedEducation }, config);
            if (res.data.success) {
                setLoading(false)
                dispatch(educationDetailsReset())
                dispatch(getUserDetails("profile"))
                dispatch(setEditing(false))
                setTimeout(() => {
                    dispatch(setItem("profile"))
                    navigate('/profile');
                }, 2000);
                return toast('Education Updated Successfully', {
                    className: 'toast-success',
                    bodyClassName: 'toast-success',
                });
            }
        } catch (error) {
            setLoading(false)
            if (error.response && error.response.data) {

                return toast(error.response.data.message, {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            } else {
                // Handle unexpected errors
                console.error('Unexpected error:', error);
                return toast('Something went wrong', {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            }
        }
    };

    useEffect(() => {


        if (!educationId || !education?.name) {
            dispatch(getEducationDetails(educationId))
        } else {
            setName(education.name)
            setField(education.field)
            setQualification(education.qualification)
            setEduStart(education.date_start)
            setEduEnd(education.date_end)
        }


    }, [dispatch, educationId, education])

    return (
        <Fragment>
            <ToastContainer />

            {loadingEducation ? <Typography textAlign="center"><CircularProgress /> </Typography> : (
                <Grid container mt={1}>

                    <Grid md={3} xs={12}></Grid>
                    <Grid md={6} xs={12} sx={{
                        py: 2,
                        px: 3, mt: 3,
                        borderRadius: "10px",
                        border: "1px solid #eaeaea",
                    }}>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                margin="normal"

                                fullWidth
                                sx={{ mb: 2 }}

                                label="School Name"
                                name="name"
                                value={sanitizeInput(name)}
                                onChange={(e) => setName(e.target.value)}

                                autoComplete="School Name"
                                autoFocus
                            />
                            <TextField
                                margin="normal"

                                fullWidth
                                sx={{ mb: 2 }}
                                label="Field of Study"
                                name="field"

                                value={sanitizeInput(field)}
                                onChange={(e) => setField(e.target.value)}

                                autoComplete="Field of Study"
                                autoFocus
                            />

                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel >Qualification</InputLabel>
                                <Select
                                    labelId="qualification"

                                    value={qualification}
                                    label="Qualification"
                                    required
                                    onChange={(e) => setQualification(e.target.value)}
                                >

                                    <MenuItem value="HND/BSC">HND / BSC</MenuItem>
                                    <MenuItem value="MSc">MSc</MenuItem>
                                    <MenuItem value="PhD">PhD</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>

                                </Select>
                            </FormControl>


                            <div style={{ marginBottom: "20px" }}  >

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                        <DatePicker
                                            label="Start Date"
                                            name="date_start"
                                            sx={{ width: "100%", }}
                                            value={dayjs(date_start)}
                                            onChange={(newValue) => setEduStart(newValue)}


                                        />

                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                            <div  >

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']}>

                                        <DatePicker
                                            label="End Date"
                                            name="date_end"
                                            sx={{ width: "100%", }}
                                            value={dayjs(date_end)}
                                            onChange={(newValue) => setEduEnd(newValue)}

                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>



                            {/* Similar input fields and logic for field, qualification, eduStart, eduEnd */}
                            <Typography textAlign="center" mt={5}>
                                <LoadingButton loading={loading} type="submit" variant="contained" className="button-primary">Update Education</LoadingButton>
                            </Typography>
                        </form>
                    </Grid>
                    <Grid xs={12} md={3}></Grid>
                </Grid>

            )}

        </Fragment>

    );
};

export default EducationItem

