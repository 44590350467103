import { Avatar, Box, Button, Divider, Grid, Typography } from "@mui/material"

import { useNavigate } from "react-router-dom"
import UploadFileIcon from '@mui/icons-material/UploadFile';



const ChooseSetupRoute = () => {
    const navigate = useNavigate()



    return (
        <Grid container>
            <Grid xs={3}></Grid>
            <Grid xs={6}>
                <Typography variant="h6" mt={3}>Set Up Profile</Typography>
                <Box
                    sx={{
                        border: "1px solid #eaeaea",
                        borderRadius: "20px",
                        p: 3,
                        mt: 2,
                        mb: 5,
                        display: "flex",
                        justifyContent: "space-evenly",
                        cursor: "pointer",
                        alignItems: "center"
                    }}
                    onClick={() => navigate("/profile")}
                >
                    <div >
                        <Avatar src="/Proten-logo.png" alt="Proten Logo"
                            sx={{ width: "150px", height: "150px", bgcolor: "#808080" }}
                        />
                    </div>
                    <div >
                        <Typography variant="h6">Create Your Profile</Typography>
                        <Typography>Create a profile to apply for Jobs</Typography>
                    </div>
                </Box>
                <Divider>Or</Divider>
                <Box
                    sx={{
                        border: "1px solid #eaeaea",
                        borderRadius: "20px",
                        p: 3,
                        mt: 5,

                    }}

                >
                    <label htmlFor="file-upload" style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        cursor: "pointer",
                        alignItems: "center"
                    }}>
                        <div>
                            <Avatar
                                sx={{ width: "150px", height: "150px", bgcolor: "#808080" }}
                            >
                                <UploadFileIcon sx={{ width: "100px", height: "100px" }} />
                            </Avatar>
                        </div>
                        <div>
                            <Typography variant="h6">Upload Resume</Typography>
                            <Typography>Create your profile 2x Faster</Typography>

                        </div>
                    </label>
                    <input type="file" id="file-upload" />

                </Box>
                <Typography sx={{ textAlign: "center", my: 5 }}>
                    <Button variant="outlined" className="button-primary">Submit Resume</Button>
                </Typography>
            </Grid>
            <Grid xs={3}></Grid>
        </Grid>
    )
}

export default ChooseSetupRoute