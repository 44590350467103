import axios from "axios"
import { TICKET_CLOSE_FAIL, TICKET_CLOSE_REQUEST, TICKET_CLOSE_RESET, TICKET_CLOSE_SUCCESS, TICKET_CREATE_FAIL, TICKET_CREATE_REQUEST, TICKET_CREATE_RESET, TICKET_CREATE_SUCCESS, TICKET_DELETE_FAIL, TICKET_DELETE_REQUEST, TICKET_DELETE_RESET, TICKET_DELETE_SUCCESS, TICKET_DETAILS_FAIL, TICKET_DETAILS_REQUEST, TICKET_DETAILS_RESET, TICKET_DETAILS_SUCCESS, TICKET_LIST_FAIL, TICKET_LIST_REQUEST, TICKET_LIST_SUCCESS, TICKET_MY_LIST_FAIL, TICKET_MY_LIST_REQUEST, TICKET_MY_LIST_SUCCESS } from "../types/ticketTypes"
import { logout } from "./userActions"



export const createTicket = (ticket) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TICKET_CREATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post(`/api/ticket`, ticket, config)

        dispatch({
            type: TICKET_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: TICKET_CREATE_FAIL,
            payload: msg,
        })
    }
}


export const listTickets =
    () =>
        async (dispatch, getState) => {
            try {
                dispatch({ type: TICKET_LIST_REQUEST });

                const {
                    userLogin: { userInfo },
                } = getState();

                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                };

                const { data } = await axios.get(
                    `/api/ticket`, config

                );

                dispatch({
                    type: TICKET_LIST_SUCCESS,
                    payload: data,
                });
            } catch (error) {
                const msg = error.response && error.response.data.msg
                if (msg === 'Not Authorized, Token Failed') {
                    dispatch(logout())
                }

                dispatch({
                    type: TICKET_LIST_FAIL,
                    payload: msg,
                });
            }
        };


export const listMyTickets = () =>
    async (dispatch, getState) => {
        try {
            dispatch({ type: TICKET_MY_LIST_REQUEST });

            const {
                userLogin: { userInfo },
            } = getState();

            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            };

            const { data } = await axios.get(
                `/api/ticket/me`, config

            );

            dispatch({
                type: TICKET_MY_LIST_SUCCESS,
                payload: data,
            });
        } catch (error) {
            const msg = error.response && error.response.data.msg
            if (msg === 'Not Authorized, Token Failed') {
                dispatch(logout())
            }

            dispatch({
                type: TICKET_MY_LIST_FAIL,
                payload: msg,
            });
        }
    };


export const getTicketDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: TICKET_DETAILS_REQUEST });

        const res = await axios.get(`/api/ticket/${id}`);
        dispatch({
            type: TICKET_DETAILS_SUCCESS,
            payload: res.data,
        });
    } catch (error) {
        const msg = error.response && error.response.data.msg

        dispatch({
            type: TICKET_DETAILS_FAIL,
            payload: msg,
        });
    }
};

export const deleteTicket = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TICKET_DELETE_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.delete(`/api/ticket/${id}`, config);

        dispatch({
            type: TICKET_DELETE_SUCCESS,
        });
    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: TICKET_DELETE_FAIL,
            payload: msg,
        });
    }
};

export const closeTicket = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TICKET_CLOSE_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(
            `/api/ticket/${id}`, {}, config
        );

        dispatch({
            type: TICKET_CLOSE_SUCCESS,
            payload: data,
        });



    } catch (error) {
        const msg = error.response && error.response.data.msg
        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: TICKET_CLOSE_FAIL,
            payload: msg


        });
    }
};


export const resetTicketCreate = () => (dispatch) =>
    dispatch({ type: TICKET_CREATE_RESET });

export const resetTicketDetails = () => (dispatch) =>
    dispatch({ type: TICKET_DETAILS_RESET });

export const resetTicketDelete = () => (dispatch) =>
    dispatch({ type: TICKET_DELETE_RESET });

export const resetTicketClose = () => (dispatch) =>
    dispatch({ type: TICKET_CLOSE_RESET });