import { Avatar, Box, Grid, IconButton, Tooltip, Typography } from "@mui/material"

import LocationIcon from '@mui/icons-material/PinDropOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Moment from 'react-moment';
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, logout, setEditing, setItem } from "../../actions/userActions";
import { deleteJob, listMyJobs, resetJobDelete } from "../../actions/jobActions";
import { ToastContainer, toast } from "react-toastify";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { copyToClipboard, isStringInArrayOfObjects, limitString } from "../../utils/Validate";
import ShareOptions from "../../UI/ShareOptions";
import axios from "axios";




const Job = (props) => {
    const { userInfo } = useSelector((state) => state.userLogin);
    // const { error: errorWishlist, success: successWishlist } = useSelector((state) => state.jobWishlist);

    const userType = userInfo?.type
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const { user } = useSelector((state) => state.userDetails);
    const { success, error } = useSelector((state) => state.jobDelete);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // const truncateString = (str, maxLength) => {
    //     if (str.length > maxLength) {
    //         return str.substring(0, maxLength) + "...";
    //     } else {
    //         return str;
    //     }
    // }

    const handleWishlist = async (id) => {
        if (!userInfo) {
            return toast("Please Login to Save Jobs", {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }


        try {

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`,
                },
            };

            const res = await axios.put(`/api/jobs/${id}/wishlist`, {}, config)
            if (res.data.success) {
                // setUnwishlisting(false)
                setTimeout(() => {
                    dispatch(getUserDetails("profile"))
                }, 3000);
                return toast(res.data.msg, {
                    className: 'toast-success',
                    bodyClassName: 'toast-success',
                });

            }
        } catch (error) {
            // setUnwishlisting(false)
            if (error.response && error.response.data) {
                console.log(error.response.data);
                if (error.response.data.msg === "Not Authorized, Token Failed") dispatch(logout())
                return toast(error.response.data.msg, {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            } else {
                // Handle unexpected errors
                console.error('Unexpected error:', error);
                return toast('Something went wrong', {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            }

        }
    }

    const handleDeleteJob = (id) => {

        dispatch(deleteJob(id))

        handleClose()



    }




    useEffect(() => {

        if (userInfo) {
            if (user && !user.firstname) {

                dispatch(getUserDetails('profile'));
            }
        }

        if (success) {

            toast("Job Deleted", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
            setTimeout(() => {
                dispatch(resetJobDelete())
                dispatch(listMyJobs())
            }, 1000)


        }
        if (error) {

            toast(error, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
            setTimeout(() => {
                dispatch(resetJobDelete())
                // dispatch(listMyJobs())
            }, 1000)


        }


    }, [dispatch, user, userInfo, success, error])


    function ResponsiveDialog({ handleClose, open, id }) {

        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('md'));



        return (


            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Delete this Job?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You are about to this delete this Job.
                        Confirm if you wish to proceed with the deletion.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} className="button-secondary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleDeleteJob(id)} className="button-primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

        );
    }

    return (
        <Box sx={{
            borderRadius: "10px",
            border: "1px solid #eaeaea",
            mb: 3,
            py: 3,
            px: isSmallScreen && 2
        }}

        >
            <ToastContainer />
            <Grid container sx={{ paddingX: "10px" }}>


                <ResponsiveDialog
                    open={open}
                    handleClose={handleClose}
                    id={props._id}
                />


                <Grid xs={4} md={2}>
                    <Avatar src={props.avatar} alt={props.company} sx={{ width: isSmallScreen ? 40 : 60, height: isSmallScreen ? 40 : 60, marginBottom: isSmallScreen && "10px" }} />
                </Grid>


                <Grid xs={12} md={8}>
                    <Typography>{props.company}</Typography>
                    <Typography
                        sx={{ my: 1, cursor: "pointer", }}
                        variant="h6"
                        className="job-title"
                        onClick={() => {
                            dispatch(setItem("job-detail"))
                            navigate(`/job/${props._id}`)
                        }}>{props.title}  </Typography>
                    <Grid sx={{
                        display: "flex",
                        alignItems: isSmallScreen ? "flex-start" : "center",
                        justifyContent: isSmallScreen ? "center" : "space-between",
                        flexDirection: isSmallScreen ? "column" : "row",
                        my: 1,
                        color: "#8b8b8b"
                    }}>
                        <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px", marginBottom: isSmallScreen && "15px" }}><LocationIcon sx={{ fontSize: "14px", marginRight: "2px" }} /> {" "}{props.location}</Typography>
                        <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px", marginBottom: isSmallScreen && "15px" }}><AccessTimeIcon sx={{ fontSize: "14px", marginRight: "2px" }} /> {props.type} </Typography>
                        <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px", marginBottom: isSmallScreen && "15px" }}> {props.minSalary ? `₦${props.minSalary}-${props.maxSalary}` : "₦ Negotiable"}</Typography>
                        <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px", marginBottom: isSmallScreen && "5px" }}><DateRangeOutlinedIcon sx={{ fontSize: "14px", marginRight: "2px" }} /> {" "}<Moment fromNow>{props.isPublished ? props.publishedAt : props.createdAt}</Moment> </Typography>
                    </Grid>
                    {(userType === "Employer" || userType === "PortalAdmin") && <Tooltip title='Copy Link'> <p onClick={() => copyToClipboard(`https://careers.protenintl.com/job/${props._id}`)} style={{
                        fontSize: isSmallScreen ? "8px" : "13px",
                        cursor: "pointer",
                        color: "#167dc1",

                    }}>[{limitString(`https://careers.protenintl.com/job/${props._id}`, 40)}]</p> </Tooltip>}
                    {/* <Typography my={1} >
                        {props?.address}
                    </Typography> */}
                    {/* <Typography component="div" sx={{ fontSize: "12px", color: "#8c8c8c", mt: 1 }}>
                        <div dangerouslySetInnerHTML={{ __html: truncateString(props.description, 150) }} />

                    </Typography> */}
                </Grid>
                <Grid xs={4} md={2}>
                    {!userType || userType === "JobSeeker" ? (
                        <Box sx={{ display: "flex", justifyContent: isSmallScreen ? "flex-start" : "space-around" }}>
                            <IconButton
                                onClick={() => handleWishlist(props._id)}>
                                {userInfo && isStringInArrayOfObjects(props.referenceNumber, user?.wishlist) ? <FavoriteIcon
                                    sx={{ color: "#18bebe" }}
                                /> : <FavoriteBorderOutlinedIcon
                                    sx={{ color: "#18bebe" }}
                                />}
                            </IconButton>
                            <ShareOptions id={props._id} />
                            {/* <IconButton><ShareIcon /></IconButton> */}
                        </Box>
                    ) : (
                        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                            <IconButton
                                onClick={() => {
                                    dispatch(setItem("edit-job"))
                                    dispatch(setEditing(true))
                                    navigate({
                                        pathname: `/job/${props._id}/edit`,
                                        search: "?activestep=1"
                                    }
                                    )
                                }}
                            ><BorderColorOutlinedIcon sx={{ color: "#EBBD1A" }} /></IconButton>
                            <IconButton
                                onClick={handleClickOpen}
                            ><DeleteOutlinedIcon sx={{ color: "#FF499E" }} /></IconButton>
                        </Box>
                    )}

                </Grid>
            </Grid>
        </Box>

    )
}



export default Job