import {
    ADMIN_LIST_FAIL,
    ADMIN_LIST_REQUEST,
    ADMIN_LIST_RESET,
    ADMIN_LIST_SUCCESS,
    CLEAR_ITEM,
    CLEAR_STAGE,
    EDUCATION_DETAILS_FAIL,
    EDUCATION_DETAILS_REQUEST,
    EDUCATION_DETAILS_RESET,
    EDUCATION_DETAILS_SUCCESS,
    EMPLOYER_LIST_FAIL,
    EMPLOYER_LIST_REQUEST,
    EMPLOYER_LIST_RESET,
    EMPLOYER_LIST_SUCCESS,
    EXPERIENCE_DETAILS_FAIL,
    EXPERIENCE_DETAILS_REQUEST,
    EXPERIENCE_DETAILS_RESET,
    EXPERIENCE_DETAILS_SUCCESS,
    SEEKER_LIST_FAIL,
    SEEKER_LIST_REQUEST,
    SEEKER_LIST_RESET,
    SEEKER_LIST_SUCCESS,
    SEEKER_RESET_FORM,
    SEEKER_UPDATE_FORM,
    SET_EDITING,
    SET_ITEM,
    SET_STAGE,
    USER_CREATE_FAIL,
    USER_CREATE_REQUEST,
    USER_CREATE_RESET,
    USER_CREATE_SUCCESS,
    USER_DELETE_FAIL,
    USER_DELETE_REQUEST,
    USER_DELETE_RESET,
    USER_DELETE_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_RESET,
    USER_DETAILS_SUCCESS,
    USER_GRANT_OFFER_FAIL,
    USER_GRANT_OFFER_REQUEST,
    USER_GRANT_OFFER_RESET,
    USER_GRANT_OFFER_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_REQUEST,
    USER_LIST_RESET,
    USER_LIST_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_PROFILE_UPDATE_FAIL,
    USER_PROFILE_UPDATE_REQUEST,
    USER_PROFILE_UPDATE_RESET,
    USER_PROFILE_UPDATE_SUCCESS,
    USER_REACTIVATE_FAIL,
    USER_REACTIVATE_REQUEST,
    USER_REACTIVATE_RESET,
    USER_REACTIVATE_SUCCESS,
    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_RESET,
    USER_REGISTER_SUCCESS,
    USER_SCHEDULE_FINAL_INTERVIEW_FAIL,
    USER_SCHEDULE_FINAL_INTERVIEW_REQUEST,
    USER_SCHEDULE_FINAL_INTERVIEW_RESET,
    USER_SCHEDULE_FINAL_INTERVIEW_SUCCESS,
    USER_SCHEDULE_INITIAL_CALL_FAIL,
    USER_SCHEDULE_INITIAL_CALL_REQUEST,
    USER_SCHEDULE_INITIAL_CALL_RESET,
    USER_SCHEDULE_INITIAL_CALL_SUCCESS,
    USER_SHORTLIST_FAIL,
    USER_SHORTLIST_REQUEST,
    USER_SHORTLIST_RESET,
    USER_SHORTLIST_SUCCESS,
    USER_SUSPEND_FAIL,
    USER_SUSPEND_REQUEST,
    USER_SUSPEND_RESET,
    USER_SUSPEND_SUCCESS,
    USER_UPDATE_FAIL,
    USER_UPDATE_REQUEST,
    USER_UPDATE_RESET,
    USER_UPDATE_SUCCESS,
    USER_VERIFY_FAIL,
    USER_VERIFY_REQUEST,
    USER_VERIFY_RESET,
    USER_VERIFY_SUCCESS,

} from "../types/userTypes";

export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return {
                loading: true,
            };
        case USER_LOGIN_SUCCESS:
            return {
                loading: false,
                userInfo: action.payload,

            };
        case USER_LOGIN_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case USER_LOGOUT:
            return {};
        default:
            return state;
    }
};

export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return {
                loading: true,
            };
        case USER_REGISTER_SUCCESS:
            return {
                loading: false,
                success: true,
            };
        case USER_REGISTER_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case USER_REGISTER_RESET:
            return {}

        default:
            return state;
    }
};

export const userListReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case USER_LIST_REQUEST:
            return { loading: true };
        case USER_LIST_SUCCESS:
            return {
                loading: false,
                users: action.payload.data,
                pages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                pageSize: action.payload.pageSize
            };
        case USER_LIST_FAIL:
            return { loading: false, error: action.payload };
        case USER_LIST_RESET:
            return {
                users: [],
            };

        default:
            return state;
    }
};

export const seekerListReducer = (state = { seekers: [] }, action) => {
    switch (action.type) {
        case SEEKER_LIST_REQUEST:
            return { loading: true };
        case SEEKER_LIST_SUCCESS:
            return {
                loading: false,
                seekers: action.payload.data,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                pageSize: action.payload.pageSize,
                totalSeekers: action.payload.totalItems
            };
        case SEEKER_LIST_FAIL:
            return { loading: false, error: action.payload };
        case SEEKER_LIST_RESET:
            return {
                seekers: [],
            };

        default:
            return state;
    }
};

export const employerListReducer = (state = { employers: [] }, action) => {
    switch (action.type) {
        case EMPLOYER_LIST_REQUEST:
            return { loading: true };
        case EMPLOYER_LIST_SUCCESS:
            return {
                loading: false,
                employers: action.payload.data,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                pageSize: action.payload.pageSize,
                totalEmployers: action.payload.totalItems
            };
        case EMPLOYER_LIST_FAIL:
            return { loading: false, error: action.payload };
        case EMPLOYER_LIST_RESET:
            return {
                employers: [],
            };

        default:
            return state;
    }
};

export const adminListReducer = (state = { admins: [] }, action) => {
    switch (action.type) {
        case ADMIN_LIST_REQUEST:
            return { loading: true };
        case ADMIN_LIST_SUCCESS:
            return {
                loading: false,
                admins: action.payload.data,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                pageSize: action.payload.pageSize,
                totalAdmins: action.payload.totalItems
            };
        case ADMIN_LIST_FAIL:
            return { loading: false, error: action.payload };
        case ADMIN_LIST_RESET:
            return {
                admins: [],
            };

        default:
            return state;
    }
};



export const userDetailsReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case USER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case USER_DETAILS_SUCCESS:
            return {
                loading: false,
                user: action.payload,
            };
        case USER_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case USER_DETAILS_RESET:
            return {
                user: {},
            };

        default:
            return state;
    }
};

export const educationDetailsReducer = (state = { education: {} }, action) => {
    switch (action.type) {
        case EDUCATION_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case EDUCATION_DETAILS_SUCCESS:
            return {
                loading: false,
                education: action.payload,
            };
        case EDUCATION_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case EDUCATION_DETAILS_RESET:
            return {
                education: {},
            };

        default:
            return state;
    }
};

export const experienceDetailsReducer = (state = { experience: {} }, action) => {
    switch (action.type) {
        case EXPERIENCE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case EXPERIENCE_DETAILS_SUCCESS:
            return {
                loading: false,
                experience: action.payload,
            };
        case EXPERIENCE_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case EXPERIENCE_DETAILS_RESET:
            return {
                experience: {},
            };

        default:
            return state;
    }
};


export const userProfileUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_PROFILE_UPDATE_REQUEST:
            return {
                loading: true,
            };
        case USER_PROFILE_UPDATE_SUCCESS:
            return {
                loading: false,
                userInfo: action.payload,
                success: true,
            };
        case USER_PROFILE_UPDATE_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case USER_PROFILE_UPDATE_RESET:
            return {};

        default:
            return state;
    }
};

export const userDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_DELETE_REQUEST:
            return { loading: true };
        case USER_DELETE_SUCCESS:
            return { loading: false, success: true };
        case USER_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case USER_DELETE_RESET:
            return {}

        default:
            return state;
    }
};

export const userUpdateReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case USER_UPDATE_REQUEST:
            return { loading: true };
        case USER_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case USER_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case USER_UPDATE_RESET:
            return {
                user: {},
            };
        default:
            return state;
    }
};

export const userShortListReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_SHORTLIST_REQUEST:
            return { loading: true };
        case USER_SHORTLIST_SUCCESS:
            return { loading: false, success: true };
        case USER_SHORTLIST_FAIL:
            return { loading: false, error: action.payload };
        case USER_SHORTLIST_RESET:
            return {};
        default:
            return state;
    }
};

export const scheduleCallReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_SCHEDULE_INITIAL_CALL_REQUEST:
            return { loading: true };
        case USER_SCHEDULE_INITIAL_CALL_SUCCESS:
            return { loading: false, success: true };
        case USER_SCHEDULE_INITIAL_CALL_FAIL:
            return { loading: false, error: action.payload };
        case USER_SCHEDULE_INITIAL_CALL_RESET:
            return {};
        default:
            return state;
    }
};

export const scheduleInterviewReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_SCHEDULE_FINAL_INTERVIEW_REQUEST:
            return { loading: true };
        case USER_SCHEDULE_FINAL_INTERVIEW_SUCCESS:
            return { loading: false, success: true };
        case USER_SCHEDULE_FINAL_INTERVIEW_FAIL:
            return { loading: false, error: action.payload };
        case USER_SCHEDULE_FINAL_INTERVIEW_RESET:
            return {};
        default:
            return state;
    }
};

export const grantOfferReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_GRANT_OFFER_REQUEST:
            return { loading: true };
        case USER_GRANT_OFFER_SUCCESS:
            return { loading: false, success: true };
        case USER_GRANT_OFFER_FAIL:
            return { loading: false, error: action.payload };
        case USER_GRANT_OFFER_RESET:
            return {};
        default:
            return state;
    }
};

export const userVerifyReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_VERIFY_REQUEST:
            return { loading: true };
        case USER_VERIFY_SUCCESS:
            return { loading: false, success: true };
        case USER_VERIFY_FAIL:
            return { loading: false, error: action.payload };
        case USER_VERIFY_RESET:
            return {};
        default:
            return state;
    }
};

export const userSuspendReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_SUSPEND_REQUEST:
            return { loading: true };
        case USER_SUSPEND_SUCCESS:
            return { loading: false, success: true };
        case USER_SUSPEND_FAIL:
            return { loading: false, error: action.payload };
        case USER_SUSPEND_RESET:
            return {};
        default:
            return state;
    }
};

export const userReactivateReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REACTIVATE_REQUEST:
            return { loading: true };
        case USER_REACTIVATE_SUCCESS:
            return { loading: false, success: true };
        case USER_REACTIVATE_FAIL:
            return { loading: false, error: action.payload };
        case USER_REACTIVATE_RESET:
            return {};
        default:
            return state;
    }
};

export const userCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_CREATE_REQUEST:
            return { loading: true };
        case USER_CREATE_SUCCESS:
            return { loading: false, success: true, user: action.payload };
        case USER_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case USER_CREATE_RESET:
            return {};
        default:
            return state;
    }
};

export const seekerFormReducer = (state = {}, action) => {
    switch (action.type) {
        case SEEKER_UPDATE_FORM:
            return {
                ...state,
                formData: {
                    ...state.formData,
                    // Update specific fields based on payload keys
                    ...Object.keys(action.payload).reduce((updatedData, key) => {
                        return {
                            ...updatedData,
                            [key]: action.payload[key],
                        };
                    }, {}),
                },
            };
        case SEEKER_RESET_FORM:
            return {};
        default:
            return state;
    }
};
export const itemReducer = (state = { item: "" }, action) => {
    switch (action.type) {
        case SET_ITEM:
            return {
                ...state,
                item: action.payload,
            };
        case CLEAR_ITEM:
            return {
                ...state,
                item: '',
            };
        default:
            return state;
    }
};

export const stageReducer = (state = { stage: "" }, action) => {
    switch (action.type) {
        case SET_STAGE:
            return {
                ...state,
                stage: action.payload,
            };
        case CLEAR_STAGE:
            return {
                ...state,
                stage: '',
            };
        default:
            return state;
    }
};

export const editingReducer = (state = { isEditing: false }, action) => {
    switch (action.type) {
        case SET_EDITING:
            return {
                ...state,
                isEditing: action.payload,
            };
        default:
            return state;
    }
};
