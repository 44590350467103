import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';

import { Chip, IconButton } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { limitString } from '../utils/Validate';
import { closeTicket, deleteTicket, listTickets, resetTicketClose, resetTicketDelete } from '../actions/ticketActions';
import { toast } from 'react-toastify';

function createData(id, referenceNumber, email, title, priority, status) {
    return { id, referenceNumber, email, title, priority, status };
}



export default function TicketTable(props) {

    const { userInfo } = useSelector(state => state.userLogin)
    const { success, error } = useSelector(state => state.ticketClose)
    const { success: successDelete, error: errorDelete } = useSelector(state => state.ticketDelete)

    const dispatch = useDispatch()


    React.useEffect(() => {
        if (success) {
            toast("Ticket Closed Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
            dispatch(resetTicketClose())
            dispatch(listTickets())
        }

        if (successDelete) {
            toast("Ticket Deleted Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
            dispatch(resetTicketDelete())
            dispatch(listTickets())
        }

        if (error) {
            toast(error, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            })
            dispatch(resetTicketClose())
        }

        if (errorDelete) {
            toast(errorDelete, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            })
            dispatch(resetTicketDelete())
        }
    }, [success, dispatch, successDelete, error, errorDelete])

    const rows = props.tickets?.map(ticket => createData(ticket._id, ticket.referenceNumber, ticket.email, ticket.title, ticket.priority, ticket.status))
    return (
        <TableContainer component={Paper} >
            <Table aria-label="simple table" >
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 700 }}>Ticket ID</TableCell>

                        <TableCell sx={{ fontWeight: 700 }}>Issue Title</TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>Priority Level</TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
                        {userInfo.isAdmin && <TableCell sx={{ fontWeight: 700 }}>Email</TableCell>}
                        {userInfo.isAdmin && <TableCell align="center" colSpan={2} sx={{ fontWeight: 700 }}>Actions</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.map((row) => (
                        <TableRow
                            key={row.referenceNumber}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.referenceNumber}
                            </TableCell>

                            <TableCell > {limitString(row.title, 25)}</TableCell>
                            <TableCell >{row.priority}</TableCell>
                            <TableCell ><Chip label={row.status} color={row.status === "Open" ? "error" : "success"} /></TableCell>
                            {userInfo.isAdmin && <TableCell align="right">{row.email}</TableCell>}
                            {userInfo.isAdmin && (
                                <React.Fragment>
                                    <TableCell><IconButton
                                        onClick={() => dispatch(closeTicket(row.id))}
                                    ><CloseOutlinedIcon sx={{ color: "#18bebe" }} /></IconButton></TableCell>
                                    <TableCell><IconButton
                                        onClick={() => dispatch(deleteTicket(row.id))}
                                    ><DeleteOutlinedIcon sx={{ color: "#FF499E" }} /></IconButton></TableCell>
                                </React.Fragment>
                            )}

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
