import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Pagination, Select, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"

import { setItem, deleteUser, resetUserDelete, suspendUser, reactivateUser, userReactivateReset, userSuspendReset, listEmployers } from "../../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VerifiedIcon from '@mui/icons-material/Verified';
import { ToastContainer, toast } from "react-toastify";



const EmployerList = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(5);

    const [open, setOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [filter, setFilter] = useState('')

    const handleClickOpen = (user) => {
        setSelectedUser(user);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedUser(null); // Clear the selected user when dialog is closed
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };



    const handleDeleteUser = (id) => {

        dispatch(deleteUser(id))

        handleClose()
    }


    const { employers, loading, totalPages } = useSelector((state) => state.employerList);
    const { success, error } = useSelector((state) => state.userDelete);
    const { success: successSuspend, error: erorrSuspend } = useSelector(state => state.userSuspend)
    const { success: successReactivate, error: errorReactivate } = useSelector(state => state.userReactivate)

    useEffect(() => {

        dispatch(listEmployers(filter, page, rowsPerPage))

        if (success) {

            toast("User Deleted Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
            dispatch(resetUserDelete())

        } else if (error) {
            toast(error, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
            dispatch(resetUserDelete())
        }

        if (successSuspend) {

            toast("User Suspend Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
            dispatch(userSuspendReset())

        } else if (erorrSuspend) {
            toast(erorrSuspend, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
            dispatch(userSuspendReset())
        }

        if (successReactivate) {
            toast("User Reactivated Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
            dispatch(userReactivateReset())
        } else if (errorReactivate) {
            toast(errorReactivate, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
            dispatch(userReactivateReset())
        }

    }, [dispatch, filter, success, successSuspend, successReactivate, error, erorrSuspend, errorReactivate, page, rowsPerPage])

    function ResponsiveDialog({ handleClose, open, user }) {

        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

        return (

            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Delete this User?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You are about to delete <span style={{ fontWeight: 800 }}>
                            {user?.firstname} {user?.lastname}
                        </span>
                        <p>Click Confirm if you wish to proceed with the deletion.</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus size="small" onClick={handleClose} className="button-secondary">
                        Cancel
                    </Button>
                    <Button size="small" onClick={() => handleDeleteUser(user._id)} autoFocus className="button-primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

        );
    }




    return (
        <Fragment>


            <FormControl sx={{ width: "30%" }}>
                <InputLabel id="employer-filter">Filter By</InputLabel>
                <Select
                    labelId="employment-filter"
                    id="select-employment-filter"
                    value={filter}
                    label="Filter By"
                    onChange={(e) => setFilter(e.target.value)}
                    name="filter"


                >

                    <MenuItem value="true">Verified</MenuItem>
                    <MenuItem value="paid">Paid</MenuItem>



                </Select>
            </FormControl>




            <Box>
                <Box display="flex" flexDirection="column" gap={2} mb={2}>
                    {loading ? (
                        <Typography textAlign="center">
                            <CircularProgress />
                        </Typography>
                    ) : (
                        employers?.map((employer) => (
                            <Grid
                                key={employer._id}
                                container
                                sx={{
                                    py: 2,
                                    px: 5,
                                    mt: 3,
                                    borderRadius: "10px",
                                    border: "1px solid #eaeaea",
                                    cursor: "pointer",
                                }}

                            >
                                <ToastContainer />
                                <ResponsiveDialog
                                    open={open}
                                    handleClose={handleClose}
                                    user={selectedUser}
                                />
                                <Grid xs={2} sx={{ display: "flex", alignItems: "center" }}>
                                    <Avatar src={employer.avatar} alt={employer.firstname} sx={{ width: 60, height: 60 }} />
                                </Grid>
                                <Grid xs={10} sx={{ display: "flex", justifyContent: "space-between" }}>

                                    <div>
                                        <Typography sx={{ my: 1, fontSize: "16px", fontWeight: 600 }} >{employer.company}</Typography>
                                        <Typography sx={{ my: 1, fontSize: "14px", }} >{employer.firstname} {employer.lastname}</Typography>
                                    </div>

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Tooltip title="View User">
                                            <IconButton
                                                onClick={() => {
                                                    dispatch(setItem("employer"))
                                                    navigate(`/employer/${employer._id}`)
                                                }}
                                            ><VisibilityIcon color="success" /></IconButton>
                                        </Tooltip>
                                        {employer.isSuspended ? (
                                            <Tooltip title="Reactivate User">
                                                <IconButton
                                                    onClick={() => dispatch(reactivateUser(employer._id))}
                                                ><CheckCircleIcon color="success" /></IconButton>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Suspend User">
                                                <IconButton
                                                    onClick={() => dispatch(suspendUser(employer._id))}
                                                ><PauseCircleIcon color="warning" /></IconButton>
                                            </Tooltip>
                                        )}


                                        <Tooltip title="Delete User">
                                            <IconButton onClick={() => handleClickOpen(employer)}><DeleteIcon color="error"
                                            /></IconButton>
                                        </Tooltip>
                                        {employer.isVerified && (
                                            <Button disabled variant="outlined" size="small"
                                                startIcon={<VerifiedIcon sx={{ color: "#18bebe" }} />}
                                                sx={{ color: "#18bebe", fontSize: "10px" }}
                                            >Verified</Button>
                                        )}
                                    </div>



                                </Grid>
                                <Grid item xs={1}></Grid>
                            </Grid>
                        ))
                    )}
                </Box>
                <Pagination
                    count={totalPages} // Total number of pages
                    page={page} // Current page
                    onChange={handleChangePage} // Function to change the page
                    color="primary"
                    shape="rounded"

                />
            </Box>
        </Fragment>
    )
}



export default EmployerList