import { Avatar, CircularProgress, Grid, Typography } from "@mui/material"
import LocationIcon from '@mui/icons-material/PinDropOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { useNavigate } from "react-router-dom";
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { getUserDetails, setItem } from "../../actions/userActions";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";


const WishlistedJobs = () => {
    const [unwishlisting, setUnwishlisting] = useState(false)
    const { userInfo } = useSelector((state) => state.userLogin);
    const { user, loading } = useSelector((state) => state.userDetails);
    const dispatch = useDispatch()


    const navigate = useNavigate()

    useEffect(() => {
        if (!userInfo) {
            navigate("/login")
        } else {
            if (!user || !user.firstname) {
                dispatch(getUserDetails('profile'));
            }
        }


    }, [dispatch, user, navigate, userInfo])


    const handleUnwishlist = async (id) => {
        setUnwishlisting(true)
        try {

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`,
                },
            };

            const res = await axios.put(`/api/jobs/${id}/unwishlist`, {}, config)
            if (res.data.success) {
                setUnwishlisting(false)
                setTimeout(() => {
                    dispatch(getUserDetails("profile"))
                }, 3000);
                return toast(res.data.msg, {
                    className: 'toast-success',
                    bodyClassName: 'toast-success',
                });
            }
        } catch (error) {
            setUnwishlisting(false)
            if (error.response && error.response.data) {
                console.log(error.response.data);
                return toast(error.response.data.msg, {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            } else {
                // Handle unexpected errors
                console.error('Unexpected error:', error);
                return toast('Something went wrong', {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            }

        }
    }

    return (

        loading ? <Typography textAlign="center"> <CircularProgress /></Typography> : user.wishlist?.map(job => (
            <Grid container sx={{
                py: 2,
                px: 2,
                mt: 3,
                borderRadius: "10px",
                border: "1px solid #eaeaea",
                cursor: "pointer"
            }}

            >
                <ToastContainer />
                <Grid xs={1}>
                    <Avatar src={job.avatar} alt="Company Logo" />
                </Grid>
                <Grid xs={11} sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Grid xs={7}>
                        <Typography sx={{ fontSize: "12px" }}>{job.company}</Typography>
                        <Typography
                            sx={{ my: 1, fontSize: "15px", fontWeight: 600 }}
                            onClick={() => {
                                dispatch(setItem("job-detail"))
                                navigate(`/job/${job._id}`)
                            }}
                        >{job.title}</Typography>

                        <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", my: 1, color: "#8b8b8b" }}>
                            <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px" }}><LocationIcon sx={{ fontSize: "14px", marginRight: "2px" }} /> {job.location} </Typography>
                            <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px" }}><AccessTimeIcon sx={{ fontSize: "14px", marginRight: "2px" }} /> {job.type}  </Typography>
                            <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px" }}><span style={{ fontSize: "12px", marginRight: "2px" }}>&#8358;</span> {" "}{`${job.minSalary}-${job.maxSalary}`}</Typography>
                            <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px" }}><DateRangeOutlinedIcon sx={{ fontSize: "14px", marginRight: "2px" }} /> {" "} <Moment fromNow>{job.createdAt}</Moment> </Typography>
                        </Grid>
                    </Grid>
                    <Grid xs={2} sx={{ display: "flex", alignItems: "center" }}>
                        <LoadingButton
                            startIcon={<RemoveCircleOutlineOutlinedIcon />}
                            onClick={() => handleUnwishlist(job._id)}
                            loading={unwishlisting}
                            variant="outlined"
                        >Remove</LoadingButton>
                    </Grid>

                </Grid>

            </Grid>
        ))

    )
}



export default WishlistedJobs