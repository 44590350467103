import { IconButton, InputBase, Paper } from "@mui/material"
// import PropTypes from 'prop-types';
import { sanitizeInput } from "../utils/Validate";




const SearchBar = (props) => {
    return (
        <Paper
            component="form"

            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "95%" }}
        >
            <IconButton sx={{ p: '10px' }} aria-label={props.icon}>
                {props.children}
            </IconButton>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={props.placeholder}
                inputProps={{ 'aria-label': `search ${props.icon}` }}
                onChange={props.onChange}
                value={sanitizeInput(props.value)}

            />


        </Paper>
    )
}

// SearchBar.propTypes = {
//     children: PropTypes.object.isRequired,
//     icon: PropTypes.object.isRequired,
//     placeholder: PropTypes.string.isRequired
// }

export default SearchBar