import { Avatar, Button, CircularProgress, Grid, Typography, Pagination, Box } from "@mui/material"
// import LinearCompany from "../../assets/images/linear.png"
import { useNavigate, useParams } from "react-router-dom"

import { setItem, setStage } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getJobDetails, listJobApplicants } from "../../actions/jobActions";
import axios from "axios";




const ApplicantHighlight = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { userInfo } = useSelector(state => state.userLogin)

    const { id } = useParams();

    const { job, loading } = useSelector((state) => state.jobDetails);
    const { applicants, totalPages } = useSelector((state) => state.jobApplicantList);

    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(5);

    useEffect(() => {

        dispatch(listJobApplicants(id, page, rowsPerPage))

        if (!job._id || job._id !== id) {
            dispatch(getJobDetails(id))


        }
        dispatch(setStage("applied"))

    }, [job, id, dispatch, page, rowsPerPage])


    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const handleUpdateProfileViews = async (id) => {
        try {

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            await axios.put(`/api/users/${id}/views`, {}, config);

        } catch (error) {
            console.log(error.response.data);
        }
    }


    return (
        <Box>
            <Box display="flex" flexDirection="column" gap={2} mb={2}>
                {loading ? <Typography textAlign="center"> <CircularProgress /></Typography> : applicants.map(applicant => (
                    <Grid container sx={{
                        py: 2,
                        px: 5, mt: 3,
                        borderRadius: "10px",
                        border: "1px solid #eaeaea",
                        cursor: "pointer"
                    }}
                        onClick={() => {
                            handleUpdateProfileViews(applicant._id)
                            dispatch(setItem("applicant"))
                            navigate(`/job/${job._id}/applicant/${applicant._id}`)
                        }}
                    >
                        <Grid xs={1} sx={{ display: "flex", alignItems: "center" }}>
                            <Avatar src={applicant.avatar} alt={applicant.firstname} />
                        </Grid>
                        <Grid xs={11} sx={{ display: "flex", justifyContent: "space-between" }}>

                            <div>
                                <Typography sx={{ my: 1, fontSize: "16px", fontWeight: 600 }} >{applicant.preferredTitle}</Typography>
                                <Typography sx={{ my: 1, fontSize: "14px", }} >{applicant.firstname} {applicant.lastname}</Typography>
                            </div>

                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Button variant="outlined" onClick={() => {
                                    handleUpdateProfileViews(applicant._id)
                                    dispatch(setItem("applicant"))
                                    navigate(`/job/${job._id}/applicant/${applicant._id}`)
                                }}>View Profile</Button>
                            </div>

                        </Grid>

                    </Grid>
                ))}
            </Box>

            <Pagination
                count={totalPages} // Total number of pages
                page={page} // Current page
                onChange={handleChangePage} // Function to change the page
                color="primary"
                shape="rounded"

            />
        </Box>

    )
}



export default ApplicantHighlight