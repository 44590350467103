import axios from 'axios';
import {
    APPLICATION_WITHDRAW_FAIL,
    APPLICATION_WITHDRAW_REQUEST,
    APPLICATION_WITHDRAW_RESET,
    APPLICATION_WITHDRAW_SUCCESS,
    CLOSED_JOB_LIST_FAIL,
    CLOSED_JOB_LIST_REQUEST,
    CLOSED_JOB_LIST_SUCCESS,
    DRAFT_LIST_FAIL,
    DRAFT_LIST_REQUEST,
    DRAFT_LIST_SUCCESS,
    JOB_APPLICANTS_FAIL,
    JOB_APPLICANTS_REQUEST,
    JOB_APPLICANTS_SUCCESS,
    JOB_APPLY_FAIL,
    JOB_APPLY_REQUEST,
    JOB_APPLY_RESET,
    JOB_APPLY_SUCCESS,
    JOB_CLOSE_FAIL,
    JOB_CLOSE_REQUEST,
    JOB_CLOSE_RESET,
    JOB_CLOSE_SUCCESS,
    JOB_CREATE_FAIL,
    JOB_CREATE_REQUEST,
    JOB_CREATE_RESET,
    JOB_CREATE_SUCCESS,
    JOB_DELETE_FAIL,
    JOB_DELETE_REQUEST,
    JOB_DELETE_RESET,
    JOB_DELETE_SUCCESS,
    JOB_DETAILS_FAIL,
    JOB_DETAILS_REQUEST,
    JOB_DETAILS_RESET,
    JOB_DETAILS_SUCCESS,
    JOB_FINAL_INTERVIEW_FAIL,
    JOB_FINAL_INTERVIEW_REQUEST,
    JOB_FINAL_INTERVIEW_SUCCESS,
    JOB_INITIAL_CALL_FAIL,
    JOB_INITIAL_CALL_REQUEST,
    JOB_INITIAL_CALL_SUCCESS,
    JOB_LIST_FAIL,
    JOB_LIST_REQUEST,
    JOB_LIST_SUCCESS,
    JOB_OFFER_FAIL,
    JOB_OFFER_REQUEST,
    JOB_OFFER_SUCCESS,
    JOB_PUBLISH_FAIL,
    JOB_PUBLISH_REQUEST,
    JOB_PUBLISH_RESET,
    JOB_PUBLISH_SUCCESS,
    JOB_REJECT_FAIL,
    JOB_REJECT_REQUEST,
    JOB_REJECT_RESET,
    JOB_REJECT_SUCCESS,
    JOB_SHORTLISTED_FAIL,
    JOB_SHORTLISTED_REQUEST,
    JOB_SHORTLISTED_SUCCESS,
    JOB_UPDATE_FAIL,
    JOB_UPDATE_REQUEST,
    JOB_UPDATE_RESET,
    JOB_UPDATE_SUCCESS,
    JOB_WISHLIST_FAIL,
    JOB_WISHLIST_REQUEST,
    JOB_WISHLIST_RESET,
    JOB_WISHLIST_SUCCESS,
    MY_JOB_LIST_FAIL,
    MY_JOB_LIST_REQUEST,
    MY_JOB_LIST_SUCCESS,
    PUBLISHED_JOB_LIST_FAIL,
    PUBLISHED_JOB_LIST_REQUEST,
    PUBLISHED_JOB_LIST_SUCCESS,
    REJECTED_JOB_LIST_FAIL,
    REJECTED_JOB_LIST_REQUEST,
    REJECTED_JOB_LIST_SUCCESS,
    RESET_FORM,
    UPDATE_FORM,
} from '../types/jobTypes';
import { logout } from './userActions';


export const listPublishedJobs =
    (filter = "", page = 1, limit = 5, timeFilter = "all") =>
        async (dispatch) => {
            try {
                dispatch({ type: PUBLISHED_JOB_LIST_REQUEST });



                const { data } = await axios.get(
                    `/api/jobs/published?filter=${filter}&page=${page}&limit=${limit}&timeFilter=${timeFilter}`,

                );

                dispatch({
                    type: PUBLISHED_JOB_LIST_SUCCESS,
                    payload: data,
                });
            } catch (error) {
                const msg = error.response && error.response.data.msg

                dispatch({
                    type: PUBLISHED_JOB_LIST_FAIL,
                    payload: msg,
                });
            }
        };

export const listClosedJobs =
    (filter = "", page = 1, limit = 5) =>
        async (dispatch) => {
            try {
                dispatch({ type: CLOSED_JOB_LIST_REQUEST });

                const { data } = await axios.get(
                    `/api/jobs/closed?filter=${filter}&page=${page}&limit=${limit}`,

                );

                dispatch({
                    type: CLOSED_JOB_LIST_SUCCESS,
                    payload: data,
                });
            } catch (error) {
                const msg = error.response && error.response.data.msg

                dispatch({
                    type: CLOSED_JOB_LIST_FAIL,
                    payload: msg,
                });
            }
        };

export const listRejectedJobs =
    (filter = "", page = 1, limit = 5) =>
        async (dispatch) => {
            try {
                dispatch({ type: REJECTED_JOB_LIST_REQUEST });

                const { data } = await axios.get(
                    `/api/jobs/rejected?filter=${filter}&page=${page}&limit=${limit}`,

                );

                dispatch({
                    type: REJECTED_JOB_LIST_SUCCESS,
                    payload: data,
                });
            } catch (error) {
                const msg = error.response && error.response.data.msg

                dispatch({
                    type: REJECTED_JOB_LIST_FAIL,
                    payload: msg,
                });
            }
        };

export const listDrafts =
    (filter = "", page = 1, rowsPerPage = 5) =>
        async (dispatch) => {
            try {
                dispatch({ type: DRAFT_LIST_REQUEST });



                const { data } = await axios.get(
                    `/api/jobs/drafts?filter=${filter}&page=${page}&rowsPerPage=${rowsPerPage}`,

                );

                dispatch({
                    type: DRAFT_LIST_SUCCESS,
                    payload: data,
                });
            } catch (error) {
                const msg = error.response && error.response.data.msg

                dispatch({
                    type: DRAFT_LIST_FAIL,
                    payload: msg,
                });
            }
        };

export const listJobs =
    (filter = '', page = 1, limit = 5) =>
        async (dispatch, getState) => {
            try {
                dispatch({ type: JOB_LIST_REQUEST });

                const {
                    userLogin: { userInfo },
                } = getState();

                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                };

                const { data } = await axios.get(
                    `/api/jobs?filter=${filter}&page=${page}&limit=${limit}`, config

                );

                dispatch({
                    type: JOB_LIST_SUCCESS,
                    payload: data,
                });
            } catch (error) {
                const msg = error.response && error.response.data.msg
                if (msg === 'Not Authorized, Token Failed') {
                    dispatch(logout())
                }

                dispatch({
                    type: JOB_LIST_FAIL,
                    payload: msg,
                });
            }
        };


export const getJobDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: JOB_DETAILS_REQUEST });

        const res = await axios.get(`/api/jobs/${id}`);
        dispatch({
            type: JOB_DETAILS_SUCCESS,
            payload: res.data,
        });
    } catch (error) {
        const msg = error.response && error.response.data.msg

        dispatch({
            type: JOB_DETAILS_FAIL,
            payload: msg,
        });
    }
};

export const getAdminJobDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: JOB_DETAILS_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const res = await axios.get(`/api/jobs/${id}/admin`, config);
        dispatch({
            type: JOB_DETAILS_SUCCESS,
            payload: res.data,
        });
    } catch (error) {
        const msg = error.response && error.response.data.msg

        dispatch({
            type: JOB_DETAILS_FAIL,
            payload: msg,
        });
    }
};

export const deleteJob = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: JOB_DELETE_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.delete(`/api/jobs/${id}`, config);

        dispatch({
            type: JOB_DELETE_SUCCESS,
        });
    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: JOB_DELETE_FAIL,
            payload: msg,
        });
    }
};

export const listMyJobs =
    () =>
        async (dispatch, getState) => {
            try {
                dispatch({ type: MY_JOB_LIST_REQUEST });

                const {
                    userLogin: { userInfo },
                } = getState();

                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                };

                const { data } = await axios.get(
                    `/api/jobs/me`,
                    config
                );

                dispatch({
                    type: MY_JOB_LIST_SUCCESS,
                    payload: data,
                });
            } catch (error) {
                const msg = error.response && error.response.data.msg

                if (msg === 'Not Authorized, Token Failed') {
                    dispatch(logout());
                }

                dispatch({
                    type: MY_JOB_LIST_FAIL,
                    payload: msg,
                });
            }
        };




export function updateForm(data) {
    return {
        type: UPDATE_FORM,
        payload: data
    };
}

export const createJob = (job) => async (dispatch, getState) => {
    try {
        dispatch({
            type: JOB_CREATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post(`/api/jobs`, job, config)

        dispatch({
            type: JOB_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: JOB_CREATE_FAIL,
            payload: msg,
        })
    }
}


export const updateJob = (id, job) => async (dispatch, getState) => {
    try {
        dispatch({
            type: JOB_UPDATE_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.put(
            `/api/jobs/${id}`,
            job,
            config
        );

        dispatch({
            type: JOB_UPDATE_SUCCESS,
        });

        dispatch({
            type: JOB_DETAILS_SUCCESS,
            payload: data,
        });

        dispatch({ type: JOB_DETAILS_RESET });
    } catch (error) {

        const msg = error.response && error.response.data.msg
        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: JOB_UPDATE_FAIL,
            payload: msg


        });
    }
};

export const applyForJob = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: JOB_APPLY_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post(
            `/api/jobs/${id}/apply`, {}, config
        );

        dispatch({
            type: JOB_APPLY_SUCCESS,
            payload: data,
        });



    } catch (error) {
        const msg = error.response && error.response.data.msg
        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: JOB_APPLY_FAIL,
            payload: msg


        });
    }
};

export const withdrawApplication = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: APPLICATION_WITHDRAW_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(
            `/api/jobs/${id}/withdraw`, {}, config
        );

        dispatch({
            type: APPLICATION_WITHDRAW_SUCCESS,
            payload: data,
        });



    } catch (error) {
        const msg = error.response && error.response.data.msg
        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: APPLICATION_WITHDRAW_FAIL,
            payload: msg


        });
    }
};

export const wishlistJob = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: JOB_WISHLIST_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(
            `/api/jobs/${id}/wishlist`, {}, config
        );

        dispatch({
            type: JOB_WISHLIST_SUCCESS,
            payload: data,
        });



    } catch (error) {
        const msg = error.response && error.response.data.msg
        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: JOB_WISHLIST_FAIL,
            payload: msg


        });
    }
};

export const publishJob = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: JOB_PUBLISH_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(
            `/api/jobs/${id}/publish`, {}, config
        );

        dispatch({
            type: JOB_PUBLISH_SUCCESS,
            payload: data,
        });



    } catch (error) {
        const msg = error.response && error.response.data.msg
        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: JOB_PUBLISH_FAIL,
            payload: msg


        });
    }
};

export const closeJob = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: JOB_CLOSE_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(
            `/api/jobs/${id}/close`, {}, config
        );

        dispatch({
            type: JOB_CLOSE_SUCCESS,
            payload: data,
        });

    } catch (error) {
        const msg = error.response && error.response.data.msg
        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: JOB_CLOSE_FAIL,
            payload: msg

        });
    }
};

export const rejectJob = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: JOB_REJECT_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(
            `/api/jobs/${id}/reject`, {}, config
        );

        dispatch({
            type: JOB_REJECT_SUCCESS,
            payload: data,
        });

    } catch (error) {
        const msg = error.response && error.response.data.msg
        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: JOB_REJECT_FAIL,
            payload: msg

        });
    }
};


export const listJobApplicants =
    (id, page = 1, limit = 5, filter = '',) =>
        async (dispatch, getState) => {
            try {
                dispatch({ type: JOB_APPLICANTS_REQUEST });

                const {
                    userLogin: { userInfo },
                } = getState();

                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                };

                const { data } = await axios.get(
                    `/api/jobs/${id}/applicants?filter=${filter}&page=${page}&limit=${limit}`, config

                );

                dispatch({
                    type: JOB_APPLICANTS_SUCCESS,
                    payload: data,
                });
            } catch (error) {
                const msg = error.response && error.response.data.msg
                if (msg === 'Not Authorized, Token Failed') {
                    dispatch(logout())
                }

                dispatch({
                    type: JOB_APPLICANTS_FAIL,
                    payload: msg,
                });
            }
        };

export const listShortlistedCandidates =
    (id, page = 1, limit = 5, filter = '',) =>
        async (dispatch, getState) => {
            try {
                dispatch({ type: JOB_SHORTLISTED_REQUEST });

                const {
                    userLogin: { userInfo },
                } = getState();

                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                };

                const { data } = await axios.get(
                    `/api/jobs/${id}/shortlisted?filter=${filter}&page=${page}&limit=${limit}`, config

                );

                dispatch({
                    type: JOB_SHORTLISTED_SUCCESS,
                    payload: data,
                });
            } catch (error) {
                const msg = error.response && error.response.data.msg
                if (msg === 'Not Authorized, Token Failed') {
                    dispatch(logout())
                }

                dispatch({
                    type: JOB_SHORTLISTED_FAIL,
                    payload: msg,
                });
            }
        };



export const listInitialInterviewCandidates =
    (id, page = 1, limit = 5, filter = '',) =>
        async (dispatch, getState) => {
            try {
                dispatch({ type: JOB_INITIAL_CALL_REQUEST });

                const {
                    userLogin: { userInfo },
                } = getState();

                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                };

                const { data } = await axios.get(
                    `/api/jobs/${id}/initial-interview?filter=${filter}&page=${page}&limit=${limit}`, config

                );

                dispatch({
                    type: JOB_INITIAL_CALL_SUCCESS,
                    payload: data,
                });
            } catch (error) {
                const msg = error.response && error.response.data.msg
                if (msg === 'Not Authorized, Token Failed') {
                    dispatch(logout())
                }

                dispatch({
                    type: JOB_INITIAL_CALL_FAIL,
                    payload: msg,
                });
            }
        };



export const listFinalInterviewCandidates =
    (id, page = 1, limit = 5, filter = '',) =>
        async (dispatch, getState) => {
            try {
                dispatch({ type: JOB_FINAL_INTERVIEW_REQUEST });

                const {
                    userLogin: { userInfo },
                } = getState();

                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                };

                const { data } = await axios.get(
                    `/api/jobs/${id}/final-interview?filter=${filter}&page=${page}&limit=${limit}`, config

                );

                dispatch({
                    type: JOB_FINAL_INTERVIEW_SUCCESS,
                    payload: data,
                });
            } catch (error) {
                const msg = error.response && error.response.data.msg
                if (msg === 'Not Authorized, Token Failed') {
                    dispatch(logout())
                }

                dispatch({
                    type: JOB_FINAL_INTERVIEW_FAIL,
                    payload: msg,
                });
            }
        };


export const listOfferCandidates =
    (id, page = 1, limit = 5, filter = '',) =>
        async (dispatch, getState) => {
            try {
                dispatch({ type: JOB_OFFER_REQUEST });

                const {
                    userLogin: { userInfo },
                } = getState();

                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                };

                const { data } = await axios.get(
                    `/api/jobs/${id}/offer?filter=${filter}&page=${page}&limit=${limit}`, config

                );

                dispatch({
                    type: JOB_OFFER_SUCCESS,
                    payload: data,
                });
            } catch (error) {
                const msg = error.response && error.response.data.msg
                if (msg === 'Not Authorized, Token Failed') {
                    dispatch(logout())
                }

                dispatch({
                    type: JOB_OFFER_FAIL,
                    payload: msg,
                });
            }
        };


export const resetJobCreate = () => (dispatch) =>
    dispatch({ type: JOB_CREATE_RESET });

export const resetJobPublish = () => (dispatch) =>
    dispatch({ type: JOB_PUBLISH_RESET });

export const resetJobClose = () => (dispatch) =>
    dispatch({ type: JOB_CLOSE_RESET });

export const resetJobReject = () => (dispatch) =>
    dispatch({ type: JOB_REJECT_RESET });

export const resetJobDetails = () => (dispatch) =>
    dispatch({ type: JOB_DETAILS_RESET });

export const resetJobDelete = () => (dispatch) =>
    dispatch({ type: JOB_DELETE_RESET });

export const resetJobUpdate = () => (dispatch) =>
    dispatch({ type: JOB_UPDATE_RESET });

export const resetJobCreateForm = () => dispatch => dispatch({
    type: RESET_FORM,

})
export const resetApplicationWithdraw = () => (dispatch) =>
    dispatch({ type: APPLICATION_WITHDRAW_RESET });
export const resetJobApply = () => (dispatch) =>
    dispatch({ type: JOB_APPLY_RESET });

export const resetJobWishlist = () => (dispatch) =>
    dispatch({ type: JOB_WISHLIST_RESET });