import axios from "axios"
import { SUBSCRIBE_FAIL, SUBSCRIBE_REQUEST, SUBSCRIBE_RESET, SUBSCRIBE_SUCCESS } from "../types/paymentTypes"
import { logout } from "./userActions"

export const subscribe = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SUBSCRIBE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post(`/api/pay`, {}, config)

        if (data.authorization_url) {
            window.location.href = data.authorization_url;
        } else {
            dispatch({
                type: SUBSCRIBE_FAIL,
                payload: "Error generating Payment Link",
            })
        }

        dispatch({
            type: SUBSCRIBE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed' || msg === "jwt expired") {
            dispatch(logout())
        }
        dispatch({
            type: SUBSCRIBE_FAIL,
            payload: msg,
        })
    }
}



export const resetSubscribe = () => (dispatch) =>
    dispatch({ type: SUBSCRIBE_RESET });