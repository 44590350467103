import {
    APPLICATION_WITHDRAW_FAIL,
    APPLICATION_WITHDRAW_REQUEST,
    APPLICATION_WITHDRAW_RESET,
    APPLICATION_WITHDRAW_SUCCESS,
    DRAFT_LIST_FAIL,
    DRAFT_LIST_REQUEST,
    DRAFT_LIST_SUCCESS,
    JOB_APPLICANTS_FAIL,
    JOB_APPLICANTS_REQUEST,
    JOB_APPLICANTS_SUCCESS,
    JOB_APPLY_FAIL,
    JOB_APPLY_REQUEST,
    JOB_APPLY_RESET,
    JOB_APPLY_SUCCESS,
    JOB_CLOSE_FAIL,
    JOB_CLOSE_REQUEST,
    JOB_CLOSE_RESET,
    JOB_CLOSE_SUCCESS,
    JOB_CREATE_FAIL,
    JOB_CREATE_REQUEST,
    JOB_CREATE_RESET,
    JOB_CREATE_SUCCESS,
    JOB_DELETE_FAIL,
    JOB_DELETE_REQUEST,
    JOB_DELETE_RESET,
    JOB_DELETE_SUCCESS,
    JOB_DETAILS_FAIL,
    JOB_DETAILS_REQUEST,
    JOB_DETAILS_RESET,
    JOB_DETAILS_SUCCESS,
    JOB_FINAL_INTERVIEW_FAIL,
    JOB_FINAL_INTERVIEW_REQUEST,
    JOB_FINAL_INTERVIEW_SUCCESS,
    JOB_INITIAL_CALL_FAIL,
    JOB_INITIAL_CALL_REQUEST,
    JOB_INITIAL_CALL_SUCCESS,
    JOB_LIST_FAIL,
    JOB_LIST_REQUEST,
    JOB_LIST_SUCCESS,
    JOB_OFFER_FAIL,
    JOB_OFFER_REQUEST,
    JOB_OFFER_SUCCESS,
    JOB_PUBLISH_FAIL,
    JOB_PUBLISH_REQUEST,
    JOB_PUBLISH_RESET,
    JOB_PUBLISH_SUCCESS,
    JOB_REJECT_FAIL,
    JOB_REJECT_REQUEST,
    JOB_REJECT_RESET,
    JOB_REJECT_SUCCESS,
    JOB_SHORTLISTED_FAIL,
    JOB_SHORTLISTED_REQUEST,
    JOB_SHORTLISTED_SUCCESS,
    JOB_UPDATE_FAIL,
    JOB_UPDATE_REQUEST,
    JOB_UPDATE_RESET,
    JOB_UPDATE_SUCCESS,
    JOB_WISHLIST_FAIL,
    JOB_WISHLIST_REQUEST,
    JOB_WISHLIST_RESET,
    JOB_WISHLIST_SUCCESS,
    MY_JOB_LIST_FAIL,
    MY_JOB_LIST_REQUEST,
    MY_JOB_LIST_SUCCESS,
    PUBLISHED_JOB_LIST_FAIL,
    PUBLISHED_JOB_LIST_REQUEST,
    PUBLISHED_JOB_LIST_SUCCESS,
    RESET_FORM,
    UPDATE_FORM,
} from '../types/jobTypes';

export const jobListReducer = (state = { jobs: [] }, action) => {
    switch (action.type) {
        case JOB_LIST_REQUEST:
            return { loading: true, jobs: [] };
        case JOB_LIST_SUCCESS:
            return {
                loading: false,
                jobs: action.payload.data,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                pageSize: action.payload.pageSize,
                totalJobs: action.payload.totalItems,
                totalDrafts: action.payload.totalDrafts
            };
        case JOB_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const jobApplicantListReducer = (state = { applicants: [] }, action) => {
    switch (action.type) {
        case JOB_APPLICANTS_REQUEST:
            return { loading: true, applicants: [] };
        case JOB_APPLICANTS_SUCCESS:
            return {
                loading: false,
                applicants: action.payload.data,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                pageSize: action.payload.pageSize,
                totalApplicants: action.payload.totalItems
            };
        case JOB_APPLICANTS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const shortListedCandidatesListReducer = (state = { shortlisted: [] }, action) => {
    switch (action.type) {
        case JOB_SHORTLISTED_REQUEST:
            return { loading: true, shortlisted: [] };
        case JOB_SHORTLISTED_SUCCESS:
            return {
                loading: false,
                shortlisted: action.payload.data,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                pageSize: action.payload.pageSize,
                totalshortlisted: action.payload.totalItems
            };
        case JOB_SHORTLISTED_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};


export const initialInterviewCandidatesListReducer = (state = { initialInterview: [] }, action) => {
    switch (action.type) {
        case JOB_INITIAL_CALL_REQUEST:
            return { loading: true, initialInterview: [] };
        case JOB_INITIAL_CALL_SUCCESS:
            return {
                loading: false,
                initialInterview: action.payload.data,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                pageSize: action.payload.pageSize,
                totalInitialInterview: action.payload.totalItems
            };
        case JOB_INITIAL_CALL_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const finalInterviewCandidatesListReducer = (state = { finalInterview: [] }, action) => {
    switch (action.type) {
        case JOB_FINAL_INTERVIEW_REQUEST:
            return { loading: true, finalInterview: [] };
        case JOB_FINAL_INTERVIEW_SUCCESS:
            return {
                loading: false,
                finalInterview: action.payload.data,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                pageSize: action.payload.pageSize,
                totalFinalInterview: action.payload.totalItems
            };
        case JOB_FINAL_INTERVIEW_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};


export const offerCandidatesListReducer = (state = { offer: [] }, action) => {
    switch (action.type) {
        case JOB_OFFER_REQUEST:
            return { loading: true, offer: [] };
        case JOB_OFFER_SUCCESS:
            return {
                loading: false,
                offer: action.payload.data,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                pageSize: action.payload.pageSize,
                totalOffers: action.payload.totalItems
            };
        case JOB_OFFER_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const draftListReducer = (state = { jobs: [] }, action) => {
    switch (action.type) {
        case DRAFT_LIST_REQUEST:
            return { loading: true, jobs: [] };
        case DRAFT_LIST_SUCCESS:
            return {
                loading: false,
                jobs: action.payload.data,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                pageSize: action.payload.pageSize,
                totalDrafts: action.payload.totalItems,

            };
        case DRAFT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const publishedJobListReducer = (state = { jobs: [] }, action) => {
    switch (action.type) {
        case PUBLISHED_JOB_LIST_REQUEST:
            return { loading: true, jobs: [] };
        case PUBLISHED_JOB_LIST_SUCCESS:
            return {
                loading: false,
                jobs: action.payload.data,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                pageSize: action.payload.pageSize,
                totalJobs: action.payload.totalItems,
            };
        case PUBLISHED_JOB_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};


export const myJobListReducer = (state = { jobs: [] }, action) => {
    switch (action.type) {
        case MY_JOB_LIST_REQUEST:
            return { loading: true, jobs: [] };
        case MY_JOB_LIST_SUCCESS:
            return {
                loading: false,
                jobs: action.payload,
                // pages: action.payload.pages,
                // page: action.payload.page,
            };
        case MY_JOB_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const jobDetailsReducer = (state = { job: {} }, action) => {
    switch (action.type) {
        case JOB_DETAILS_REQUEST:
            return {
                loading: true,
                ...state,
            };
        case JOB_DETAILS_SUCCESS:
            return {
                loading: false,
                job: action.payload,
            };
        case JOB_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case JOB_DETAILS_RESET:
            return {
                job: {},
            };

        default:
            return state;
    }
};

export const jobDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case JOB_DELETE_REQUEST:
            return { loading: true };
        case JOB_DELETE_SUCCESS:
            return { loading: false, success: true };
        case JOB_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case JOB_DELETE_RESET:
            return {};

        default:
            return state;
    }
};

export const jobCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case JOB_CREATE_REQUEST:
            return { loading: true };
        case JOB_CREATE_SUCCESS:
            return { loading: false, success: true, job: action.payload };
        case JOB_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case JOB_CREATE_RESET:
            return {};
        default:
            return state;
    }
};

export const jobUpdateReducer = (state = { job: {} }, action) => {
    switch (action.type) {
        case JOB_UPDATE_REQUEST:
            return { loading: true };
        case JOB_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case JOB_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case JOB_UPDATE_RESET:
            return {
                job: {},
            };

        default:
            return state;
    }
};

export const jobApplyReducer = (state = {}, action) => {
    switch (action.type) {
        case JOB_APPLY_REQUEST:
            return { loading: true };
        case JOB_APPLY_SUCCESS:
            return { loading: false, success: true };
        case JOB_APPLY_FAIL:
            return { loading: false, error: action.payload };
        case JOB_APPLY_RESET:
            return {};
        default:
            return state;
    }
};

export const applicationWithdrawReducer = (state = {}, action) => {
    switch (action.type) {
        case APPLICATION_WITHDRAW_REQUEST:
            return { loading: true };
        case APPLICATION_WITHDRAW_SUCCESS:
            return { loading: false, success: true };
        case APPLICATION_WITHDRAW_FAIL:
            return { loading: false, error: action.payload };
        case APPLICATION_WITHDRAW_RESET:
            return {};
        default:
            return state;
    }
};

export const jobWishlistReducer = (state = {}, action) => {
    switch (action.type) {
        case JOB_WISHLIST_REQUEST:
            return { loading: true };
        case JOB_WISHLIST_SUCCESS:
            return { loading: false, success: true };
        case JOB_WISHLIST_FAIL:
            return { loading: false, error: action.payload };
        case JOB_WISHLIST_RESET:
            return {};
        default:
            return state;
    }
};

export const jobPublishReducer = (state = {}, action) => {
    switch (action.type) {
        case JOB_PUBLISH_REQUEST:
            return { loading: true };
        case JOB_PUBLISH_SUCCESS:
            return { loading: false, success: true };
        case JOB_PUBLISH_FAIL:
            return { loading: false, error: action.payload };
        case JOB_PUBLISH_RESET:
            return {};
        default:
            return state;
    }
};

export const jobCloseReducer = (state = {}, action) => {
    switch (action.type) {
        case JOB_CLOSE_REQUEST:
            return { loading: true };
        case JOB_CLOSE_SUCCESS:
            return { loading: false, success: true };
        case JOB_CLOSE_FAIL:
            return { loading: false, error: action.payload };
        case JOB_CLOSE_RESET:
            return {};
        default:
            return state;
    }
};

export const jobRejectReducer = (state = {}, action) => {
    switch (action.type) {
        case JOB_REJECT_REQUEST:
            return { loading: true };
        case JOB_REJECT_SUCCESS:
            return { loading: false, success: true };
        case JOB_REJECT_FAIL:
            return { loading: false, error: action.payload };
        case JOB_REJECT_RESET:
            return {};
        default:
            return state;
    }
};

export const formReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_FORM:
            return {
                ...state,
                formData: {
                    ...state.formData,
                    // Update specific fields based on payload keys
                    ...Object.keys(action.payload).reduce((updatedData, key) => {
                        return {
                            ...updatedData,
                            [key]: action.payload[key],
                        };
                    }, {}),
                },
            };
        case RESET_FORM:
            return {}
        default:
            return state;
    }
}