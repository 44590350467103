import { Box, Grid, Typography } from "@mui/material"
import Card from "../../Card"
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

// import LineChartDataset from "./LineChartDataSet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listEmployers, listSeekers, setItem } from "../../../actions/userActions";
import { useEffect } from "react";
import { listJobs } from "../../../actions/jobActions";

const Dashboard = () => {

    const { totalSeekers } = useSelector(state => state.seekerList)
    const { totalEmployers } = useSelector(state => state.employerList)
    const { totalDrafts } = useSelector((state) => state.jobList);

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(listSeekers())
        dispatch(listEmployers())
        dispatch(listJobs())
    }, [dispatch])


    return (
        <Box>
            <Grid container>
                <Grid xs={4}
                    onClick={() => {
                        dispatch(setItem("users"))
                        navigate("/users")
                    }}
                    sx={{ cursor: "pointer" }}
                >

                    <Card
                        color="#167dc1"
                        metricName="Job Seekers"
                        metricNumber={totalSeekers}
                        Icon={WorkOutlineIcon} />
                </Grid>
                <Grid xs={4}
                    onClick={() => {
                        dispatch(setItem("users"))
                        navigate("/users")
                    }}
                    sx={{ cursor: "pointer" }}
                >
                    <Card color="#ebbd1a"
                        metricName="Employers"
                        metricNumber={totalEmployers}

                        Icon={PersonOutlineOutlinedIcon} />
                </Grid>
                <Grid xs={4}
                    onClick={() => {
                        dispatch(setItem("jobs"))
                        navigate("/jobs")
                    }}
                    sx={{ cursor: "pointer" }}
                >
                    <Card
                        color="#18bebe"
                        metricName="Drafts"
                        metricNumber={totalDrafts}

                        Icon={CheckBoxOutlinedIcon}
                    />
                </Grid>

            </Grid>
            <Typography variant="h5" sx={{ mt: 5, mb: 2 }}>
                {/* Revenue Statistics */}
            </Typography>


            {/* 
            <LineChartDataset /> */}




        </Box >
    )
}

export default Dashboard