import { LoadingButton } from "@mui/lab"
import { Avatar, Box, Button, CircularProgress, Grid, TextField, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserDetails, setItem, userVerifyReset, verifyUser } from "../../../actions/userActions";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import DownloadIcon from '@mui/icons-material/Download';


import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { downloadFile } from "../../../utils/Validate";




const EmployerDetails = () => {


    const { user, loading } = useSelector((state) => state.userDetails);

    const { id } = useParams()


    const navigate = useNavigate()
    const dispatch = useDispatch();

    const { userInfo } = useSelector((state) => state.userLogin);
    const userType = userInfo.type
    const { success, loading: loadingVerify } = useSelector((state) => state.userVerify);




    useEffect(() => {
        if ((user && user._id !== id)) {

            dispatch(getUserDetails(id));
        }

        if (success) {

            toast("Employer Verified Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            })

            dispatch(userVerifyReset())
            dispatch(getUserDetails(id));
        }
    }, [dispatch, user, userInfo, success, id])


    return (
        <Grid container mt={3}>
            <Grid xs={2}></Grid>
            <Grid xs={8}>
                <ToastContainer />

                {loading ? <Typography textAlign="center"><CircularProgress /></Typography> : (

                    <Box>
                        <Typography display="flex" justifyContent="center">
                            <Avatar src={user?.avatar} alt={user.firstname} sx={{ width: 120, height: 120 }} />
                        </Typography>

                        <TextField
                            margin="normal"

                            fullWidth

                            label="First Name"
                            value={user.firstname}

                            autoComplete="First Name"
                            autoFocus
                        />
                        <TextField
                            margin="normal"

                            fullWidth

                            label="Last name"
                            value={user.lastname}

                            autoComplete="Last name"
                            autoFocus
                        />
                        <TextField
                            margin="normal"

                            fullWidth
                            type="email"

                            value={user.email}
                            autoComplete="Email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"

                            fullWidth

                            label="Company Name"
                            value={user.company}

                            autoFocus
                        />
                        <TextField
                            margin="normal"

                            fullWidth

                            label="LinkedIn Profile"
                            value={user?.linkedin}
                            autoComplete="LinkedIn"
                            autoFocus
                        />

                        <Typography variant="h6" my={2}>Download Proof Of Company Ownership</Typography>

                        <div>
                            <Button
                                variant="contained"
                                startIcon={<DownloadIcon />}
                                fullWidth
                                onClick={() =>
                                    downloadFile(
                                        `${window.location.origin
                                        }/uploads/${user.proof.slice(9)}`
                                    )
                                }
                            >Download CAC</Button>
                        </div>




                        {(userType === "PortalAdmin" && !user.isVerified) && (
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                                <Button
                                    variant="text"
                                    startIcon={<KeyboardBackspaceOutlinedIcon />}
                                    onClick={() => {
                                        dispatch(setItem("users"))
                                        navigate("/users")
                                    }}
                                >Back</Button>

                                <LoadingButton
                                    variant="contained"
                                    endIcon={<ThumbUpOffAltIcon />}
                                    onClick={() => dispatch(verifyUser(id))}
                                    loading={loadingVerify}
                                >Approve Employer</LoadingButton>

                            </div>
                        )}
                    </Box>
                )}


            </Grid>
            <Grid xs={2}></Grid>
        </Grid>
    )
}




export default EmployerDetails