import { Grid } from "@mui/material"
import HorizontalLinearStepper from "./HorizontalLinearStepper"


const JobStepper = () => {
    return (
        <Grid container>
            <Grid xs={2}></Grid>
            <Grid xs={8}>
                <HorizontalLinearStepper />
            </Grid>
            <Grid xs={2}></Grid>
        </Grid>
    )
}

export default JobStepper