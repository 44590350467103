import { Box, Button, CircularProgress, Container, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Modal, Pagination, Radio, RadioGroup, Select, Typography, styled, useMediaQuery } from "@mui/material"
import SearchBar from "../../UI/SearchBar"
import SearchIcon from '@mui/icons-material/Search';

import Job from "../../components/job/Job";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { listPublishedJobs, resetJobDelete } from "../../actions/jobActions";
import { useNavigate } from "react-router-dom";
import ResponsiveAppBar from "../../UI/AppBar";
import { ToastContainer, toast } from "react-toastify";
import { getUserDetails, setItem } from "../../actions/userActions";
import axios from "axios";
import { calculateProfileCompleteness } from "../../utils/Validate";

import { useTheme } from '@mui/material/styles';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #eaeaea',
    boxShadow: 24,
    p: 4,
    borderRadius: "20px"
};


const JobList = () => {
    const [type, setType] = useState('')
    const [experienceLevel, setExperienceLevel] = useState('')
    const [mode, setMode] = useState("")

    // const [title, setTitle] = useState("")
    const [searchParam, setSearchParam] = useState("")
    // const [industry, setIndustry] = useState("")

    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(5);
    const [uploading, setUploading] = useState(false)
    // const [resume, setResume] = useState("")

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const { userInfo } = useSelector(state => state.userLogin)
    const { user } = useSelector(state => state.userDetails)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };



    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });


    const uploadResumeHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('resume', file)
        setUploading(true)
        handleClose()

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            const res = await axios.post('/api/upload/', formData, config)
            if (res.data.success) {
                setUploading(false)
                dispatch(getUserDetails("profile"))
                setTimeout(() => {
                    dispatch(setItem("profile"))
                    navigate('/profile');
                }, 3000);
                return toast('Resume Parsed Successfully', {
                    className: 'toast-success',
                    bodyClassName: 'toast-success',
                });
            }



        } catch (error) {
            console.error(error)
            setUploading(false)
            if (error.response && error.response.data) {
                console.log(error.response.data);
                return toast(error.response.data.message, {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            } else {
                // Handle unexpected errors
                console.error('Unexpected error:', error);
                return toast('Something went wrong', {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                });
            }
        }
    }


    const [filter, setFilter] = useState('')
    const [timeFilter, setTimeFilter] = useState('all')

    const { jobs, totalPages, loading, error, totalJobs } = useSelector((state) => state.publishedJobList);
    const { success } = useSelector((state) => state.jobDelete);



    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleModeChange = e => {

        const modeValue = e.target.value;
        setMode(modeValue);
        setFilter(modeValue);
    };

    // const handleTitleChange = e => {

    //     const titleValue = e.target.value;
    //     setTitle(titleValue);
    //     setFilter(titleValue);
    // };

    const handleSearchChange = e => {

        const searchedValue = e.target.value;
        setSearchParam(searchedValue);
        setFilter(searchedValue);
    };

    // const handleIndustryChange = e => {

    //     const industryValue = e.target.value;
    //     setIndustry(industryValue);
    //     setFilter(industryValue);
    // };

    const handleTypeChange = e => {

        const typeValue = e.target.value;
        setType(typeValue);
        setFilter(typeValue);
    };

    const handleExperienceChange = e => {

        const experienceValue = e.target.value;
        setExperienceLevel(experienceValue);
        setFilter(experienceValue);
    };

    const handleFilterChange = (value) => {

        setTimeFilter(value)

    };




    useEffect(() => {
        if (userInfo) {
            getUserDetails('profile')
        }
        if (success) {

            toast("Job Deleted", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
            dispatch(resetJobDelete())

        }




        dispatch(listPublishedJobs(filter, page, rowsPerPage, timeFilter))


    }, [dispatch, filter, rowsPerPage, page, success, userInfo, timeFilter])





    return (
        <Fragment>
            <ResponsiveAppBar />


            <Container sx={{ p: 4, borderRadius: "10px", background: "white", marginTop: "150px" }}>
                <ToastContainer />

                <Typography component="h3" variant="h4">Find your new Job Today</Typography>
                <Typography sx={{ color: "#8c8c8c", my: 3 }}>
                    Thousands of jobs in the computer, engineering and technology sectors are waiting for you.
                </Typography>

                <Grid container>
                    <Grid xs={12} md={2} mb={3} item>
                        {/* <SearchBar
                            icon="search"
                            placeholder="Search Jobs by Role or Title"
                            name="title" value={title} onChange={handleTitleChange}
                        >
                            <SearchIcon />
                        </SearchBar> */}
                    </Grid>
                    <Grid xs={12} md={8} mb={3} item>
                        <SearchBar
                            icon="search"
                            placeholder="Search For Any Job"
                            name="anything" value={searchParam} onChange={handleSearchChange}
                        >
                            <SearchIcon />
                        </SearchBar>
                    </Grid>
                    <Grid xs={12} md={2} mb={3} item>
                        {/* <SearchBar
                            icon="industry"
                            placeholder="Search Jobs by Industry"
                            name="industry" value={industry} onChange={handleIndustryChange}
                        >
                            <FactoryIcon />
                        </SearchBar> */}
                    </Grid>
                    {/* <Grid xs={2} >
                        <Button variant="contained" sx={{ marginTop: "3px", padding: "10px 20px", display: "flex", alignItems: "center" }}>Search Job</Button>
                    </Grid> */}
                </Grid>

                <Grid container sx={{ marginTop: isSmallScreen ? -5 : 15 }}>

                    <Grid xs={12} sm={6} md={3} item sx={{ mb: isSmallScreen && "40px" }}>

                        {!isSmallScreen && (



                            <Box sx={{
                                borderRadius: "10px",
                                border: "2px solid #f8f8f8",
                                py: 3,
                                px: 2
                            }}>

                                <Typography variant="h6" sx={{ mb: 3 }}>Filters</Typography>
                                <Typography sx={{ my: 1 }}>Type of Employment</Typography>
                                <FormControl fullWidth>
                                    <InputLabel id="employment-type">Type of Employment</InputLabel>
                                    <Select
                                        labelId="employment-type"
                                        id="select-employment-type"

                                        label="Employment Type"

                                        name="type"
                                        value={type}
                                        onChange={handleTypeChange}

                                    >

                                        <MenuItem value="Full-time">Full-Time</MenuItem>
                                        <MenuItem value="Part-Time">Part-Time</MenuItem>
                                        <MenuItem value="Freelance">Freelance</MenuItem>


                                    </Select>
                                </FormControl>
                                <FormControl sx={{ my: 3 }}>
                                    <FormLabel id="demo-radio-buttons-group-label" sx={{ color: "#000", mt: 4 }}> Date of Posting</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="All time"
                                        name="radio-buttons-group"
                                        value={timeFilter}
                                        onChange={(e) => handleFilterChange(e.target.value)}


                                    >
                                        <FormControlLabel value="all" control={<Radio />} label="All time" />
                                        <FormControlLabel value="24h" control={<Radio />} label="Last 24 Hours" />
                                        <FormControlLabel value="7d" control={<Radio />} label="Last 7 Days" />
                                        <FormControlLabel value="1m" control={<Radio />} label="Last Month" />
                                    </RadioGroup>
                                </FormControl>
                                <Typography sx={{ my: 1 }}>Experience Level</Typography>
                                <FormControl fullWidth>
                                    <InputLabel id="experience-level">Experience Level</InputLabel>
                                    <Select
                                        labelId="experience-level"
                                        id="select-experience"

                                        label="Experience Level"
                                        name="experienceLevel"
                                        value={experienceLevel}
                                        onChange={handleExperienceChange}



                                    >

                                        <MenuItem value="Entry-Level">Entry-Level</MenuItem>
                                        <MenuItem value="Intermediate">Intermediate</MenuItem>
                                        <MenuItem value="Lead">Lead</MenuItem>
                                        <MenuItem value="Manager">Manager</MenuItem>
                                        <MenuItem value="Senior Manager">Senior Manager</MenuItem>

                                    </Select>
                                </FormControl>

                                <FormControl sx={{ my: 3 }}>
                                    <FormLabel id="demo-radio-buttons-group-label" sx={{ color: "#000", mt: 4 }}>Work Environment</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"


                                        name="mode"
                                        value={mode}
                                        onChange={handleModeChange}

                                    >
                                        <FormControlLabel value="Onsite" control={<Radio />} label="Onsite" />
                                        <FormControlLabel value="Hybrid" control={<Radio />} label="Hybrid" />
                                        <FormControlLabel value="Remote" control={<Radio />} label="Remote" />

                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        )}
                    </Grid>
                    <Grid xs={12} md={7} item>
                        <Box sx={{ mx: isSmallScreen ? 0 : 2, }}>

                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Box display="flex" justifyContent="space-around">
                                        <Typography id="modal-modal-title" variant="h5" component="h6" mt={2}>
                                            Update your Profile
                                        </Typography>
                                        <Typography variant='h5'
                                            sx={{ cursor: "pointer", fontWeight: 800, color: "#ff499e" }}
                                            onClick={handleClose}>X</Typography>
                                    </Box>

                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Your Profile is not Complete, Finish creating your profile to Start
                                        applying for Jobs
                                    </Typography>
                                    <Box display="flex" justifyContent="space-between" mt={4}>
                                        <LoadingButton
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            onChange={uploadResumeHandler}
                                            className="button-secondary"
                                            sx={{ fontSize: "10px" }}
                                            size="small"
                                            loading={uploading}

                                        >
                                            Upload Resume
                                            <VisuallyHiddenInput type="file" />
                                        </LoadingButton>
                                        <Button className="button-primary"
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                dispatch(setItem("profile"))
                                                navigate("/profile")
                                            }}
                                            sx={{ fontSize: "10px" }}
                                        >Update Profile</Button>
                                    </Box>
                                </Box>
                            </Modal>
                            <Typography variant="h6" mt={isSmallScreen && 3} mb={1}>
                                {totalJobs} Jobs
                            </Typography>



                            {loading ? <Typography textAlign="center"><CircularProgress /></Typography> :
                                error ? <Typography>{error}</Typography> : !jobs ? <Typography variant="h5" textAlign="center">There are no Opportunities Available</Typography> :
                                    // publishedAt ?
                                    //     filteredJobs?.map(job => (
                                    //         <Job {...job} />
                                    //     )) :
                                    jobs?.map(job => (
                                        <Job {...job} />
                                    ))}
                        </Box>
                        <Box mb={isSmallScreen && 5}>
                            <Pagination
                                count={totalPages} // Total number of pages
                                page={page} // Current page
                                onChange={handleChangePage} // Function to change the page
                                color="primary"
                                shape="rounded"

                            />
                        </Box>
                        {/* {loading ? <Typography textAlign="center"><CircularProgress /></Typography> :
                                error ? <Typography>{error}</Typography> : !jobs ? <Typography variant="h5" textAlign="center">There are no Opportunities Available</Typography> :
                                    publishedAt ?
                                        filteredJobs?.map(job => (
                                            <Job {...job} />
                                        )) :
                                        jobs?.map(job => (
                                            <Job {...job} />
                                        ))}


                        </Box>
                        {(jobs?.length > 10 || filteredJobs?.length > 10) && (
                            <Box>
                                <TablePaginationDemo />
                            </Box>
                        )} */}





                    </Grid>
                    <Grid xs={12} md={2} item>
                        {!userInfo ? (

                            <Box sx={{
                                borderRadius: "10px",
                                border: "2px solid #f8f8f8",
                                py: 3,
                                px: 1,
                                textAlign: "center"
                            }}>
                                <Typography mb={2}>Sign Up to Apply</Typography>
                                <Typography sx={{ fontSize: "12px", color: "#8c8c8c" }}>
                                    Sign up to start applying for Jobs
                                </Typography>
                                <LoadingButton
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    sx={{ my: 3, fontSize: "12px" }}
                                    onClick={() => navigate("/register")}
                                    className="button-primary"
                                >Sign Up</LoadingButton>

                            </Box>

                        ) : user && (calculateProfileCompleteness(user) !== 100) ? (
                            <Box sx={{
                                borderRadius: "10px",
                                border: "2px solid #f8f8f8",
                                py: 3,
                                px: 1,
                                textAlign: "center"
                            }}>
                                <Typography mb={2}>Set Up Profile</Typography>
                                <Typography sx={{ fontSize: "12px", color: "#8c8c8c" }}>
                                    Please Complete your Profile to start applying for Jobs
                                </Typography>
                                <Button
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    sx={{ my: 3, fontSize: "12px" }}
                                    onClick={handleOpen}
                                    className="button-primary"
                                >Set up Profile</Button>

                            </Box>
                        ) : null}

                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    )
}

export default JobList