import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { useState } from "react";
import { sanitizeInput, states } from '../../../utils/Validate';

const ExperienceForm = ({ onAdd }) => {
    const [title, setTitle] = useState('');
    const [organization, setOrganization] = useState('');
    const [location, setLocation] = useState('');
    const [date_start, setExpStart] = useState(dayjs());
    const [date_end, setExpEnd] = useState(dayjs());
    const [currentlyWorkHere, setCurrentlyWorkHere] = useState(false);
    const [responsibilities, setResponsibilities] = useState('');



    const handleSubmit = (event) => {
        event.preventDefault();
        const newExperience = { title, organization, location, date_start, date_end, currentlyWorkHere, responsibilities };
        onAdd(newExperience); // Call the provided function to handle form submission
        setTitle(''); // Reset form after submission
        setOrganization('');
        setLocation('')
        setExpStart(dayjs())
        setExpEnd(dayjs())
        setCurrentlyWorkHere('')
        setResponsibilities('')
    }

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                margin="normal"

                fullWidth
                sx={{ mb: 2 }}

                label="Job Title"
                name="title"
                value={sanitizeInput(title)}
                onChange={(e) => setTitle(e.target.value)}

                autoComplete="job Title"
                autoFocus
            />


            <TextField
                margin="normal"

                fullWidth
                sx={{ mb: 3 }}
                label="Organization"
                name="organization"

                value={sanitizeInput(organization)}
                onChange={(e) => setOrganization(e.target.value)}

                autoComplete="Organization"
                autoFocus
            />

            <FormControl fullWidth sx={{ my: 2 }}>
                <InputLabel id={`location`}>Location</InputLabel>
                <Select
                    labelId={`location`}
                    id={`location`}
                    value={location}
                    label="Location"
                    required
                    onChange={(e) => setLocation(e.target.value)}
                >

                    {states.map(state => (
                        <MenuItem value={state} key={state}>{state}</MenuItem>
                    ))}

                </Select>
            </FormControl>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ marginRight: "10px" }}>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                            <DatePicker
                                label="Start Date"
                                name="date_start"

                                value={date_start}
                                onChange={(newValue) => setExpStart(newValue)}


                            />

                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                <div style={{ marginLeft: "10px" }}>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>

                            <DatePicker
                                label="End Date"
                                name="date_end"

                                value={date_end}
                                onChange={(newValue) => setExpEnd(newValue)}

                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>


            </div>

            <FormGroup sx={{ mt: 2 }}>

                <FormControlLabel control={<Checkbox
                    checked={currentlyWorkHere}

                    onChange={() => setCurrentlyWorkHere(!currentlyWorkHere)}
                />} label="I currently work here" />

                <TextField
                    onChange={(e) => setResponsibilities(e.target.value)}
                    label="Job Responsibilities"
                    multiline
                    value={sanitizeInput(responsibilities)}
                    rows={4}
                    placeholder="Outline your Achievements in this Role"
                    sx={{ mt: 2 }}
                />


            </FormGroup>
            {/* Similar input fields and logic for field, qualification, eduStart, eduEnd */}
            <Typography textAlign="center" mt={5}>
                <Button type="submit" variant="contained" className="button-primary">Add Experience</Button>
            </Typography>
        </form>
    )
}

export default ExperienceForm
