import { LoadingButton } from "@mui/lab"
import { Box, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useDispatch, useSelector } from "react-redux";
import { loginUser, setItem } from "../../actions/userActions";
import { ToastContainer, toast } from "react-toastify";
import { isEmpty } from "../../utils/Validate";
import { USER_LOGOUT } from "../../types/userTypes";
import { useLocation, } from 'react-router-dom';




const Login = () => {
    const [showPassword, setShowPassword] = useState(false);


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // const [message, setMessage] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { loading, userInfo, error } = useSelector((state) => state.userLogin);





    const loginHandler = (e) => {
        e.preventDefault();

        if (isEmpty(email) || isEmpty(password)) {
            return toast("Please Enter Your Username and Password", {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }

        dispatch(loginUser(email, password));

    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const redirectUrl = new URLSearchParams(location.search).get('redirect');

    useEffect(() => {
        if (userInfo) {

            if (userInfo.type === "JobSeeker") {

                navigate(redirectUrl || '/');
            } else {
                dispatch(setItem("dashboard"))
                navigate('/dashboard');
            }

        }

        if (error) {
            toast(error, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
            dispatch({
                type: USER_LOGOUT,
            });
        }
    }, [userInfo, navigate, dispatch, error, redirectUrl]);

    return (

        <Grid container>
            <Grid xs={12} sm={6} md={4} ></Grid>
            <Grid xs={12} sm={6} md={4} >
                <ToastContainer />

                <Box sx={{ padding: "40px 20px", borderRadius: "10px", background: "white", mt: 5 }}>
                    <form onSubmit={loginHandler}>
                        <Typography component="h1" variant="h5" sx={{ textAlign: "center" }}>
                            Login
                        </Typography>
                        <TextField
                            margin="normal"

                            fullWidth
                            type="email"
                            label="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            autoComplete="Email"
                            autoFocus
                        />
                        <FormControl sx={{ my: 3 }} variant="outlined" fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                fullWidth
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        <LoadingButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ my: 3 }}
                            loading={loading}
                            disabled={email.length < 6 || password.length < 6}
                            className="button-primary"
                        >
                            Login
                        </LoadingButton>
                    </form>
                    <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography>

                            Don&apos;t have an account? <Link to="/register" > Signup </Link>
                        </Typography>
                        <Typography><Link to='/auth/forgot-password'>Forgot Password ?</Link></Typography>
                    </Grid>

                </Box>


            </Grid>
            <Grid xs={12} sm={6} md={4} ></Grid>
        </Grid>


    )
}

export default Login