

import TabComponent from '../../../UI/TabComponent';

import { Grid } from '@mui/material';


import { useSelector } from 'react-redux';

export default function JobList() {
    const { userInfo } = useSelector((state) => state.userLogin);
    const userType = userInfo.type

    const labels = [
        {
            value: "one",
            text: "Drafts"
        },
        {
            value: "two",
            text: "Published"
        },
        {
            value: "three",
            text: "Rejected"
        },
        {
            value: "four",
            text: "Closed"
        },

    ]


    return (
        <Grid container width="100%">

            <TabComponent labels={labels} title="Drafts" userType={userType} />

        </Grid>
    );
}


