
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import { Avatar, Menu, MenuItem, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout, setItem } from '../actions/userActions';

export default function ResponsiveAppBar() {
    // const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const { userInfo } = useSelector((state) => state.userLogin);
    const userType = userInfo?.type
    const settings = userType === "JobSeeker" ? ['Dashboard', 'Profile', 'Applications', 'Logout'] :
        (userType === "Employer" || userType === "PortalAdmin") && ["Dashboard", "Profile", "Jobs", "Logout"]

    const navigate = useNavigate()
    const dispatch = useDispatch()

    //   const handleOpenNavMenu = (event) => {
    //     setAnchorElNav(event.currentTarget);
    //   };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = (item) => {
        item = item.toLowerCase()
        if (item === "logout") {
            dispatch(logout())
            navigate("/")
        } else {
            dispatch(setItem(item))
            navigate(`/${item}`)
        }

        setAnchorElUser(null);
    };

    //   const handleCloseNavMenu = () => {
    //     setAnchorElNav(null);
    //   };



    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" color="transparent" sx={{ backgroundColor: "#fff" }}>
                <Toolbar>

                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>

                        <img
                            src="/Proten-logo.png"
                            alt="Proten Intl"
                            height={150} width={150}
                            style={{ marginTop: "-20px", marginBottom: "-30px", cursor: "pointer" }}
                            onClick={() => navigate("/")}

                        />
                    </Typography>
                    {userInfo ? (
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt={userInfo.firstname} src={userInfo?.avatar} />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    ) : (<Box display="flex" justifyContent="space-evenly">
                        <Button sx={{ marginRight: "15px", fontWeight: 800, color: "#155986" }} className='primary' variant='text' onClick={() => navigate("/login")}>Post A Job</Button>
                        <Button className='button-primary' variant='contained' onClick={() => navigate("/login")}>Login</Button>

                    </Box>)}

                </Toolbar>
            </AppBar>
        </Box>
    );
}
