import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"

import { ArrowLeftIcon } from "@mui/x-date-pickers/icons"
import { LoadingButton } from "@mui/lab"
// import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { ToastContainer, toast } from "react-toastify"
import { createUser, resetUserCreate } from "../../../actions/userActions"


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #eaeaea',
    boxShadow: 24,
    p: 4,
    borderRadius: "20px"
};

const CreateUserForm = (props) => {
    const [email, setEmail] = useState("")
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")


    // const navigate = useNavigate()
    const dispatch = useDispatch()

    const { loading, success, error } = useSelector(state => state.userCreate)


    const handleSubmit = (e) => {
        e.preventDefault()
        const user = {
            email, firstname, lastname
        }

        dispatch(createUser(user))

        setFirstname("")
        setLastname("")
        setEmail("")



    }


    useEffect(() => {
        if (success) {

            toast('User Created Successfully', {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });

            // dispatch(resetUserCreate())
        }
        if (error) {
            toast(error, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
            dispatch(resetUserCreate())
        }
    }, [dispatch, success, error])

    return (
        <Grid container>
            <ToastContainer />
            <Grid xs={3}></Grid>
            <Grid xs={6}>
                <Modal
                    open={props.open}
                    onClose={props.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={style}
                    >
                        <Box display="flex" justifyContent="space-between">
                            <Typography id="modal-modal-title" variant="h5" component="h6" >
                                CREATE AN ADMIN
                            </Typography>
                            <Typography variant='h5'
                                sx={{ cursor: "pointer", fontWeight: 800, color: "#ff499e" }}
                                onClick={props.handleClose}>X</Typography>
                        </Box>
                        <form onSubmit={handleSubmit}>


                            <TextField
                                margin="normal"
                                value={firstname}
                                fullWidth
                                required
                                label="First Name"
                                onChange={(e) => setFirstname(e.target.value)}
                                autoComplete="First Name"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                value={lastname}
                                label="Last name"
                                onChange={(e) => setLastname(e.target.value)}
                                autoComplete="Last name"

                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                type="email"
                                label="Company Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="Company Email"

                            />

                            <Box display="flex" justifyContent="space-between">
                                <Button
                                    variant="text"
                                    startIcon={<ArrowLeftIcon />}
                                    onClick={() => {
                                        props.handleClose()
                                    }}
                                >Back</Button>
                                <LoadingButton type="submit"
                                    className="button-primary"
                                    loading={loading}
                                >
                                    Submit
                                </LoadingButton>
                            </Box>
                        </form>
                    </Box>
                </Modal>

            </Grid>
            <Grid xs={3}></Grid>

        </Grid>
    )
}

export default CreateUserForm