import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BarChartIcon from '@mui/icons-material/BarChart';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';

import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import JobSeekerProfileSetup from '../../components/user/JobSeeker/JobSeekerProfile';
import EmployerProfile from '../../components/user/Employer/Profile';
import AdminProfile from '../../components/user/Admin/Profile';
import Applications from '../../components/user/JobSeeker/Applications';
import Dashboard from '../../components/user/JobSeeker/Dashboard';
import EmployerDashboard from '../../components/user/Employer/Dashboard';
import AdminDashboard from '../../components/user/Admin/Dashboard';
import JobStepper from '../../components/job/JobStepper';
import { LoadingButton } from '@mui/lab';
import JobList from '../../components/job/JobList';
import AdminJobList from '../../components/user/Admin/JobList';
import JobDetails from '../../components/job/JobDetails';
import Applicants from '../../components/user/Employer/Applicants';
import JobSeekerDetails from '../../components/user/JobSeeker/JobSeekerDetails';
import UserList from '../../components/user/Admin/UserList';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout, setEditing, setItem } from '../../actions/userActions';
import Applicant from '../../components/user/Employer/Applicant';
import { resetJobDetails } from '../../actions/jobActions';
import EmployerDetails from '../../components/user/Admin/EmployerDetails';
import JobSeeker from '../../components/user/Admin/JobSeeker';
import EmployerBilling from '../../components/user/Employer/EmployerBilling';
import Settings from '../../components/user/Settings';
import UpdatePassword from './UpdatePassword';
import EducationItem from '../../components/user/JobSeeker/EducationItem';
import ExperienceItem from '../../components/user/JobSeeker/ExperienceItem';
import TicketList from '../../components/ticket/TicketList';
import { useMediaQuery } from '@mui/material';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = React.useState(isSmallScreen ? false : true);
    const { item } = useSelector((state) => state.itemState);
    const navigate = useNavigate();
    const { userInfo } = useSelector((state) => state.userLogin);
    const userType = userInfo.type
    const dispatch = useDispatch();




    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const logoutHandler = () => {
        dispatch(logout());
        navigate('/');
    };

    // const handleItemChange = (item) => setItem(item)



    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} color="transparent" sx={{ background: "#fff" }} >
                <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                    <React.Fragment>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        {!isSmallScreen && <Typography variant="h5" noWrap component="h5">
                            {item === "setup-profile" && "Set Up Your Profile"}
                            {item === "profile" && "Profile"}
                            {item === "education" && "Update Education"}
                            {item === "experience" && "Update Experience"}
                            {item === "dashboard" && "Dashboard"}
                            {item === "jobs" && "Jobs"}
                            {item === "users" && "Users"}
                            {item === "createJob" && "Create a Job"}
                            {item === "applications" && "Applications"}
                            {item === "settings" && "Settings"}
                            {item === "billing" && "Billing"}
                            {item === "update-password" && "Update Password"}
                            {item === "job-detail" && "Job Details"}
                            {item === "employer" && "Employer Profile"}
                            {item === "applicants" && "Applicants"}
                            {item === "applicant" && "Applicant"}
                            {item === "jobseeker" && "JobSeeker Profile"}
                            {item === "support-ticket" && "Support Ticket"}
                            {item === "edit-profile" && "Edit Profile"}
                            {item === "edit-job" && "Edit Job"}
                        </Typography>
                        }
                        {(userType === "Employer" || userType === "PortalAdmin") ? (
                            <LoadingButton
                                type="submit"
                                className="button-primary"
                                disabled={userType === "Employer" && !userInfo.isVerified}
                                onClick={() => {
                                    dispatch(resetJobDetails())
                                    dispatch(setEditing(false))
                                    dispatch(setItem("createJob"))
                                }}
                                variant="contained"
                                sx={{ my: 3 }}
                            >
                                Create a Job
                            </LoadingButton>
                        ) : (
                            <LoadingButton
                                type="submit"
                                onClick={() => navigate("/")}
                                variant="contained"
                                sx={{ my: 3 }}
                                className="button-primary"
                            >
                                Apply for Job
                            </LoadingButton>
                        )}

                    </React.Fragment>

                </Toolbar>

            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Typography textAlign="center">
                    <img
                        src="/Proten-logo.png"
                        alt="Proten Intl"
                        height={150} width={150}
                        style={{ marginTop: "-50px", marginBottom: "-50px", cursor: "pointer" }}
                        onClick={() => navigate("/")}

                    />
                </Typography>
                <Divider />
                {userType === "JobSeeker" ? (
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                dispatch(setItem("dashboard"))
                                navigate("/dashboard")
                            }}>
                                <ListItemIcon><BarChartIcon /></ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                dispatch(setItem("profile"))
                                navigate("/profile")
                            }}>
                                <ListItemIcon><PersonIcon /></ListItemIcon>
                                <ListItemText primary="Profile" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                dispatch(setItem("applications"))
                                navigate("/applications")
                            }}>
                                <ListItemIcon><AddIcon /></ListItemIcon>
                                <ListItemText primary="Applications" />
                            </ListItemButton>
                        </ListItem>


                    </List>
                ) : (
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                dispatch(setItem("dashboard"))
                                navigate("/dashboard")
                            }}>
                                <ListItemIcon><BarChartIcon /></ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                dispatch(setItem("profile"))
                                navigate("/profile")
                            }}>
                                <ListItemIcon><PersonIcon /></ListItemIcon>
                                <ListItemText primary="Profile" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                dispatch(setItem("jobs"))
                                navigate("/jobs")
                            }}>
                                <ListItemIcon><WorkOutlineOutlinedIcon /></ListItemIcon>
                                <ListItemText primary="Jobs" />
                            </ListItemButton>
                        </ListItem>
                        {userType === "PortalAdmin" && (
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => {
                                    dispatch(setItem("users"))
                                    navigate("/users")
                                }}>
                                    <ListItemIcon><GroupsOutlinedIcon /></ListItemIcon>
                                    <ListItemText primary="Users" />
                                </ListItemButton>
                            </ListItem>
                        )}
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                dispatch(setItem("billing"))
                                navigate("/billing")

                            }}>
                                <ListItemIcon><PaymentsOutlinedIcon /></ListItemIcon>
                                <ListItemText primary="Billing" />
                            </ListItemButton>
                        </ListItem>


                    </List>
                )}

                <Divider />
                {userType === "JobSeeker" ? (

                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                dispatch(setItem("settings"))
                                navigate("/settings")
                            }}>
                                <ListItemIcon><SettingsIcon /></ListItemIcon>
                                <ListItemText primary="Settings" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding sx={{ marginTop: "70%" }}>
                            <ListItemButton onClick={logoutHandler}>
                                <ListItemIcon><LogoutIcon /></ListItemIcon>
                                <ListItemText primary="Logout" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                ) : (

                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                dispatch(setItem("settings"))
                                navigate("/settings")
                            }}>
                                <ListItemIcon><SettingsIcon /></ListItemIcon>
                                <ListItemText primary="Settings" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                dispatch(setItem("support-ticket"))
                                navigate("/support-ticket")
                            }}>
                                <ListItemIcon><ContactSupportOutlinedIcon /></ListItemIcon>
                                <ListItemText primary="Support Ticket" />
                            </ListItemButton>
                        </ListItem>


                        <ListItem disablePadding sx={{ marginTop: "25%" }}>
                            <ListItemButton onClick={logoutHandler}>
                                <ListItemIcon><LogoutIcon /></ListItemIcon>
                                <ListItemText primary="Logout" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                )}

            </Drawer>
            <Main open={open}>
                <DrawerHeader />

                {item === "profile" && userType === "JobSeeker" ? <JobSeekerDetails /> :
                    item === "profile" && userType === "Employer" ? <EmployerProfile /> :
                        item === "profile" && userType === "PortalAdmin" && <AdminProfile />}
                {item === "applications" && <Applications />}
                {item === "applicants" && <Applicants />}
                {item === "education" && <EducationItem />}
                {item === "experience" && <ExperienceItem />}
                {item === "applicant" && <Applicant />}
                {item === "support-ticket" && <TicketList />}
                {item === "update-password" && <UpdatePassword />}
                {item === "settings" && <Settings />}
                {item === "jobseeker" && <JobSeeker />}
                {item === "createJob" && <JobStepper />}
                {item === "users" && <UserList />}
                {item === "billing" && <EmployerBilling />}
                {item === "jobs" && userType === "PortalAdmin" ? <AdminJobList /> :
                    item === "jobs" && <JobList />}

                {item === "job-detail" && <JobDetails />}
                {item === "employer" && <EmployerDetails />}
                {item === "apply" && <JobSeekerDetails />}
                {/* {item === "setup-profile" && <JobSeekerProfileSetup />} */}
                {item === "edit-profile" && <JobSeekerProfileSetup />}
                {item === "edit-job" && <JobStepper />}
                {item === "dashboard" && userType === "JobSeeker" ? <Dashboard /> :
                    item === "dashboard" && userType === "Employer" ? <EmployerDashboard /> :
                        item === "dashboard" && userType === "PortalAdmin" && <AdminDashboard />}
            </Main>
        </Box>
    );
}