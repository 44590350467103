

import TabComponent from '../../../UI/TabComponent';

import { Button, Grid } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CreateUserForm from './CreateUserForm';



export default function UserList() {
    const { userInfo } = useSelector(state => state.userLogin)
    const userType = userInfo.type

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const labels = [
        {
            value: "one",
            text: "JobSeeker"
        },
        {
            value: "two",
            text: "Employer"
        },
        {
            value: "three",
            text: "Admin"
        },


    ]


    return (
        <Grid container width="100%" mt={5}>

            <CreateUserForm open={open} handleClose={handleClose} />

            <Grid xs={10}>

                <TabComponent labels={labels} title="Users" userType={userType} />
            </Grid>





            <Grid xs={2}>

                <Button
                    onClick={handleOpen}
                    size="small"
                    className="button-primary"
                    sx={{ fontSize: "12px" }}


                >Create Admin</Button>


            </Grid>

        </Grid>
    );
}


