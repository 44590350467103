import { Box, Chip, Grid, Typography } from "@mui/material"
import Card from "../../Card"
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import AccessTimeIcon from '@mui/icons-material/AccessTime';

import LineChartDataset from "./LineChartDataSet";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { computeApplicantsOfferRatio, computeApplicantsStats, computeAverageTimeToFill, isProfileComplete } from "../../../utils/Validate";
import { listMyJobs } from "../../../actions/jobActions";
import { getUserDetails, setItem } from "../../../actions/userActions";

const Dashboard = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { jobs } = useSelector((state) => state.myJobList);
    const { user } = useSelector((state) => state.userDetails);


    useEffect(() => {

        if (!user || !user.firstname) {
            dispatch(getUserDetails('profile'));

        }
        dispatch(listMyJobs())

    }, [dispatch, user])
    return (
        <Box mt={3}>
            {user && !isProfileComplete(user) && <Typography textAlign="center"><Chip
                label="Please Complete your profile to start creating jobs"
                color="error"
                onClick={() => {
                    dispatch(setItem("profile"))
                    navigate("/profile")
                }}
            /></Typography>}
            <Grid container>

                <Grid xs={9} sm={6} md={4}
                    onClick={() => {
                        dispatch(setItem("jobs"))
                        navigate("/jobs")
                    }}
                    sx={{ cursor: "pointer" }}
                >
                    <Card
                        color="#167dc1"
                        metricName="Jobs Posted"
                        metricNumber={!jobs ? 0 : jobs?.length}
                        Icon={WorkOutlineIcon} />
                </Grid>
                <Grid xs={9} sm={6} md={4}>
                    <Card color="#ebbd1a"
                        metricName="Avg Time To Fill"
                        metricNumber={computeAverageTimeToFill(jobs)}
                        suffix=" d"
                        Icon={AccessTimeIcon} />
                </Grid>
                <Grid xs={9} sm={6} md={4}>
                    <Card
                        color="#18bebe"
                        metricName="Conversion Rate"
                        metricNumber={computeApplicantsOfferRatio(jobs)}
                        suffix="%"
                        Icon={FilterAltOutlinedIcon}
                    />
                </Grid>

            </Grid>
            <Typography variant="h5" sx={{ mt: 5, mb: 2 }}>
                Applicant Statistics
            </Typography>
            <Grid container>

                <Grid xs={12}>
                    <LineChartDataset dataset={computeApplicantsStats(user)} />
                </Grid>




            </Grid>







        </Box>
    )
}

export default Dashboard