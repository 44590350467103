import { Avatar, CircularProgress, Grid, Typography } from "@mui/material"
import LocationIcon from '@mui/icons-material/PinDropOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Moment from "react-moment";
import { setItem } from "../../actions/userActions";
import { listJobs } from "../../actions/jobActions";


const RejectedJobs = () => {

    const { userInfo } = useSelector((state) => state.userLogin);
    const { jobs, loading } = useSelector((state) => state.jobList);
    const dispatch = useDispatch()


    const navigate = useNavigate()

    useEffect(() => {
        if (!userInfo) {
            navigate("/login")
        } else {

            dispatch(listJobs());

        }


    }, [dispatch, navigate, userInfo])

    return (

        loading ? <Typography textAlign="center"> <CircularProgress /></Typography> : jobs.filter(job => job.isRejected)?.map(job => (
            <Grid container sx={{
                py: 2,
                px: 5,
                mt: 3,
                borderRadius: "10px",
                border: "1px solid #eaeaea",
                cursor: "pointer"
            }}
                onClick={() => {
                    dispatch(setItem("job-detail"))
                    navigate(`/job/${job._id}`)
                }}
            >
                <Grid xs={1}>
                    <Avatar src={job.avatar} alt="Company Logo" />
                </Grid>
                <Grid xs={10}>
                    <Typography sx={{ fontSize: "12px" }}>{job.company}</Typography>
                    <Typography sx={{ my: 1, fontSize: "15px", fontWeight: 600 }} >{job.title}</Typography>
                    <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", my: 1, color: "#8b8b8b" }}>
                        <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px" }}><LocationIcon sx={{ fontSize: "14px", marginRight: "2px" }} /> {job.location} </Typography>
                        <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px" }}><AccessTimeIcon sx={{ fontSize: "14px", marginRight: "2px" }} /> {job.type}  </Typography>
                        <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px" }}><span style={{ fontSize: "12px", marginRight: "2px" }}>&#8358;</span> {" "}{`${job.minSalary}-${job.maxSalary}`}</Typography>
                        <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px" }}><DateRangeOutlinedIcon sx={{ fontSize: "14px", marginRight: "2px" }} /> {" "} <Moment fromNow>{job.createdAt}</Moment> </Typography>
                    </Grid>
                </Grid>
                <Grid xs={1}></Grid>
            </Grid>
        ))

    )
}



export default RejectedJobs