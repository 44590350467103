import { LoadingButton } from "@mui/lab"
import { Box, Chip, CircularProgress, Grid, IconButton, Stack, Typography, Button, Tooltip, useTheme, useMediaQuery } from "@mui/material"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import FavoriteIcon from '@mui/icons-material/FavoriteBorderOutlined';

import LocationIcon from '@mui/icons-material/PinDropOutlined';

import MenuOptions from "../../UI/MenuOptions";
import { Fragment, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getUserDetails, setItem } from "../../actions/userActions";
import Moment from "react-moment";
import { getJobDetails, resetApplicationWithdraw, withdrawApplication, } from "../../actions/jobActions";
import { ToastContainer, toast } from "react-toastify";
import { copyToClipboard, isStringInArrayOfObjects, limitString } from "../../utils/Validate";
import ShareOptions from "../../UI/ShareOptions";




const JobDetails = () => {
    const { userInfo } = useSelector((state) => state.userLogin);
    const { user } = useSelector((state) => state.userDetails);
    const userType = userInfo?.type
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { id } = useParams();

    const { job, loading } = useSelector((state) => state.jobDetails);
    // const { item } = useSelector((state) => state.itemState)
    const { success, error } = useSelector((state) => state.applicationWithdraw);
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleWithdrawApplication = (id) => {
        if (error) {
            toast(error, {
                className: 'toast-error',
                bodyClassName: 'toast-error',
            })
            dispatch(resetApplicationWithdraw())
        } else {
            dispatch(withdrawApplication(id))

        }
    }

    // Log component rendering
    console.log("JobDetails component rendered");
    console.log("Job ID from params:", id);

    useEffect(() => {

        console.log("useEffect triggered");
        dispatch(setItem("job-detail"));
        console.log("Dispatched setItem('job-detail')");
        if (!user || !user.firstname) {
            dispatch(getUserDetails('profile'))
        }


        if (!job?._id || job?._id !== id) {

            console.log(`Dispatching getJobDetails with ID: ${id}`);

            dispatch(getJobDetails(id))

        }

        if (success) {
            toast("Application Withdrawn Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            })
            dispatch(resetApplicationWithdraw())
            dispatch(getUserDetails('profile'))

        }




    }, [dispatch, job, id, user, userInfo, navigate, success])



    return (
        <Box sx={{
            borderRadius: "10px",
            border: "2px solid #f8f8f8",
            mb: 3,
            py: 3,
        }}>


            {loading ? <Typography textAlign="center"><CircularProgress /></Typography> : !job ? <Typography textAlign="center" variant="h2">Job No Longer Exists</Typography> : (

                <Grid container >
                    <ToastContainer />

                    <Grid md={1} xs={12}>
                        <img src={job?.avatar} alt="Company Logo" width={70} height={70} style={{ borderRadius: "100%" }} />
                    </Grid>

                    <Grid md={9} xs={12} sx={{ paddingX: !userType || (userType === "JobSeeker") ? "20px" : "20px" }}>


                        <Fragment>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <Typography mt={2}>{job.company}</Typography>
                                    <Typography className="job-title" sx={{ my: 1, cursor: "pointer" }} variant="h6">{job.title}</Typography>

                                </div>
                                {(isStringInArrayOfObjects(job.referenceNumber, user?.appliedJobs) && (userType === "JobSeeker")) && (
                                    <div>
                                        <LoadingButton
                                            type="submit"
                                            size="small"
                                            variant="outlined"
                                            sx={{ my: 3, fontSize: "12px" }}
                                            className="button-primary"
                                            onClick={() => handleWithdrawApplication(job._id)}

                                        >    Withdraw Application
                                        </LoadingButton>
                                    </div>
                                )}

                            </div>

                            <Grid sx={
                                {
                                    display: "flex",
                                    alignItems: isSmallScreen ? "flex-start" : "center",
                                    justifyContent: isSmallScreen ? "center" : "space-between",
                                    flexDirection: isSmallScreen ? "column" : "row",
                                    my: 1,
                                    color: "#8b8b8b"
                                }}>
                                <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px", marginBottom: isSmallScreen && "15px" }}><LocationIcon sx={{ fontSize: "14px", marginRight: "2px" }} /> {job.location}</Typography>
                                <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px", marginBottom: isSmallScreen && "15px" }}><AccessTimeIcon sx={{ fontSize: "14px", marginRight: "2px" }} />  {job.type} </Typography>
                                <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px", marginBottom: isSmallScreen && "15px" }}><span style={{ fontSize: "12px", marginRight: "2px" }}>&#8358;</span> {" "} {job.minSalary ? `${job.minSalary}-${job.maxSalary}` : `Negotiable`}</Typography>
                                <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px", marginBottom: isSmallScreen && "15px" }}><DateRangeOutlinedIcon sx={{ fontSize: "14px", marginRight: "2px" }} /> {" "}<Moment fromNow>{job.isPublished ? job.publishedAt : job.createdAt}</Moment></Typography>
                            </Grid>


                            <Tooltip title='Copy Link'> <p onClick={() => copyToClipboard(`https://careers.protenintl.com/job/${job._id}`)} style={{ fontSize: "13px", cursor: "pointer", color: "#167dc1" }}>[{limitString(`https://careers.protenintl.com/job/${job._id}`, 40)}]</p> </Tooltip>
                            <Typography my={1}>
                                Location: {job?.address}
                            </Typography>
                            {isStringInArrayOfObjects(job.referenceNumber, user?.appliedJobs) ? (
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    disabled
                                    sx={{ my: 3, backgroundColor: "#18bebe", color: "#fff" }}

                                >
                                    Application Submitted
                                </LoadingButton>
                            ) : (!userType || userType === "JobSeeker") && (
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    className="button-primary"

                                    onClick={() => {
                                        dispatch(setItem('apply'))
                                        navigate(`/job/${id}/apply`)

                                    }}
                                    sx={{ my: 3 }}

                                >
                                    Apply Now
                                </LoadingButton>
                            )}



                            <Typography variant="h6" my={2}>Company Overview:</Typography>
                            <Typography sx={{ fontSize: "14px", lineHeight: "28px" }}>
                                {job?.overview}
                            </Typography>
                            <Typography variant="h6" my={2}>Job Description</Typography>


                            {/* <Typography sx={{ fontSize: "14px", lineHeight: "28px" }}>{job.description}</Typography> */}
                            <Typography component="div" sx={{ fontSize: "14px", lineHeight: "28px" }}>
                                <div dangerouslySetInnerHTML={{ __html: job.description }} />
                            </Typography>

                            <Typography variant="h6" my={2}>Job Requirement</Typography>


                            {/* <Typography sx={{ fontSize: "14px", lineHeight: "28px" }}>{job.requirement}</Typography> */}

                            <Typography component="div" sx={{ fontSize: "14px", lineHeight: "28px" }}>
                                <div dangerouslySetInnerHTML={{ __html: job.requirement }} />
                            </Typography>

                            <Typography variant="h6" my={2}>Qualifications</Typography>


                            <Typography sx={{ fontSize: "14px", lineHeight: "28px" }}>{job.qualification}</Typography>

                            <Typography variant="h6" my={2}>Skills</Typography>
                            <Stack direction="row" spacing={1}>

                                {job.skills?.map(j => (
                                    <Chip label={j} key={j} />
                                ))}


                            </Stack>

                            {isStringInArrayOfObjects(job.referenceNumber, user?.appliedJobs) ? (
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    disabled
                                    sx={{ my: 3, backgroundColor: "#18bebe", color: "#fff" }}

                                >
                                    Application Submitted
                                </LoadingButton>
                            ) : (!userType || userType === "JobSeeker") && (
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    className="button-primary"
                                    onClick={() => {
                                        dispatch(setItem('apply'))
                                        navigate(`/job/${id}/apply`)

                                    }}
                                    sx={{ my: 3 }}

                                >
                                    Apply Now
                                </LoadingButton>
                            )}

                        </Fragment>

                    </Grid>
                    <Grid xs={12} md={2}>
                        {!userType || userType === "JobSeeker" ? (
                            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <IconButton><FavoriteIcon /></IconButton>
                                <ShareOptions id={job?._id} />
                            </Box>
                        ) : (
                            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                                <MenuOptions />
                            </Box>

                        )}
                    </Grid>

                    <Typography>


                        <Button variant="text" onClick={userInfo.type === "JobSeeker" ? () => {

                            dispatch(setItem("applications"))
                            navigate(`/applications`)
                        } :
                            () => {

                                dispatch(setItem("jobs"))
                                navigate(`/jobs`)
                            }
                        }
                            startIcon={<KeyboardBackspaceIcon />} sx={{ mt: 5, color: "#303030", fontWeight: 700 }}>Back</Button>
                    </Typography>

                </Grid>

            )}


        </Box>
    )
}



export default JobDetails