
import { TICKET_CLOSE_FAIL, TICKET_CLOSE_REQUEST, TICKET_CLOSE_RESET, TICKET_CLOSE_SUCCESS, TICKET_CREATE_FAIL, TICKET_CREATE_REQUEST, TICKET_CREATE_RESET, TICKET_CREATE_SUCCESS, TICKET_DELETE_FAIL, TICKET_DELETE_REQUEST, TICKET_DELETE_RESET, TICKET_DELETE_SUCCESS, TICKET_DETAILS_FAIL, TICKET_DETAILS_REQUEST, TICKET_DETAILS_RESET, TICKET_DETAILS_SUCCESS, TICKET_LIST_FAIL, TICKET_LIST_REQUEST, TICKET_LIST_SUCCESS, TICKET_MY_LIST_FAIL, TICKET_MY_LIST_REQUEST, TICKET_MY_LIST_SUCCESS } from "../types/ticketTypes";



export const myTicketListReducer = (state = { tickets: [] }, action) => {
    switch (action.type) {
        case TICKET_MY_LIST_REQUEST:
            return { loading: true, tickets: [] };
        case TICKET_MY_LIST_SUCCESS:
            return {
                loading: false,
                tickets: action.payload,

            };
        case TICKET_MY_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const ticketListReducer = (state = { tickets: [] }, action) => {
    switch (action.type) {
        case TICKET_LIST_REQUEST:
            return { loading: true, tickets: [] };
        case TICKET_LIST_SUCCESS:
            return {
                loading: false,
                tickets: action.payload,

            };
        case TICKET_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};


export const ticketDetailsReducer = (state = { ticket: {} }, action) => {
    switch (action.type) {
        case TICKET_DETAILS_REQUEST:
            return {
                loading: true,
                ...state,
            };
        case TICKET_DETAILS_SUCCESS:
            return {
                loading: false,
                ticket: action.payload,
            };
        case TICKET_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        case TICKET_DETAILS_RESET:
            return {
                ticket: {},
            };

        default:
            return state;
    }
};

export const ticketDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case TICKET_DELETE_REQUEST:
            return { loading: true };
        case TICKET_DELETE_SUCCESS:
            return { loading: false, success: true };
        case TICKET_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case TICKET_DELETE_RESET:
            return {};

        default:
            return state;
    }
};

export const ticketCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case TICKET_CREATE_REQUEST:
            return { loading: true };
        case TICKET_CREATE_SUCCESS:
            return { loading: false, success: true, ticket: action.payload };
        case TICKET_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case TICKET_CREATE_RESET:
            return {};
        default:
            return state;
    }
};

export const ticketCloseReducer = (state = {}, action) => {
    switch (action.type) {
        case TICKET_CLOSE_REQUEST:
            return { loading: true };
        case TICKET_CLOSE_SUCCESS:
            return { loading: false, success: true };
        case TICKET_CLOSE_FAIL:
            return { loading: false, error: action.payload };
        case TICKET_CLOSE_RESET:
            return {};
        default:
            return state;
    }
};