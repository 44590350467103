import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, useMediaQuery } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import PropTypes from 'prop-types';

import ApplicantHighlight from '../components/job/ApplicantHighlight';
import JobHighlight from '../components/job/JobHighlight';
import ShortlistedApplicants from '../components/job/ShortlistedApplicants';
import InitialCallApplicants from '../components/job/InitialCallApplicants';
import FinalInterviewApplicants from '../components/job/FinalInterviewApplicants';
import OfferApplicants from '../components/job/OfferApplicants';
// import { useSelector } from 'react-redux';
import DraftedJobs from '../components/job/DraftedJobs';
import PublishedJobs from '../components/job/PublishedJobs';
import RejectedJobs from '../components/job/RejectedJobs';
import ClosedJobs from '../components/job/ClosedJobs';
import EmployerList from '../components/user/Admin/EmployerList';
import JobSeekerList from '../components/user/Admin/JobSeekerList';
import WishlistedJobs from '../components/job/WishlistedJobs';
import AdminList from '../components/user/Admin/AdminList';
import RejectedApplications from '../components/job/RejectedApplications';
import AcceptedApplications from '../components/job/AcceptedApplications';
import { useTheme } from '@mui/material/styles';




const TabComponent = ({ labels, title }) => {
    const [value, setValue] = React.useState('one');
    // const { userInfo } = useSelector((state) => state.userLogin);
    // const userType = userInfo.type

    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ width: "100%" }}
        >
            <TabContext value={value}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label={title}
                    orientation={isSmallScreen ? 'vertical' : 'horizontal'}


                >
                    {labels.map((item) => <Tab value={item.value} label={item.text}
                        sx={{
                            width: `${100 / labels.length}%`, mt: 5,
                            fontSize: isSmallScreen ? '0.75rem' : '1rem'
                        }} />)}

                </Tabs>
                <TabPanel value="one">
                    {title === "Applications" ? <JobHighlight /> : title === "Drafts" ? <DraftedJobs /> :
                        title === "Users" ? <JobSeekerList />
                            : <ApplicantHighlight />}

                </TabPanel>
                <TabPanel value="two">
                    {title === "Applications" ? <WishlistedJobs /> : title === "Drafts" ? <PublishedJobs /> :
                        title === "Users" ? <EmployerList /> : <ShortlistedApplicants />}

                </TabPanel>
                <TabPanel value="three">
                    {title === "Applications" ? <RejectedApplications /> : title === "Drafts" ? <RejectedJobs /> :
                        title === "Users" ? <AdminList /> : <InitialCallApplicants />}
                </TabPanel>
                <TabPanel value="four">
                    {title === "Applications" ? <AcceptedApplications /> : title === "Drafts" ? <ClosedJobs /> :
                        <FinalInterviewApplicants />}
                </TabPanel>
                <TabPanel value="five">
                    {title === "Applications" ? <JobHighlight /> :
                        <OfferApplicants />}
                </TabPanel>
            </TabContext>
        </Box>
    )
}

TabComponent.propTypes = {

    labels: PropTypes.array.isRequired,
    userType: PropTypes.string,
    title: PropTypes.string.isRequired
}

export default TabComponent