
import { LoadingButton } from "@mui/lab"
import { Box, Grid, Typography } from "@mui/material"
import axios from "axios";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";


function EmailVerified() {
    const { activationToken } = useParams();
    const navigate = useNavigate()

    useEffect(() => {
        // check token
        if (activationToken) {
            const activateUser = async () => {
                try {
                    const res = await axios.post("/api/users/activation", {
                        activationToken,
                    });
                    if (res.data.success) {
                        return toast(res.data.msg, {
                            className: "toast-success",
                            bodyClassName: "toast-success",
                        });
                    }

                } catch (err) {

                    toast(err.response.data.msg, {
                        className: "toast-failed",
                        bodyClassName: "toast-failed",
                    });
                }
            };
            activateUser();
        } else {
            navigate("/register")
        }
    }, [activationToken, navigate]);

    return (
        <Grid container>
            <ToastContainer />
            <Grid xs={12} md={4}></Grid>
            <Grid xs={12} md={4}>
                <Box sx={{
                    padding: " 40px 20px", borderRadius: "10px",
                    background: "white", mt: 5, textAlign: "center"
                }}>
                    <Typography component="h6" variant="h6">Email Verified</Typography>
                    <img src="/Email-Verified.png" alt="Email Verified" width={200} height={200} />

                    <Typography sx={{ mb: 2, color: "#8b8b8b", fontSize: "16px", textAlign: "center" }}>
                        Your email has been successfully verified. Click the button below to Login to your profile.
                    </Typography>
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ my: 3 }}
                        href="/login"
                        className="button-primary"
                    >
                        Login
                    </LoadingButton>
                </Box>


            </Grid>
            <Grid xs={12} md={4}></Grid>

        </Grid>
    )
}

export default EmailVerified