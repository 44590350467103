import { Box, Grid, Typography } from "@mui/material"
import { Link } from "react-router-dom"

const AccountCreated = () => {
    return (
        <Grid container>
            <Grid md={4} xs={12} sm={2}></Grid>
            <Grid md={4} sm={8} xs={12}>
                <Box sx={{
                    padding: " 40px 20px", borderRadius: "10px",
                    background: "white", mt: 5, textAlign: "center"
                }}>
                    <Typography component="h6" variant="h6" sx={{ mb: 4 }}>Account Created Successfully</Typography>
                    <img src="/Account-created.png" alt="Account Created" width={100} height={100} />
                    <Typography sx={{ my: 2, fontWeight: "700" }}>Verify Email</Typography>
                    <Typography sx={{ my: 2, color: "#8b8b8b", fontSize: "16px" }}>A verification Link has been sent to your email Click on
                        the Link to Proceed
                    </Typography>

                </Box>
                <Grid sx={{ mt: 4, textAlign: "center" }}>
                    <Typography >Didnt receive the link ? <Link to="">Resend Link</Link></Typography>

                </Grid>

            </Grid>
            <Grid md={4} sm={2} xs={12}></Grid>

        </Grid>
    )
}

export default AccountCreated