import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ShareIcon from '@mui/icons-material/ShareOutlined';
import { LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton, } from "react-share"
import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyToClipboard } from '../utils/Validate';

export default function ShareOptions(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <ShareIcon />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose}>
                    <LinkedinShareButton url={`https://careers.protenintl.com/job/${props.id}`}>
                        <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <TwitterShareButton url={`https://careers.protenintl.com/job/${props.id}`}>
                        <TwitterIcon size={32} round />
                    </TwitterShareButton>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <WhatsappShareButton url={`https://careers.protenintl.com/job/${props.id}`}>
                        <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Tooltip title='Copy URL'>
                        <IconButton onClick={() => copyToClipboard(`https://careers.protenintl.com/job/${props.id}`)}>
                            <ContentCopyIcon size={32} round />
                        </IconButton>
                    </Tooltip>
                </MenuItem>
            </Menu>
        </div>
    );
}
