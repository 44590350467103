
import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts';



const chartSetting = {
    yAxis: [
        {
            label: 'Applicants',
        },
    ],
    width: window.innerWidth * 0.5,
    height: window.innerHeight * 0.6,
    sx: {
        [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-20px, 1)',

        },

    },
};
export const dataset = [
    {
        shortlisted: 700,
        interviewed: 236,
        offer: 45,
        month: 'Jan',
    },
    {
        shortlisted: 400,
        interviewed: 136,
        offer: 23,
        month: 'Feb',
    },
    {
        shortlisted: 300,
        interviewed: 96,
        offer: 34,
        month: 'Mar',
    },
    {
        shortlisted: 650,
        interviewed: 336,
        offer: 22,
        month: 'Apr',
    },
    {
        shortlisted: 450,
        interviewed: 76,
        offer: 10,
        month: 'May',
    },
    {
        shortlisted: 600,
        interviewed: 226,
        offer: 34,
        month: 'June',
    },
    {
        shortlisted: 700,
        interviewed: 346,
        offer: 25,
        month: 'July',
    },
    {
        shortlisted: 450,
        interviewed: 156,
        offer: 5,
        month: 'Aug',
    },
    {
        shortlisted: 477,
        interviewed: 324,
        offer: 23,
        month: 'Sept',
    },
    {
        shortlisted: 650,
        interviewed: 344,
        offer: 27,
        month: 'Oct',
    },
    {
        shortlisted: 819,
        interviewed: 567,
        offer: 33,
        month: 'Nov',
    },
    {
        shortlisted: 560,
        interviewed: 300,
        offer: 19,
        month: 'Dec',
    },
];

const valueFormatter = (value) => `${value}`;

export default function LineChartDataset(props) {
    return (

        <LineChart

            dataset={props.dataset}
            xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
            series={[
                { dataKey: 'shortlisted', label: 'Shortlisted', valueFormatter, color: "#167dc1" },
                { dataKey: 'interviewed', label: 'Interviewed', valueFormatter, color: "#18bebe" },
                { dataKey: 'offered', label: 'Offered', valueFormatter, color: "#ebbd1a" },

            ]}
            {...chartSetting}
        />

    );
}