import * as React from 'react';
import { styled } from '@mui/material/styles';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LoadingButton } from '@mui/lab';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function UploadButton(props) {
    return (
        <LoadingButton
            fullWidth={props.fullWidth}
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            onChange={props.onChange}
            loading={props.loading}
            sx={props.style}
            size={props.size}
        >
            {props.text}
            <VisuallyHiddenInput type="file" />
        </LoadingButton>
    );
}
