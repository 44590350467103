import { useState } from "react";


import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Box, Button, FormGroup, FormLabel, Grid, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { isEmail, isEmpty } from "../../utils/Validate";
import { ArrowLeftIcon } from "@mui/x-date-pickers/icons";
import { useNavigate } from "react-router-dom";



const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        //console.log("submitted");
        // check fields
        if (isEmpty(email)) {
            setLoading(false)
            return toast("Please fill in all fields.", {
                className: "toast-failed",
                bodyClassName: "toast-failed",
            });
        }

        // check email
        if (!isEmail(email)) {
            setLoading(false)
            return toast("Please enter a valid email address.", {
                className: "toast-failed",
                bodyClassName: "toast-failed",
            });

        }

        try {
            await axios.post("/api/users/forgot-password", { email });
            setEmail("");
            setLoading(false)
            return toast("Please check your email 📧", {
                className: "toast-success",
                bodyClassName: "toast-success",
            });

        } catch (err) {
            toast(err.response.data.msg, {
                className: "toast-failed",
                bodyClassName: "toast-failed",
            });
        }
    };
    return (
        <Grid container>
            <ToastContainer />
            <Grid md={4}></Grid>
            <Grid md={4}>
                <Box sx={{
                    padding: "40px 20px",
                    borderRadius: "10px",
                    background: "white",
                    marginTop: "80px",
                    border: "1px solid #eaeaea"
                }}>
                    <Typography variant="h6" textAlign="center">FORGOT PASSWORD ?</Typography>
                    <form onSubmit={handleSubmit}>
                        <FormGroup>
                            <FormLabel sx={{ mt: 2 }}>Email Address</FormLabel>
                            <TextField
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                placeholder="Enter your Email"
                                sx={{ my: 2 }}
                                fullWidth
                            />
                        </FormGroup>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Button variant="text" onClick={() => navigate("/login")}
                                startIcon={<ArrowLeftIcon />}
                            >Back</Button>
                            <LoadingButton className="button-primary" type="submit" variant="contained" loading={loading}>
                                Submit
                            </LoadingButton>
                        </Box>

                    </form>
                </Box>
            </Grid>
            <Grid md={4}></Grid>

        </Grid>
    );
};

export default ForgotPassword;