import { Box, Switch, Typography } from '@mui/material'
import { ArrowRightIcon } from '@mui/x-date-pickers/icons'
import React from 'react'
import { setItem } from '../../actions/userActions'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

const Settings = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [checked, setChecked] = React.useState(true);

    const { userInfo } = useSelector(state => state.userLogin)

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    return (
        <Box mt={5}>
            <Box display="flex" justifyContent="space-between" sx={{
                py: 4,
                px: 5, mt: 3,
                borderRadius: "10px",
                border: "1px solid #eaeaea",
                cursor: "pointer"
            }}>
                <Typography variant="h6">Edit Profile Information</Typography>
                <Typography onClick={() => {
                    dispatch(setItem("profile"))
                    navigate("/profile")
                }}><ArrowRightIcon sx={{ color: "#167dc1", fontSize: "28px" }} /></Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" sx={{
                py: 4,
                px: 5, mt: 3,
                borderRadius: "10px",
                border: "1px solid #eaeaea",
                cursor: "pointer"
            }}>
                <Typography variant="h6">Update Password</Typography>
                <Typography onClick={() => {
                    dispatch(setItem("update-password"))
                    navigate("/update-password")
                }}><ArrowRightIcon sx={{ color: "#167dc1", fontSize: "28px" }} /></Typography>
            </Box>

            {userInfo.type === "JobSeeker" && (
                <Box display="flex" justifyContent="space-between" sx={{
                    py: 4,
                    px: 5, mt: 3,
                    borderRadius: "10px",
                    border: "1px solid #eaeaea",

                }}>
                    <Typography variant="h6">Receive Job Alerts</Typography>
                    <Typography >
                        <Switch

                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}

                        />
                    </Typography>
                </Box>
            )}

        </Box>
    )
}

export default Settings