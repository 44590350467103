import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Divider from '@mui/material/Divider';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate, useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useDispatch, useSelector } from 'react-redux';
import { setItem } from '../actions/userActions';
import { closeJob, getJobDetails, publishJob, rejectJob, resetJobClose, resetJobPublish, resetJobReject } from '../actions/jobActions';
import { ToastContainer, toast } from 'react-toastify';



const StyledMenu = styled((props) => (

    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function MenuOptions() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { userInfo } = useSelector((state) => state.userLogin);
    const userType = userInfo.type
    const open = Boolean(anchorEl);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { id } = useParams();

    const { job } = useSelector((state) => state.jobDetails);
    const { success, error } = useSelector((state) => state.jobPublish);
    const { success: successClose, error: errorClose } = useSelector((state) => state.jobClose);
    const { success: successReject, error: errorReject } = useSelector((state) => state.jobReject);

    React.useEffect(() => {

        if (success) {
            toast("Job Published Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
            dispatch(resetJobPublish())
        }

        if (successClose) {
            toast("Job Closed Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
            dispatch(resetJobClose())
        }

        if (successReject) {
            toast("Job Rejected Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
            dispatch(resetJobReject())
        }

        if (error) {
            toast(error, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            })
            dispatch(resetJobPublish())
        }

        if (errorReject) {
            errorReject && toast(errorReject, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            })
            dispatch(resetJobReject())
        }
        if (errorClose) {
            errorClose && toast(errorClose, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            })
            dispatch(resetJobClose())
        }

        if (!job._id || job._id !== id) {
            dispatch(getJobDetails(id))

        }

    }, [job, id, dispatch, success, successClose, successReject, error, errorClose, errorReject])

    return (
        <div>
            {
                error && toast(error, {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                })
            }
            {
                errorReject && toast(errorReject, {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                })
            }
            {
                errorClose && toast(errorClose, {
                    className: 'toast-failed',
                    bodyClassName: 'toast-failed',
                })
            }

            <ToastContainer />

            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                Actions
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {job.isPublished && (
                    <MenuItem
                        onClick={() => {
                            dispatch(setItem("applicants"))
                            handleClose()
                            navigate(`/job/${job._id}/applicants`)
                        }}
                        disableRipple>
                        <VisibilityIcon />
                        View Applicants
                    </MenuItem>
                )}

                {(userType === "PortalAdmin" && !job.isPublished) && (
                    <MenuItem onClick={() => {
                        dispatch(publishJob(id))
                        handleClose()
                    }} disableRipple>
                        <SendIcon />
                        Publish Job
                    </MenuItem>
                )}

                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => {
                    dispatch(closeJob(id))
                    handleClose()
                }} disableRipple>
                    <CloseIcon />
                    Close Job
                </MenuItem>
                {(userType === "PortalAdmin" && !job.isRejected) && (
                    <MenuItem onClick={() => {
                        dispatch(rejectJob(id))
                        handleClose()
                    }} disableRipple>
                        <ThumbDownIcon />
                        Reject Job
                    </MenuItem>
                )}
            </StyledMenu>
        </div>
    );
}

