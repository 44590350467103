export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET"

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAIL = "USER_CREATE_FAIL";
export const USER_CREATE_RESET = "USER_CREATE_RESET";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const SEEKER_LIST_REQUEST = "SEEKER_LIST_REQUEST";
export const SEEKER_LIST_SUCCESS = "SEEKER_LIST_SUCCESS";
export const SEEKER_LIST_FAIL = "SEEKER_LIST_FAIL";
export const SEEKER_LIST_RESET = "SEEKER_LIST_RESET";

export const EMPLOYER_LIST_REQUEST = "EMPLOYER_LIST_REQUEST";
export const EMPLOYER_LIST_SUCCESS = "EMPLOYER_LIST_SUCCESS";
export const EMPLOYER_LIST_FAIL = "EMPLOYER_LIST_FAIL";
export const EMPLOYER_LIST_RESET = "EMPLOYER_LIST_RESET";

export const ADMIN_LIST_REQUEST = "ADMIN_LIST_REQUEST";
export const ADMIN_LIST_SUCCESS = "ADMIN_LIST_SUCCESS";
export const ADMIN_LIST_FAIL = "ADMIN_LIST_FAIL";
export const ADMIN_LIST_RESET = "ADMIN_LIST_RESET";

export const USER_VERIFY_REQUEST = "USER_VERIFY_REQUEST";
export const USER_VERIFY_SUCCESS = "USER_VERIFY_SUCCESS";
export const USER_VERIFY_FAIL = "USER_VERIFY_FAIL";
export const USER_VERIFY_RESET = "USER_VERIFY_RESET";

export const USER_SUSPEND_REQUEST = "USER_SUSPEND_REQUEST";
export const USER_SUSPEND_SUCCESS = "USER_SUSPEND_SUCCESS";
export const USER_SUSPEND_FAIL = "USER_SUSPEND_FAIL";
export const USER_SUSPEND_RESET = "USER_SUSPEND_RESET";

export const USER_REACTIVATE_REQUEST = "USER_REACTIVATE_REQUEST";
export const USER_REACTIVATE_SUCCESS = "USER_REACTIVATE_SUCCESS";
export const USER_REACTIVATE_FAIL = "USER_REACTIVATE_FAIL";
export const USER_REACTIVATE_RESET = "USER_REACTIVATE_RESET";

export const USER_PROFILE_UPDATE_REQUEST = "USER_PROFILE_UPDATE_REQUEST";
export const USER_PROFILE_UPDATE_SUCCESS = "USER_PROFILE_UPDATE_SUCCESS";
export const USER_PROFILE_UPDATE_FAIL = "USER_PROFILE_UPDATE_FAIL";
export const USER_PROFILE_UPDATE_RESET = "USER_PROFILE_UPDATE_RESET";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";
export const USER_DELETE_RESET = "USER_DELETE_RESET"

export const USER_SHORTLIST_REQUEST = "USER_SHORTLIST_REQUEST";
export const USER_SHORTLIST_SUCCESS = "USER_SHORTLIST_SUCCESS";
export const USER_SHORTLIST_FAIL = "USER_SHORTLIST_FAIL";
export const USER_SHORTLIST_RESET = "USER_SHORTLIST_RESET";

export const USER_SCHEDULE_INITIAL_CALL_REQUEST = "USER_SCHEDULE_INITIAL_CALL_REQUEST";
export const USER_SCHEDULE_INITIAL_CALL_SUCCESS = "USER_SCHEDULE_INITIAL_CALL_SUCCESS";
export const USER_SCHEDULE_INITIAL_CALL_FAIL = "USER_SCHEDULE_INITIAL_CALL_FAIL";
export const USER_SCHEDULE_INITIAL_CALL_RESET = "USER_SCHEDULE_INITIAL_CALL_RESET";

export const USER_SCHEDULE_FINAL_INTERVIEW_REQUEST = "USER_SCHEDULE_FINAL_INTERVIEW_REQUEST";
export const USER_SCHEDULE_FINAL_INTERVIEW_SUCCESS = "USER_SCHEDULE_FINAL_INTERVIEW_SUCCESS";
export const USER_SCHEDULE_FINAL_INTERVIEW_FAIL = "USER_SCHEDULE_FINAL_INTERVIEW_FAIL";
export const USER_SCHEDULE_FINAL_INTERVIEW_RESET = "USER_SCHEDULE_FINAL_INTERVIEW_RESET";

export const USER_GRANT_OFFER_REQUEST = "USER_GRANT_OFFER_REQUEST";
export const USER_GRANT_OFFER_SUCCESS = "USER_GRANT_OFFER_SUCCESS";
export const USER_GRANT_OFFER_FAIL = "USER_GRANT_OFFER_FAIL";
export const USER_GRANT_OFFER_RESET = "USER_GRANT_OFFER_RESET";

export const EDUCATION_DETAILS_REQUEST = "EDUCATION_DETAILS_REQUEST";
export const EDUCATION_DETAILS_SUCCESS = "EDUCATION_DETAILS_SUCCESS";
export const EDUCATION_DETAILS_FAIL = "EDUCATION_DETAILS_FAIL";
export const EDUCATION_DETAILS_RESET = "EDUCATION_DETAILS_RESET";

export const EXPERIENCE_DETAILS_REQUEST = "EXPERIENCE_DETAILS_REQUEST";
export const EXPERIENCE_DETAILS_SUCCESS = "EXPERIENCE_DETAILS_SUCCESS";
export const EXPERIENCE_DETAILS_FAIL = "EXPERIENCE_DETAILS_FAIL";
export const EXPERIENCE_DETAILS_RESET = "EXPERIENCE_DETAILS_RESET";

export const SEEKER_UPDATE_FORM = "SEEKER_UPDATE_FORM"
export const SEEKER_RESET_FORM = "SEEKER_RESET_FORM"

export const SET_ITEM = 'SET_ITEM';
export const CLEAR_ITEM = 'CLEAR_ITEM';

export const SET_STAGE = 'SET_STAGE';
export const CLEAR_STAGE = 'CLEAR_STAGE';

export const SET_EDITING = 'SET_EDITING';
