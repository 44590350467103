import { LoadingButton } from "@mui/lab"
import { Box, Grid, Typography } from "@mui/material"
import LocationIcon from '@mui/icons-material/PinDropOutlined';
import { setItem } from "../../actions/userActions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const SimilarJob = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <Box sx={{
            py: 2,
            px: 2,
            borderRadius: "10px",
            border: "1px solid #eaeaea",
            mb: 2,
            mr: 3

        }}>
            <Typography sx={{ fontSize: "16px" }}>{props.title}</Typography>
            <Typography sx={{ fontSize: "12px", color: "#8c8c8c" }}>{props.company}</Typography>
            <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", my: 2, color: "#8b8b8b" }}>
                <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px" }}><LocationIcon sx={{ fontSize: "14px", marginRight: "2px" }} /> {" "} {props.location}  </Typography>

                <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px" }}><span style={{ fontSize: "12px", marginRight: "2px" }}>&#8358;</span> {" "} {`${props.minSalary}-${props.maxSalary}`}</Typography>

            </Grid>
            <Typography sx={{ textAlign: "center" }}>
                <LoadingButton

                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{ my: 1, alignSelf: "center", }}
                    onClick={() => {
                        dispatch(setItem("job-detail"))
                        navigate(`/job/${props._id}`)
                    }}

                >
                    Apply Now
                </LoadingButton>
            </Typography>
        </Box>
    )
}

export default SimilarJob