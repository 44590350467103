import { Avatar, Box, CircularProgress, Grid, Pagination, Typography } from "@mui/material";
import LocationIcon from '@mui/icons-material/PinDropOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { setItem } from "../../actions/userActions";
import { listDrafts } from "../../actions/jobActions";

const DraftedJobs = () => {
    const { userInfo } = useSelector((state) => state.userLogin);
    const { jobs, loading, totalPages } = useSelector((state) => state.draftList);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(5); // Number of items per page

    useEffect(() => {
        if (!userInfo) {
            navigate("/login");
        } else {
            dispatch(listDrafts("", page, rowsPerPage));
        }


    }, [dispatch, navigate, userInfo, page, rowsPerPage]);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    return (
        <Box>
            <Box display="flex" flexDirection="column" gap={2} mb={2}>
                {loading ? (
                    <Typography textAlign="center">
                        <CircularProgress />
                    </Typography>
                ) : (
                    jobs?.map((job) => (
                        <Grid
                            key={job._id}
                            container
                            sx={{
                                py: 2,
                                px: 5,
                                mt: 3,
                                borderRadius: "10px",
                                border: "1px solid #eaeaea",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                dispatch(setItem("job-detail"));
                                navigate(`/job/${job._id}`);
                            }}
                        >
                            <Grid item xs={1}>
                                <Avatar src={job.avatar} alt="Company Logo" />
                            </Grid>
                            <Grid item xs={10}>
                                <Typography sx={{ fontSize: "12px" }}>{job.company}</Typography>
                                <Typography sx={{ my: 1, fontSize: "15px", fontWeight: 600 }}>
                                    {job.title}
                                </Typography>
                                <Grid
                                    sx={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        justifyContent: "space-between",
                                        my: 1,
                                        color: "#8b8b8b",
                                    }}
                                >
                                    <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px" }}>
                                        <LocationIcon sx={{ fontSize: "14px", marginRight: "2px" }} />{" "}
                                        {job.location}
                                    </Typography>
                                    <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px" }}>
                                        <AccessTimeIcon sx={{ fontSize: "14px", marginRight: "2px" }} />{" "}
                                        {job.type}
                                    </Typography>
                                    <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px" }}>
                                        <span style={{ fontSize: "12px", marginRight: "2px" }}>&#8358;</span>{" "}
                                        {`${job.minSalary}-${job.maxSalary}`}
                                    </Typography>
                                    <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px" }}>
                                        <DateRangeOutlinedIcon sx={{ fontSize: "14px", marginRight: "2px" }} />{" "}
                                        <Moment fromNow>{job.createdAt}</Moment>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    ))
                )}
            </Box>
            <Pagination
                count={totalPages} // Total number of pages
                page={page} // Current page
                onChange={handleChangePage} // Function to change the page
                color="primary"
                shape="rounded"

            />
        </Box>
    );
};

export default DraftedJobs;
