import { Avatar, Box, Button, Chip, Container, Grid, IconButton, Stack, Typography, useMediaQuery } from "@mui/material"
// import SearchBar from "../../UI/SearchBar"
// import SearchIcon from '@mui/icons-material/Search';
import LocationIcon from '@mui/icons-material/PinDropOutlined';
import SimilarJob from "../../components/job/SimilarJob";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ResponsiveAppBar from "../../UI/AppBar"
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

import { LoadingButton } from "@mui/lab";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import FavoriteIcon from '@mui/icons-material/FavoriteBorderOutlined';


import { Fragment, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import Moment from "react-moment";
import { getJobDetails, listJobs } from "../../actions/jobActions";
import { ToastContainer, toast } from "react-toastify";
import ShareOptions from "../../UI/ShareOptions";
import { getUserDetails, setItem } from "../../actions/userActions";
import { isStringInArrayOfObjects } from "../../utils/Validate";



const JobDetailScreen = () => {
    const { userInfo } = useSelector((state) => state.userLogin);
    const userType = userInfo?.type
    const { user } = useSelector((state) => state.userDetails);

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { id } = useParams();
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const { job, loading, error } = useSelector((state) => state.jobDetails);
    const { jobs, loading: listLoading } = useSelector((state) => state.jobList);
    const handleApply = () => {
        if (!userInfo) {
            setTimeout(() => {

                navigate(`/login?redirect=/job/${id}`)
                dispatch(setItem("job-detail"))
            }, 2000)
            return toast("Please Login to Apply", {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
            //
        } else {
            dispatch(setItem("job-detail"))
            navigate(`/job/${id}`)
        }

    }

    const handleWishlist = async (id) => {
        if (!userInfo) {
            return toast("Please Login to Save Jobs", {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }
    }


    useEffect(() => {

        if (userInfo) {
            if (user && !user.firstname) {

                dispatch(getUserDetails('profile'));
            }
        }


        if (!job._id || job._id !== id) {

            dispatch(getJobDetails(id))

        }

        dispatch(listJobs())




    }, [dispatch, id, job, user, userInfo])

    return (
        <Container sx={{ p: 4, borderRadius: "10px", background: "white", marginTop: "150px" }}>
            <ResponsiveAppBar />
            <ToastContainer />




            <Grid container sx={{ marginY: userType && userType !== "JobSeeker" ? 5 : 5 }}>
                <Grid xs={12} md={userType && userType !== "JobSeeker" ? 10 : 8}>
                    <Button variant="text" onClick={() => navigate("/")}
                        startIcon={<KeyboardBackspaceIcon />} sx={{ mb: 3, color: "#303030", fontWeight: 700 }}>Back</Button>
                    <Box sx={{
                        borderRadius: "10px",
                        border: "2px solid #f8f8f8",
                        mb: 3,
                        py: 3,
                        px: 2
                    }}>

                        <Grid container >

                            <Grid md={1} xs={12}>
                                <Avatar src={job.avatar} alt={job.company} sx={{ width: isSmallScreen ? 40 : 60, height: isSmallScreen ? 40 : 60 }} />
                            </Grid>

                            <Grid md={9} xs={12} sx={{ paddingX: !userType || (userType === "JobSeeker") ? "20px" : "20px" }}>
                                {loading ? <Typography>Loading...</Typography> : error ?
                                    <Typography>Something went wrong...</Typography> :
                                    (

                                        <Fragment>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div>
                                                    <Typography mt={2}>{job.company}</Typography>
                                                    <Typography className="job-title" sx={{ my: 1, cursor: "pointer" }} variant="h6">{job.title}</Typography>
                                                </div>
                                                {userType === "JobSeeker" && (
                                                    <div>
                                                        <LoadingButton
                                                            type="submit"
                                                            size="small"
                                                            variant="outlined"
                                                            sx={{ my: 3, fontSize: "12px" }}

                                                        >    Withdraw Application
                                                        </LoadingButton>
                                                    </div>
                                                )}

                                            </div>

                                            <Grid sx={{
                                                display: "flex",
                                                alignItems: isSmallScreen ? "flex-start" : "center",
                                                justifyContent: isSmallScreen ? "center" : "space-between",
                                                flexDirection: isSmallScreen ? "column" : "row",
                                                my: 1,
                                                color: "#8b8b8b"
                                            }}>
                                                <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px", marginBottom: isSmallScreen && "15px" }}><LocationIcon sx={{ fontSize: "14px", marginRight: "2px" }} /> {job.location}</Typography>
                                                <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px", marginBottom: isSmallScreen && "15px" }}><AccessTimeIcon sx={{ fontSize: "14px", marginRight: "2px" }} />  {job.type} </Typography>
                                                <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px", marginBottom: isSmallScreen && "15px" }}><span style={{ fontSize: "12px", marginRight: "2px" }}>&#8358;</span> {" "} {job.minSalary ? `${job.minSalary}-${job.maxSalary}` : `Negotiable`}</Typography>
                                                <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px", marginBottom: isSmallScreen && "15px" }}><DateRangeOutlinedIcon sx={{ fontSize: "14px", marginRight: "2px" }} /> {" "}<Moment fromNow>{job.publishedAt}</Moment></Typography>
                                            </Grid>

                                            <LoadingButton
                                                type="submit"
                                                onClick={() => handleApply()}
                                                variant="contained"
                                                sx={{ my: 3 }}
                                                className="button-primary"

                                            >
                                                Apply Now
                                            </LoadingButton>
                                            <Typography my={1}>
                                                Location: {job?.address}
                                            </Typography>
                                            <Typography variant="h6" mb={1}>Company Overview:</Typography>
                                            <Typography sx={{ fontSize: "14px", lineHeight: "28px" }}>
                                                {job?.overview}
                                            </Typography>
                                            <Typography variant="h6" my={2}>Job Description</Typography>


                                            <Typography component="div" sx={{ fontSize: "14px", lineHeight: "28px" }}>
                                                <div dangerouslySetInnerHTML={{ __html: job.description }} />
                                            </Typography>

                                            <Typography variant="h6" my={2}>Job Requirement</Typography>


                                            <Typography component="div" sx={{ fontSize: "14px", lineHeight: "28px" }}>
                                                <div dangerouslySetInnerHTML={{ __html: job.requirement }} />
                                            </Typography>

                                            <Typography variant="h6" my={2}>Qualifications</Typography>


                                            <Typography sx={{ fontSize: "14px", lineHeight: "28px" }}>{job.qualification}</Typography>

                                            <Typography variant="h5" my={2}>Skills</Typography>
                                            <Stack direction={isSmallScreen ? "column" : "row"} spacing={1}>

                                                {job.skills?.map(j => (
                                                    <Chip label={j} key={j} sx={{ width: "50%" }} />
                                                ))}


                                            </Stack>

                                            <LoadingButton
                                                type="submit"
                                                onClick={() => handleApply()}
                                                variant="contained"
                                                sx={{ my: 3 }}
                                                className="button-primary"

                                            >
                                                Apply Now
                                            </LoadingButton>
                                        </Fragment>
                                    )}
                            </Grid>
                            <Grid md={2} xs={12}>
                                {(!userType || userType === "JobSeeker") && (
                                    <Box sx={{ display: "flex", justifyContent: isSmallScreen ? "flex-start" : "space-around" }}>
                                        <IconButton
                                            onClick={() => handleWishlist(job._id)}>
                                            {userInfo && isStringInArrayOfObjects(job.referenceNumber, user?.wishlist) ? <FavoriteIcon
                                                sx={{ color: "#18bebe" }}
                                            /> : <FavoriteBorderOutlinedIcon
                                                sx={{ color: "#18bebe" }}
                                            />}
                                        </IconButton>
                                        <ShareOptions id={job._id} />
                                    </Box>
                                )}
                            </Grid>

                        </Grid>
                    </Box>
                </Grid>
                <Grid xs={1}></Grid>
                {(userType === "JobSeeker") && (
                    <Grid xs={3}>
                        <Typography variant="h6" mb={2}>Similar Jobs</Typography>
                        {listLoading ? <Typography>Loading...</Typography> :
                            jobs.filter(j => j._id !== job._id).map(j => {
                                if (j.title?.toLowerCase().includes(job.title?.toLowerCase())) {
                                    return <SimilarJob {...job} />
                                } else {
                                    return false
                                }
                            })
                        }

                    </Grid>
                )}

            </Grid>
        </Container>
    )
}

export default JobDetailScreen