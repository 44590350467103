import { LoadingButton } from "@mui/lab"
import { Box, CircularProgress, Grid, TextField, Typography } from "@mui/material"

import { getUserDetails, listSeekers, updateUserProfile, userProfileUpdateReset } from "../../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import SearchBar from "../../../UI/SearchBar";
import SearchIcon from '@mui/icons-material/Search';
import UserHighlight from "../UserHighlight";


const Profile = () => {



    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [avatar, setAvatar] = useState("")
    const [uploading, setUploading] = useState(false)
    const [filter, setFilter] = useState("")
    const { userInfo } = useSelector((state) => state.userLogin);


    const { success, loading } = useSelector((state) => state.userProfileUpdate);
    const { user } = useSelector((state) => state.userDetails);
    const { seekers, loading: loadingSeekers } = useSelector(state => state.seekerList)

    const dispatch = useDispatch()

    const uploadAvatarHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('avatar', file)
        setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            const { data } = await axios.post('/api/upload/avatar', formData, config)


            setAvatar(data)
            setUploading(false)
        } catch (error) {
            console.error(error)
            setUploading(false)
        }
    }

    const updateUserHandler = (e) => {
        e.preventDefault();

        try {
            dispatch(updateUserProfile({ id: user._id, firstname, lastname, avatar }));


        } catch (error) {
            return toast(error, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }

    };

    useEffect(() => {

        if (filter) {
            dispatch(listSeekers(filter))
        }

        if ((user.profileId !== userInfo.profileId) || success) {
            dispatch(getUserDetails('profile'));
            dispatch(userProfileUpdateReset());
        } else {
            setFirstname(user.firstname);
            setLastname(user.lastname);
            setAvatar(user.avatar)
        }

        if (success) {

            toast("Profile Updated Successfully", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            })
        }

    }, [dispatch, user, success, filter, userInfo])

    return (
        <Grid container>
            <ToastContainer />

            <Grid xs={7}>
                {filter ? loadingSeekers ? <Typography><CircularProgress /></Typography> : seekers?.map(seeker => (
                    <UserHighlight {...seeker} />
                )) : (
                    <Box>
                        <form onSubmit={updateUserHandler}>
                            <div className="personal-image" >
                                <label className="label">
                                    <input type="file" onChange={uploadAvatarHandler} />
                                    <figure className="personal-figure">
                                        <img src={avatar || "/company-logo.png"} className="personal-avatar" alt="avatar" />
                                        <figcaption className="personal-figcaption">
                                            <img src="https://raw.githubusercontent.com/ThiagoLuizNunes/angular-boilerplate/master/src/assets/imgs/camera-white.png" alt="Camera" />
                                        </figcaption>
                                    </figure>
                                </label>
                            </div>
                            {uploading && <Typography align="center"><CircularProgress /></Typography>}
                            <TextField
                                margin="normal"

                                fullWidth

                                label="First Name"
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                                autoComplete="First Name"
                                autoFocus
                            />
                            <TextField
                                margin="normal"

                                fullWidth

                                label="Last name"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                                autoComplete="Last name"
                                autoFocus
                            />
                            <TextField
                                margin="normal"

                                fullWidth
                                type="email"

                                disabled
                                value={user.email}

                            />

                            <Typography sx={{ textAlign: "center" }}>
                                <LoadingButton
                                    type="submit"

                                    loading={loading}
                                    variant="contained"
                                    className="button-primary"
                                    sx={{ my: 3 }}
                                >
                                    Save Profile
                                </LoadingButton>
                            </Typography>
                        </form>
                    </Box>
                )}
            </Grid>
            <Grid xs={1}></Grid>
            <Grid xs={4} mt={3} >
                <SearchBar
                    icon="search"
                    placeholder="Search Talents By Roles"
                    name="title" value={filter} onChange={(e) => setFilter(e.target.value)}
                >
                    <SearchIcon />
                </SearchBar>
            </Grid>
        </Grid>
    )
}

export default Profile