
import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts';
// import { useEffect } from 'react';



const chartSetting = {
    yAxis: [
        {
            label: 'Jobs Applied',
        },
    ],
    width: window.innerWidth * 0.5,
    height: window.innerHeight * 0.6,
    sx: {
        [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-20px, 1)',

        },

    },
};


export const dataset = [
    {
        pending: 20,
        accepted: 12,
        rejected: 43,
        month: 'Jan',
    },
    {
        pending: 28,
        accepted: 8,
        rejected: 88,
        month: 'Feb',
    },
    {
        pending: 65,
        accepted: 1,
        rejected: 33,
        month: 'Mar',
    },
    {
        pending: 78,
        accepted: 3,
        rejected: 90,
        month: 'Apr',
    },
    {
        pending: 23,
        accepted: 2,
        rejected: 43,
        month: 'May',
    },


    {
        pending: 21,
        accepted: 1,
        rejected: 13,
        month: 'June',
    },
    {
        pending: 32,
        accepted: 17,
        rejected: 48,
        month: 'July',
    },
    {
        pending: 25,
        accepted: 6,
        rejected: 34,
        month: 'Aug',
    },
    {
        pending: 36,
        accepted: 0,
        rejected: 73,
        month: 'Sept',
    },
    {
        pending: 26,
        accepted: 2,
        rejected: 63,
        month: 'Oct',
    },
    {
        pending: 29,
        accepted: 1,
        rejected: 67,
        month: 'Nov',
    },
    {
        pending: 70,
        accepted: 0,
        rejected: 56,
        month: 'Dec',
    },
];

const valueFormatter = (value) => `${value}`;

export default function LineChartDataset(props) {


    return (

        <LineChart

            dataset={props.dataset}
            xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
            series={[
                { dataKey: 'pending', label: 'Pending', valueFormatter, color: "#167dc1" },
                { dataKey: 'accepted', label: 'Accepted', valueFormatter, color: "#18bebe" },
                { dataKey: 'rejected', label: 'Rejected', valueFormatter, color: "#ff499e" },

            ]}
            {...chartSetting}
        />

    );
}