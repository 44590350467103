export const TICKET_CREATE_REQUEST = "TICKET_CREATE_REQUEST";
export const TICKET_CREATE_SUCCESS = "TICKET_CREATE_SUCCESS";
export const TICKET_CREATE_FAIL = "TICKET_CREATE_FAIL";
export const TICKET_CREATE_RESET = "TICKET_CREATE_RESET";

export const TICKET_LIST_REQUEST = "TICKET_LIST_REQUEST";
export const TICKET_LIST_SUCCESS = "TICKET_LIST_SUCCESS";
export const TICKET_LIST_FAIL = "TICKET_LIST_FAIL";
export const TICKET_LIST_RESET = "TICKET_LIST_RESET";

export const TICKET_MY_LIST_REQUEST = "TICKET_MY_LIST_REQUEST";
export const TICKET_MY_LIST_SUCCESS = "TICKET_MY_LIST_SUCCESS";
export const TICKET_MY_LIST_FAIL = "TICKET_MY_LIST_FAIL";
export const TICKET_MY_LIST_RESET = "TICKET_MY_LIST_RESET";


export const TICKET_DELETE_REQUEST = "TICKET_DELETE_REQUEST";
export const TICKET_DELETE_SUCCESS = "TICKET_DELETE_SUCCESS";
export const TICKET_DELETE_FAIL = "TICKET_DELETE_FAIL";
export const TICKET_DELETE_RESET = "TICKET_DELETE_RESET";

export const TICKET_CLOSE_REQUEST = "TICKET_CLOSE_REQUEST";
export const TICKET_CLOSE_SUCCESS = "TICKET_CLOSE_SUCCESS";
export const TICKET_CLOSE_FAIL = "TICKET_CLOSE_FAIL";
export const TICKET_CLOSE_RESET = "TICKET_CLOSE_RESET";

export const TICKET_DETAILS_REQUEST = "TICKET_DETAILS_REQUEST";
export const TICKET_DETAILS_SUCCESS = "TICKET_DETAILS_SUCCESS";
export const TICKET_DETAILS_FAIL = "TICKET_DETAILS_FAIL";
export const TICKET_DETAILS_RESET = "TICKET_DETAILS_RESET";