


import { useState } from 'react';
import TabComponent from '../../../UI/TabComponent';

import { Grid } from '@mui/material';


export default function Applicants() {
    const [userType] = useState("Employer")

    const labels = [
        {
            value: "one",
            text: "All Applicants"
        },
        {
            value: "two",
            text: "Shortlisted"
        },
        {
            value: "three",
            text: "Initial Call"
        },
        {
            value: "four",
            text: "Final Interview"
        },
        {
            value: "five",
            text: "Offer"
        }
    ]


    return (
        <Grid container width="100%">

            <TabComponent labels={labels} title="Applicants" userType={userType} />

        </Grid>
    );
}
