
import axios from 'axios';
import {
    ADMIN_LIST_FAIL,
    ADMIN_LIST_REQUEST,
    ADMIN_LIST_SUCCESS,
    CLEAR_ITEM,
    EDUCATION_DETAILS_FAIL,
    EDUCATION_DETAILS_REQUEST,
    EDUCATION_DETAILS_RESET,
    EDUCATION_DETAILS_SUCCESS,
    EMPLOYER_LIST_FAIL,
    EMPLOYER_LIST_REQUEST,
    EMPLOYER_LIST_SUCCESS,
    EXPERIENCE_DETAILS_FAIL,
    EXPERIENCE_DETAILS_REQUEST,
    EXPERIENCE_DETAILS_RESET,
    EXPERIENCE_DETAILS_SUCCESS,
    SEEKER_LIST_FAIL,
    SEEKER_LIST_REQUEST,
    SEEKER_LIST_SUCCESS,
    SEEKER_RESET_FORM,
    SEEKER_UPDATE_FORM,
    SET_EDITING,
    SET_ITEM,
    SET_STAGE,
    USER_CREATE_FAIL,
    USER_CREATE_REQUEST,
    USER_CREATE_RESET,
    USER_CREATE_SUCCESS,
    USER_DELETE_FAIL,
    USER_DELETE_REQUEST,
    USER_DELETE_RESET,
    USER_DELETE_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_RESET,
    USER_DETAILS_SUCCESS,
    USER_GRANT_OFFER_FAIL,
    USER_GRANT_OFFER_REQUEST,
    USER_GRANT_OFFER_RESET,
    USER_GRANT_OFFER_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_REQUEST,

    USER_LIST_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_PROFILE_UPDATE_FAIL,
    USER_PROFILE_UPDATE_REQUEST,
    USER_PROFILE_UPDATE_RESET,
    USER_PROFILE_UPDATE_SUCCESS,
    USER_REACTIVATE_FAIL,
    USER_REACTIVATE_REQUEST,
    USER_REACTIVATE_RESET,
    USER_REACTIVATE_SUCCESS,
    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_RESET,
    USER_REGISTER_SUCCESS,
    USER_SCHEDULE_FINAL_INTERVIEW_FAIL,
    USER_SCHEDULE_FINAL_INTERVIEW_REQUEST,
    USER_SCHEDULE_FINAL_INTERVIEW_RESET,
    USER_SCHEDULE_FINAL_INTERVIEW_SUCCESS,
    USER_SCHEDULE_INITIAL_CALL_FAIL,
    USER_SCHEDULE_INITIAL_CALL_REQUEST,
    USER_SCHEDULE_INITIAL_CALL_RESET,
    USER_SCHEDULE_INITIAL_CALL_SUCCESS,
    USER_SHORTLIST_FAIL,
    USER_SHORTLIST_REQUEST,
    USER_SHORTLIST_RESET,
    USER_SHORTLIST_SUCCESS,
    USER_SUSPEND_FAIL,
    USER_SUSPEND_REQUEST,
    USER_SUSPEND_RESET,
    USER_SUSPEND_SUCCESS,
    USER_UPDATE_FAIL,
    USER_UPDATE_REQUEST,
    USER_UPDATE_RESET,
    USER_UPDATE_SUCCESS,
    USER_VERIFY_FAIL,
    USER_VERIFY_REQUEST,
    USER_VERIFY_RESET,
    USER_VERIFY_SUCCESS,

} from '../types/userTypes';
import { resetJobDetails } from './jobActions';
import { PURGE } from 'redux-persist';


export const setItem = (item) => ({
    type: SET_ITEM,
    payload: item,
});

export const clearItem = () => ({
    type: CLEAR_ITEM,
});

export const setStage = (stage) => ({
    type: SET_STAGE,
    payload: stage,
});

export const setEditing = (isEditing) => {
    return {
        type: SET_EDITING,
        payload: isEditing,
    };
};


export const loginUser = (email, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.post(
            '/api/users/login',
            { email, password },
            config
        );
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data,
        });

        localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
        const msg = error.response && error.response.data.msg
        console.log(error.response)

        dispatch({
            type: USER_LOGIN_FAIL,
            payload: msg,
        });
    }
};

export const register =
    (user) =>
        async (dispatch) => {
            try {
                dispatch({
                    type: USER_REGISTER_REQUEST,
                });

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };

                const res = await axios.post(
                    '/api/users/register',
                    user,
                    config
                );

                dispatch({
                    type: USER_REGISTER_SUCCESS,
                    payload: res.data,
                });
            } catch (error) {
                const msg = error.response && error.response.data.msg
                dispatch({
                    type: USER_REGISTER_FAIL,

                    payload: msg

                });
            }
        };

export const listUsers = (filter = "", page = 1, rowsPerPage = 20) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIST_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get(`/api/users?filter=${filter}&page=${page}&rowsPerPage=${rowsPerPage}`, config);

        dispatch({
            type: USER_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: USER_LIST_FAIL,
            payload: msg,
        });
    }
};

export const listSeekers = (filter = '', page = 1, limit = 5) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SEEKER_LIST_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get(`/api/users/seekers?filter=${filter}&page=${page}&limit=${limit}`, config);

        dispatch({
            type: SEEKER_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: SEEKER_LIST_FAIL,
            payload: msg,
        });
    }
};

export const listEmployers = (filter = '', page = 1, limit = 5) => async (dispatch, getState) => {
    try {
        dispatch({
            type: EMPLOYER_LIST_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get(`/api/users/employers?filter=${filter}&page=${page}&limit=${limit}`, config);

        dispatch({
            type: EMPLOYER_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: EMPLOYER_LIST_FAIL,
            payload: msg,
        });
    }
};

export const listAdmins = (filter = "", page = 1, limit = 5) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADMIN_LIST_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get(`/api/users/admin?filter=${filter}&page=${page}&limit=${limit}`, config);

        dispatch({
            type: ADMIN_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: ADMIN_LIST_FAIL,
            payload: msg,
        });
    }
};


export const grantOffer = (id, applicantId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_GRANT_OFFER_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(
            `/api/jobs/${id}/applicants/${applicantId}/offer`, {}, config
        );

        dispatch({
            type: USER_GRANT_OFFER_SUCCESS,
            payload: data,
        });



    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: USER_GRANT_OFFER_FAIL,
            payload: msg


        });
    }
};

export const shortlistUser = (id, applicantId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_SHORTLIST_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(
            `/api/jobs/${id}/applicants/${applicantId}/shortlist`, {}, config
        );

        dispatch({
            type: USER_SHORTLIST_SUCCESS,
            payload: data,
        });



    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: USER_SHORTLIST_FAIL,
            payload: msg


        });
    }
};


export const scheduleInitialCall = (id, applicantId, callData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_SCHEDULE_INITIAL_CALL_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(
            `/api/jobs/${id}/applicants/${applicantId}/initial-interview`, callData, config
        );

        dispatch({
            type: USER_SCHEDULE_INITIAL_CALL_SUCCESS,
            payload: data,
        });



    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: USER_SCHEDULE_INITIAL_CALL_FAIL,
            payload: msg


        });
    }
};

export const scheduleFinalInterview = (id, applicantId, callData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_SCHEDULE_FINAL_INTERVIEW_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(
            `/api/jobs/${id}/applicants/${applicantId}/final-interview`, callData, config
        );

        dispatch({
            type: USER_SCHEDULE_FINAL_INTERVIEW_SUCCESS,
            payload: data,
        });



    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: USER_SCHEDULE_FINAL_INTERVIEW_FAIL,
            payload: msg


        });
    }
};

export const verifyUser = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_VERIFY_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(
            `/api/users/${id}/verify`, {}, config
        );

        dispatch({
            type: USER_VERIFY_SUCCESS,
            payload: data,
        });



    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: USER_VERIFY_FAIL,
            payload: msg


        });
    }
};

export const suspendUser = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_SUSPEND_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(
            `/api/users/${id}/suspend`, {}, config
        );

        dispatch({
            type: USER_SUSPEND_SUCCESS,
            payload: data,
        });

    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: USER_SUSPEND_FAIL,
            payload: msg

        });
    }
};

export const reactivateUser = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_REACTIVATE_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(
            `/api/users/${id}/reactivate`, {}, config
        );

        dispatch({
            type: USER_REACTIVATE_SUCCESS,
            payload: data,
        });

    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: USER_REACTIVATE_FAIL,
            payload: msg

        });
    }
};


export const getUserDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DETAILS_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const res = await axios.get(`/api/users/${id}`, config);

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: res.data,
        });
    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        // console.error(error.response)

        dispatch({
            type: USER_DETAILS_FAIL,
            payload: msg,
        });
    }
};

export const getEducationDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: EDUCATION_DETAILS_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const res = await axios.get(`/api/users/education/${id}`, config);

        dispatch({
            type: EDUCATION_DETAILS_SUCCESS,
            payload: res.data,
        });
    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        // console.error(error.response)

        dispatch({
            type: EDUCATION_DETAILS_FAIL,
            payload: msg,
        });
    }
};
export const getExperienceDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: EXPERIENCE_DETAILS_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const res = await axios.get(`/api/users/experience/${id}`, config);

        dispatch({
            type: EXPERIENCE_DETAILS_SUCCESS,
            payload: res.data,
        });
    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        // console.error(error.response)

        dispatch({
            type: EXPERIENCE_DETAILS_FAIL,
            payload: msg,
        });
    }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_PROFILE_UPDATE_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const res = await axios.put('/api/users/profile', user, config);

        dispatch({
            type: USER_PROFILE_UPDATE_SUCCESS,
            payload: res.data,
        });

        dispatch({ type: USER_LOGIN_SUCCESS, payload: res.data });

        localStorage.setItem('userInfo', JSON.stringify(res.data));
    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: USER_PROFILE_UPDATE_FAIL,
            payload: msg,
        });
    }
};



export const deleteUser = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DELETE_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.delete(`/api/users/${id}`, config);

        dispatch({
            type: USER_DELETE_SUCCESS,
        });
    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }

        dispatch({
            type: USER_DELETE_FAIL,
            payload: msg,
        });
    }
};

export const updateUser = (user) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UPDATE_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.put(`/api/users/${user._id}`, user, config);

        dispatch({ type: USER_UPDATE_SUCCESS });

        dispatch({ type: USER_DETAILS_SUCCESS, payload: data });

        dispatch({ type: USER_DETAILS_RESET });
    } catch (error) {
        const msg = error.response && error.response.data.message

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: USER_UPDATE_FAIL,
            payload: msg,
        });
    }
};

export function updateSeekerForm(data) {
    return {
        type: SEEKER_UPDATE_FORM,
        payload: data
    };
}

export const createUser = (user) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_CREATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post(`/api/users/admin`, user, config)

        dispatch({
            type: USER_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        const msg = error.response && error.response.data.msg

        if (msg === 'Not Authorized, Token Failed') {
            dispatch(logout())
        }
        dispatch({
            type: USER_CREATE_FAIL,
            payload: msg,
        })
    }
}




export const logout = () => (dispatch) => {
    dispatch({
        type: USER_LOGOUT,
    });

    dispatch({
        type: USER_DETAILS_RESET,
    });

    dispatch(resetJobDetails())

    dispatch(educationDetailsReset())
    // dispatch({ type: USER_LIST_RESET });

    localStorage.removeItem('userInfo');
    dispatch({ type: PURGE, key: 'root', result: () => null });
};

export const userProfileUpdateReset = () => (dispatch) => {
    dispatch({ type: USER_PROFILE_UPDATE_RESET });
};

export const userUpdateReset = () => (dispatch) => {
    dispatch({ type: USER_UPDATE_RESET });
};

export const resetSeekerCreateForm = () => dispatch => dispatch({
    type: SEEKER_RESET_FORM,

})

export const userRegisterReset = () => (dispatch) => {
    dispatch({ type: USER_REGISTER_RESET });
};



export const initialCallReset = () => (dispatch) => {
    dispatch({ type: USER_SCHEDULE_INITIAL_CALL_RESET });
};
export const finalInterviewReset = () => (dispatch) => {
    dispatch({ type: USER_SCHEDULE_FINAL_INTERVIEW_RESET });
};
export const userShortlistReset = () => (dispatch) => {
    dispatch({ type: USER_SHORTLIST_RESET });
};
export const grantOfferReset = () => (dispatch) => {
    dispatch({ type: USER_GRANT_OFFER_RESET });
};

export const userVerifyReset = () => (dispatch) => {
    dispatch({ type: USER_VERIFY_RESET });
};

export const userSuspendReset = () => (dispatch) => {
    dispatch({ type: USER_SUSPEND_RESET });
};

export const userReactivateReset = () => (dispatch) => {
    dispatch({ type: USER_REACTIVATE_RESET });
};

export const educationDetailsReset = () => (dispatch) => {
    dispatch({ type: EDUCATION_DETAILS_RESET });
};

export const experienceDetailsReset = () => (dispatch) => {
    dispatch({ type: EXPERIENCE_DETAILS_RESET });
};

export const resetUserCreate = () => (dispatch) =>
    dispatch({ type: USER_CREATE_RESET });

export const resetUserDelete = () => (dispatch) =>
    dispatch({ type: USER_DELETE_RESET });
