import { Avatar, Box, Typography } from "@mui/material"
import PropTypes from 'prop-types';



const Card = ({ color, metricName, metricNumber, suffix, Icon }) => {
    return (
        <Box sx={{
            borderRadius: "10px",
            border: `2px solid ${color}`,
            py: 4,
            px: 4,
            mr: 2,
            display: "flex",
            justifyContent: "space-between",
            background: `${color}`,
            color: "white",
            width: "90%",
            mt: 5
        }}>

            <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar variant="rounded" sx={{ bgcolor: "transparent", border: "1px solid white" }}>
                    <Icon />
                </Avatar>
            </div>
            <div>
                <Typography >{metricName}</Typography>
                <Typography variant="h3" sx={{ textAlign: "end" }}>{metricNumber}{suffix && suffix}</Typography>
            </div>
        </Box>
    )
}

Card.propTypes = {
    color: PropTypes.string.isRequired,
    metricName: PropTypes.string.isRequired,
    metricNumber: PropTypes.number.isRequired,
    suffix: PropTypes.string,
    Icon: PropTypes.string.isRequired
}

export default Card