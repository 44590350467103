import { SUBSCRIBE_FAIL, SUBSCRIBE_REQUEST, SUBSCRIBE_RESET, SUBSCRIBE_SUCCESS } from "../types/paymentTypes";


export const subscribeReducer = (state = {}, action) => {
    switch (action.type) {
        case SUBSCRIBE_REQUEST:
            return { loading: true };
        case SUBSCRIBE_SUCCESS:
            return { loading: false, success: true };
        case SUBSCRIBE_FAIL:
            return { loading: false, error: action.payload };
        case SUBSCRIBE_RESET:
            return {};
        default:
            return state;
    }
};