import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { setItem } from "../../actions/userActions"
import { ArrowLeftIcon } from "@mui/x-date-pickers/icons"
import { LoadingButton } from "@mui/lab"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { createTicket, resetTicketCreate } from "../../actions/ticketActions"
import { ToastContainer, toast } from "react-toastify"


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #eaeaea',
    boxShadow: 24,
    p: 4,
    borderRadius: "20px"
};

const CreateTicketForm = (props) => {
    const [title, setTitle] = useState("")
    const [priority, setPriority] = useState("")
    const [description, setDescription] = useState("")


    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { loading, success, error } = useSelector(state => state.ticketCreate)


    const handleSubmit = (e) => {
        e.preventDefault()
        const ticket = {
            title, priority, description
        }
        try {
            dispatch(createTicket(ticket))
        } catch (error) {
            return toast(error.response.data.msg, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }

        setTitle("")
        setPriority("")
        setDescription("")

        setTimeout(() => {
            props.handleClose()
        }, 3000);


    }


    useEffect(() => {
        if (success) {
            dispatch(resetTicketCreate())
            toast('Ticket Created Successfully', {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
            dispatch(resetTicketCreate())
        }

        if (error) {
            toast(error, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
            dispatch(resetTicketCreate())
        }
    }, [dispatch, success, error])

    return (
        <Grid container>
            <ToastContainer />
            <Grid xs={3}></Grid>
            <Grid xs={6}>
                <Modal
                    open={props.open}
                    onClose={props.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={style}
                    >
                        <Box display="flex" justifyContent="space-between">
                            <Typography id="modal-modal-title" variant="h5" component="h6" >
                                RAISE A TICKET
                            </Typography>
                            <Typography variant='h5'
                                sx={{ cursor: "pointer", fontWeight: 800, color: "#ff499e" }}
                                onClick={props.handleClose}>X</Typography>
                        </Box>
                        <form onSubmit={handleSubmit}>


                            <TextField
                                margin="normal"
                                value={title}
                                fullWidth
                                sx={{ mb: 2 }}
                                required
                                label="Issue Title"
                                onChange={(e) => setTitle(e.target.value)}

                            />
                            <FormControl fullWidth >
                                <InputLabel id="demo-simple-select-label">Priority Level</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={priority}
                                    sx={{ mb: 2 }}
                                    label="Priority Level"
                                    onChange={(e) => setPriority(e.target.value)}
                                >

                                    <MenuItem value="Low">Low</MenuItem>
                                    <MenuItem value="Medium">Medium</MenuItem>
                                    <MenuItem value="High">High</MenuItem>

                                </Select>
                            </FormControl>


                            <TextField
                                onChange={(e) => setDescription(e.target.value)}
                                label="Issue Description"
                                multiline
                                value={description}
                                rows={4}
                                fullWidth
                                placeholder="Write the details of the Problem you are facing"
                                sx={{ mb: 2 }}
                            />


                            <Box display="flex" justifyContent="space-between">
                                <Button
                                    variant="text"
                                    startIcon={<ArrowLeftIcon />}
                                    onClick={() => {
                                        dispatch(setItem("profile"))
                                        navigate(`/profile`)
                                    }}
                                >Back</Button>
                                <LoadingButton type="submit"
                                    className="button-primary"
                                    loading={loading}
                                >
                                    Submit
                                </LoadingButton>
                            </Box>
                        </form>
                    </Box>
                </Modal>

            </Grid>
            <Grid xs={3}></Grid>

        </Grid>
    )
}

export default CreateTicketForm