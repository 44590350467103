import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { useState } from "react";
import { sanitizeInput } from '../../../utils/Validate';

const EducationForm = ({ onAdd }) => {
    const [name, setName] = useState('');
    const [field, setField] = useState('');
    const [qualification, setQualification] = useState('');
    const [date_start, setEduStart] = useState(dayjs());
    const [date_end, setEduEnd] = useState(dayjs());


    const handleSubmit = (event) => {
        event.preventDefault();
        const newEducation = { name, field, qualification, date_start, date_end };
        onAdd(newEducation); // Call the provided function to handle form submission
        setName(''); // Reset form after submission
        setField('');
        setQualification('')
        setEduStart(dayjs())
        setEduEnd(dayjs())
    }

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                margin="normal"

                fullWidth
                sx={{ mb: 2 }}

                label="School Name"
                name="name"
                value={sanitizeInput(name)}
                onChange={(e) => setName(e.target.value)}

                autoComplete="School Name"
                autoFocus
            />
            <TextField
                margin="normal"

                fullWidth
                sx={{ mb: 3 }}
                label="Field of Study"
                name="field"

                value={sanitizeInput(field)}
                onChange={(e) => setField(e.target.value)}

                autoComplete="Field of Study"
                autoFocus
            />

            <FormControl fullWidth sx={{ my: 2 }}>
                <InputLabel id="qualification-select">Qualification</InputLabel>
                <Select
                    labelId="qualification-select"
                    id="qualification"
                    value={qualification}
                    label="Qualification"
                    required
                    onChange={(e) => setQualification(e.target.value)}
                >

                    <MenuItem value="HND/BSC">HND / BSC</MenuItem>
                    <MenuItem value="MSc">MSc</MenuItem>
                    <MenuItem value="PhD">PhD</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>

                </Select>
            </FormControl>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ marginRight: "10px" }}>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                            <DatePicker
                                label="Start Date"
                                name="date_start"

                                value={date_start}
                                onChange={(newValue) => setEduStart(newValue)}


                            />

                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                <div style={{ marginLeft: "10px" }}>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>

                            <DatePicker
                                label="End Date"
                                name="date_end"

                                value={date_end}
                                onChange={(newValue) => setEduEnd(newValue)}

                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>


            </div>
            {/* Similar input fields and logic for field, qualification, eduStart, eduEnd */}
            <Typography textAlign="center" mt={5}>

                <Button type="submit" variant="contained" className="button-primary" >Add Education</Button>
            </Typography>
        </form>
    )
}

export default EducationForm
