
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useState } from 'react';
import { isEmpty, isLength, isMatch } from '../../utils/Validate';
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { LoadingButton } from '@mui/lab';
import { ArrowLeftIcon } from '@mui/x-date-pickers/icons';




const ResetPassword = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false)
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const navigate = useNavigate()

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const { token } = useParams();


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        // check fields
        if (isEmpty(password) || isEmpty(confirmPassword)) {
            setLoading(false)
            return toast('Please fill in all fields.', {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }

        //check password length
        if (!isLength(password)) {
            setLoading(false)
            return toast('Password must be at least 8 characters.', {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });

        }

        // password match
        if (!isMatch(password, confirmPassword)) {
            setLoading(false)
            return toast('Password did not match', {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }

        try {
            await axios.post(
                '/api/users/reset-password',
                { password },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setLoading(false)
            setPassword("")
            setConfirmPassword("")
            setTimeout(() => {
                navigate('/login');
            }, 3000);

            return toast('Password was successfully changed 🤗', {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
        } catch (err) {
            setLoading(false)
            toast(err.response.data.msg, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }
    };

    return (
        <Grid container>
            <ToastContainer />
            <Grid md={4}></Grid>
            <Grid md={4}>
                <Box sx={{ border: "1px solid #eaeaea", p: 3, marginTop: "80px", borderRadius: "15px" }}>
                    <Typography variant="h6" textAlign="center" mt={3}>RESET YOUR PASSWORD</Typography>
                    <form onSubmit={handleSubmit}>

                        <FormControl sx={{ my: 3 }} variant="outlined" fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                fullWidth
                                required
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        <FormControl sx={{ my: 3 }} variant="outlined" fullWidth>
                            <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                            <OutlinedInput
                                required
                                fullWidth
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                value={confirmPassword}
                                id="outlined-adornment-confirm-password"
                                type={showConfirmPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Confirm Password"
                            />
                        </FormControl>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button onClick={() => navigate("/login")} startIcon={<ArrowLeftIcon />} variant="text">
                                Login
                            </Button>
                            <LoadingButton className="button-primary" type="submit" variant="contained" loading={loading}>
                                Submit
                            </LoadingButton>

                        </div>
                    </form>
                </Box>
            </Grid>
            <Grid md={4}></Grid>


        </Grid>
    );
};

export default ResetPassword;