import { Button, Card, CardActions, CardContent, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { subscribe } from '../../../actions/paymentActions';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react';

const EmployerBilling = () => {

    // const navigate = useNavigate()
    const dispatch = useDispatch()

    const { success } = useSelector(state => state.subscribe)

    useEffect(() => {
        if (success) {
            return toast("Payment Link Generated", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });
        }

    }, [success, dispatch])

    const handlePayment = () => {
        try {
            dispatch(subscribe())
        } catch (error) {
            return toast(error, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }
    }

    return (
        <Grid container mt={3}>
            <ToastContainer />
            <Grid md={3} xs={12} sm={2} ></Grid>
            <Grid md={6} xs={12} sm={8}>
                <Card sx={{ paddingX: 2, paddingY: 4, marginTop: 2 }}>
                    <CardContent>
                        <Typography variant="h4">Unlimited Job Listing</Typography>
                        <Typography variant="h2" my={2}>N10,000</Typography>
                        <Divider />
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircleOutlineIcon />
                                </ListItemIcon>
                                <ListItemText primary="Lorem Ipsum..." />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircleOutlineIcon />
                                </ListItemIcon>
                                <ListItemText primary="Lorem Ipsum..." />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircleOutlineIcon />
                                </ListItemIcon>
                                <ListItemText primary="Lorem Ipsum..." />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircleOutlineIcon />
                                </ListItemIcon>
                                <ListItemText primary="Lorem Ipsum..." />
                            </ListItem>
                        </List>

                    </CardContent>
                    <CardActions>
                        <Button
                            fullWidth
                            className="button-primary"
                            variant="contained"

                            onClick={handlePayment}
                        >Proceed to Payment</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid md={3} xs={12} sm={2}></Grid>
        </Grid>
    )
}

export default EmployerBilling