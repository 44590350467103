import { Grid } from "@mui/material"
import HorizontalLinearStepper from "./Stepper"


const JobSeekerProfileSetup = (props) => {
    return (
        <Grid container>

            <Grid xs={12} sm={10} md={8}>
                <HorizontalLinearStepper />
            </Grid>

        </Grid>
    )
}

export default JobSeekerProfileSetup