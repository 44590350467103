import { Box, CircularProgress, Grid, TablePagination, Typography } from '@mui/material'

import Job from './Job'

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { listMyJobs } from '../../actions/jobActions';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';



const JobList = () => {
    const { userInfo } = useSelector((state) => state.userLogin);
    const { jobs, loading, error } = useSelector((state) => state.myJobList);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (!userInfo) {
            navigate("/login")
        } else {

            dispatch(listMyJobs())


        }


    }, [dispatch, userInfo, navigate])



    function TablePaginationDemo() {


        return (
            <TablePagination
                component="div"
                count={jobs.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        );
    }

    return (
        <Grid container mt={5}>
            <ToastContainer />

            <Grid xs={1}></Grid>


            <Grid xs={10}>

                {loading ? <Typography textAlign="center"><CircularProgress /></Typography> :
                    error ? <Typography variant='h3'>{error}</Typography> :
                        jobs?.map(job => (
                            <Job  {...job} key={job._id} />
                        ))}



            </Grid>
            <Grid xs={1}></Grid>
            {(jobs?.length > 10) && (
                <Box mt={2}>
                    <TablePaginationDemo />
                </Box>
            )}
        </Grid>
    )
}



export default JobList