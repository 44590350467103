import { Box, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { LoadingButton } from '@mui/lab';
import { Link, useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from "react-redux";
import { register, userRegisterReset } from "../../actions/userActions"
import { isEmpty, isLength, sanitizeInput } from "../../utils/Validate";
import { ToastContainer, toast } from "react-toastify";



const Register = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);


    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [company, setCompany] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [type, setType] = useState("")
    // const [isSubmitting, setIsSubmitting] = useState(false)



    const navigate = useNavigate()

    const dispatch = useDispatch();
    const { loading, success, error } = useSelector((state) => state.userRegister);



    const handleRegister = (e) => {
        e.preventDefault();



        if (!type) {
            return toast("Please select an option", {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }

        const requiredFields = [email, password, confirmPassword, type, firstname, lastname];
        const areFieldsEmpty = requiredFields.some(isEmpty);

        if (areFieldsEmpty) {
            return toast("Please Fill all fields", {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }

        if (password !== confirmPassword) {
            return toast("Passwords do not Match", {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }

        if (!isLength(password) || !isLength(confirmPassword)) {
            return toast("Password is too short", {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }

        // Submit User details

        const userData = {
            firstname,
            lastname,
            email,
            password,
            type,
            company
        };

        // if (type === "Employer") {
        //     userData.company = company;
        // }

        dispatch(register(userData));


    };

    useEffect(() => {
        if (success) {
            toast("Registration Successful", {
                className: 'toast-success',
                bodyClassName: 'toast-success',
            });

            setTimeout(() => {
                dispatch(userRegisterReset())
                navigate("/account-created");
            }, 3000);




        } else {
            toast(error, {
                className: 'toast-failed',
                bodyClassName: 'toast-failed',
            });
        }
    }, [error, navigate, success, dispatch])


    return (

        <Grid container >


            <Grid xs={12} sm={6} md={4}>
                <div>
                    <img src="/Proten-logo.png" alt="Proten Logo" width={150} height={150} />
                </div>

                <Typography component="h4" variant="h5" sx={{ my: 3 }}>Sign up as a:</Typography>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">User</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={type}
                        label="User"
                        onChange={(e) => setType(e.target.value)}
                    >

                        <MenuItem value="JobSeeker">JobSeeker</MenuItem>
                        <MenuItem value="Employer">Employer</MenuItem>

                    </Select>
                </FormControl>

                <Typography component="p" variant="p" sx={{ mt: 5, mb: 5, lineHeight: "30px" }}>
                    By Signing up on Proten Job Portal, You agree to our
                    <Link to=""> Terms </Link> and <Link to="">Conditions</Link>
                </Typography>
            </Grid>
            <Grid xs={12} sm={6} md={4}>

            </Grid>
            <Grid xs={12} sm={6} md={4}>
                <ToastContainer />
                <Typography component="h1" variant="h5" sx={{ fontWeight: 700 }}>
                    Sign up
                </Typography>
                {type === "Employer" ? (
                    <Box>
                        <form onSubmit={handleRegister}>


                            <TextField
                                margin="normal"
                                value={sanitizeInput(firstname)}
                                fullWidth
                                required
                                label="First Name"
                                onChange={(e) => setFirstname(e.target.value)}
                                autoComplete="First Name"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                value={sanitizeInput(lastname)}
                                label="Last name"
                                onChange={(e) => setLastname(e.target.value)}
                                autoComplete="Last name"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                onChange={(e) => setCompany(e.target.value)}
                                label="Company Name"
                                value={sanitizeInput(company)}
                                autoComplete="Company Name"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                type="email"
                                label="Company Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="Company Email"
                                autoFocus
                            />
                            <FormControl sx={{ my: 3 }} variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <Tooltip title="password must be Minimum 8 Characters
Has Uppercase, lowercase, symbols and No spaces">

                                    <OutlinedInput
                                        fullWidth
                                        required
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                </Tooltip>
                            </FormControl>
                            <FormControl sx={{ my: 3 }} variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    required
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    value={confirmPassword}
                                    id="outlined-adornment-confirm-password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Confirm Password"
                                />
                            </FormControl>
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ my: 3 }}
                                loading={loading}
                                className="button-primary"
                            >
                                Sign Up
                            </LoadingButton>

                        </form>
                        <Grid sx={{ textAlign: "center" }}>
                            <Typography >Already have an Account ? <Link to="/login">Login</Link></Typography>

                        </Grid>
                    </Box>
                ) : (
                    <Box>
                        <form onSubmit={handleRegister}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                onChange={(e) => setFirstname(e.target.value)}
                                label="First Name"
                                value={sanitizeInput(firstname)}
                                autoComplete="First Name"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                onChange={(e) => setLastname(e.target.value)}
                                label="Last name"
                                value={sanitizeInput(lastname)}
                                autoComplete="Last name"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth
                                type="email"
                                label="Email"
                                value={email}
                                autoComplete="Email"
                                autoFocus
                                required
                            />
                            <FormControl sx={{ my: 3 }} variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <Tooltip title="Password must be Minimum 8 Characters
Has Uppercase, lowercase, symbols and No spaces">
                                    <OutlinedInput
                                        fullWidth
                                        required
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                </Tooltip>
                            </FormControl>
                            <FormControl sx={{ my: 3 }} variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                                <OutlinedInput
                                    required
                                    fullWidth
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    value={confirmPassword}
                                    id="outlined-adornment-confirm-password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Confirm Password"
                                />
                            </FormControl>
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ my: 3 }}
                                loading={loading}
                                className="button-primary"
                            >
                                Sign Up
                            </LoadingButton>
                        </form>
                        <Grid sx={{ textAlign: "center" }}>
                            <Typography >Already have an Account ? <Link to="/login">Login</Link></Typography>

                        </Grid>
                    </Box>
                )}
            </Grid>
        </Grid>

    )
}

export default Register