// import React from 'react'
// import { useDispatch, useSelector } from "react-redux"
// import { Navigate, useLocation } from "react-router-dom"
// import { logout } from '../actions/userActions';

// const ProtectedRoute = ({ children }) => {
//     const { userInfo, error } = useSelector((state) => state.userLogin);
//     let location = useLocation();
//     const dispatch = useDispatch()

//     if (!userInfo) {
//         return <Navigate to="/login" state={{ from: location }} replace />
//     }
//     if (error === "Not Authorized, Token Failed") {
//         dispatch(logout())
//     }
//     return children

// };

// export default ProtectedRoute;


// src/components/ProtectedRoute.js

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { setItem } from '../actions/userActions';


const ProtectedRoute = ({ children, item }) => {
    const dispatch = useDispatch();
    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        if (userInfo) {
            dispatch(setItem(item));
            console.log(`ProtectedRoute: setItem('${item}')`);
        }
    }, [dispatch, item, userInfo]);

    if (!userInfo) {
        return <Navigate to="/login" replace />;
    }

    return children;
};

export default ProtectedRoute;
