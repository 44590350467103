

import TabComponent from '../../../UI/TabComponent';

import { Grid } from '@mui/material';



import { useSelector } from 'react-redux';

export default function Applications() {

    const { userInfo } = useSelector((state) => state.userLogin);
    const userType = userInfo.type

    const labels = [
        {
            value: "one",
            text: "All Applications"
        },
        {
            value: "two",
            text: "Wishlisted"
        },
        {
            value: "three",
            text: "Accepted"
        },
        {
            value: "four",
            text: "Rejected"
        },

    ]


    return (
        <Grid container width="100%">

            <TabComponent labels={labels} title="Applications" userType={userType} />

        </Grid>
    );
}

