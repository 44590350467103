import { Button, CircularProgress, Grid, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import CreateTicketForm from './CreateTicketForm'
import { useDispatch, useSelector } from 'react-redux';
import TicketTable from '../../UI/TicketTable';
import { listMyTickets, listTickets } from '../../actions/ticketActions';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';

const TicketList = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { userInfo } = useSelector(state => state.userLogin)
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const dispatch = useDispatch()
    const { tickets: myTickets, loading: myLoading } = useSelector(state => state.myTicketList)
    const { tickets, loading } = useSelector(state => state.ticketList)


    React.useEffect(() => {
        if (userInfo.isAdmin) {
            dispatch(listTickets())
        } else {
            dispatch(listMyTickets())
        }

    }, [dispatch, userInfo])

    return (
        <Grid container mt={5}>

            <Grid xs={10} sm={8}>
                <CreateTicketForm open={open} handleClose={handleClose} />

                {(loading || myLoading) ? <Typography><CircularProgress /></Typography> : <TicketTable tickets={userInfo.isAdmin ? tickets : myTickets} />}


            </Grid>
            <Grid xs={1} sm={2}></Grid>
            <Grid xs={1} sm={2}>
                {userInfo.type === "Employer" && (
                    <Button
                        onClick={handleOpen}
                        size="small"
                        className="button-primary"
                        sx={{ fontSize: isSmallScreen ? "8px" : "12px" }}


                    > {isSmallScreen ? <AddIcon /> : "Create Ticket"}</Button>
                )}

            </Grid>
        </Grid>
    )
}

export default TicketList