import { Avatar, Button, Grid, Typography } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setItem } from '../../actions/userActions';

const UserHighlight = (props) => {

    const { userInfo } = useSelector((state) => state.userLogin);
    const navigate = useNavigate()
    const dispatch = useDispatch();


    const handleUpdateProfileViews = async (id) => {
        try {

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`,
                },
            }

            await axios.put(`/api/users/${id}/views`, {}, config);

        } catch (error) {
            console.log(error.response.data);
        }
    }

    return (
        <Grid container sx={{
            py: 2,
            px: 5, mt: 3,
            borderRadius: "10px",
            border: "1px solid #eaeaea",
            cursor: "pointer",

        }}
            onClick={() => {
                handleUpdateProfileViews(props._id)

            }}
        >
            <Grid xs={2} sx={{ display: "flex", alignItems: "center" }}>
                <Avatar src={props.avatar} alt={props.firstname} sx={{ height: 50, width: 50 }} />
            </Grid>
            <Grid xs={10} sx={{ display: "flex", justifyContent: "space-between" }}>

                <div>
                    <Typography sx={{ my: 1, fontSize: "16px", fontWeight: 600 }} >{props.preferredTitle}</Typography>
                    <Typography sx={{ my: 1, fontSize: "14px", }} >{props.firstname} {props.lastname}</Typography>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <Button variant="outlined" onClick={() => {
                        handleUpdateProfileViews(props._id)
                        dispatch(setItem("jobseeker"))
                        navigate(`/user/${props._id}`)
                    }}>View Profile</Button>
                </div>

            </Grid>


        </Grid>
    )
}

export default UserHighlight