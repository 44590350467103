import { Avatar, CircularProgress, Grid, Typography, useMediaQuery } from "@mui/material"
import LocationIcon from '@mui/icons-material/PinDropOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Moment from "react-moment";
import { getUserDetails, setItem } from "../../actions/userActions";
import { useTheme } from '@mui/material/styles';


const JobHighlight = () => {

    const { userInfo } = useSelector((state) => state.userLogin);
    const { user, loading } = useSelector((state) => state.userDetails);
    const dispatch = useDispatch()
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const navigate = useNavigate()

    useEffect(() => {
        if (!userInfo) {
            navigate("/login")
        } else {
            if (!user || !user.appliedJobs) {
                dispatch(getUserDetails('profile'));
            }
        }


    }, [dispatch, user, navigate, userInfo])

    return (

        loading ? <Typography textAlign="center"> <CircularProgress /></Typography> : user?.appliedJobs?.map(job => (
            <Grid container sx={{
                py: 2,
                px: 5,
                mt: 3,
                borderRadius: "10px",
                border: "1px solid #eaeaea",
                cursor: "pointer"
            }}
                onClick={() => {
                    dispatch(setItem("job-detail"))
                    navigate(`/job/${job._id}`)
                }}
            >
                <Grid md={1} xs={12} sm={12}>
                    <Avatar src={job.avatar} alt="Company Logo" />
                </Grid>
                <Grid md={10} xs={12} sm={12}>
                    <Typography sx={{ fontSize: "12px" }}>{job.company}</Typography>
                    <Typography sx={{
                        my: 1,
                        fontSize: "15px",
                        fontWeight: 600,

                    }} >{job.title}</Typography>
                    <Grid sx={{
                        display: "flex",
                        my: 1, color: "#8b8b8b",
                        alignItems: isSmallScreen ? "flex-start" : "center",
                        justifyContent: isSmallScreen ? "center" : "space-between",
                        flexDirection: isSmallScreen ? "column" : "row",
                    }}>
                        <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px", marginBottom: isSmallScreen && "15px" }}><LocationIcon sx={{ fontSize: "14px", marginRight: "2px" }} /> {job.location} </Typography>
                        <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px", marginBottom: isSmallScreen && "15px" }}><AccessTimeIcon sx={{ fontSize: "14px", marginRight: "2px" }} /> {job.type}  </Typography>
                        <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px", marginBottom: isSmallScreen && "15px" }}><span style={{ fontSize: "12px", marginRight: "2px" }}>&#8358;</span> {" "}{`${job.minSalary}-${job.maxSalary}`}</Typography>
                        <Typography sx={{ display: "flex", flexWrap: "wrap", fontSize: "12px" }}><DateRangeOutlinedIcon sx={{ fontSize: "14px", marginRight: "2px" }} /> {" "} <Moment fromNow>{job.createdAt}</Moment> </Typography>
                    </Grid>
                </Grid>
                <Grid xs={12} md={1}></Grid>
            </Grid>
        ))

    )
}



export default JobHighlight